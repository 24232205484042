import { ButtonAdd, NotReady, PageHeading, PagePadding, useQueryParam, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { canEditBook } from '../common'
import { ImportMenu } from './importer'
import { StoryList } from './list/StoryList'

export function StoriesController(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const filter = useQueryParam('filter')
  const { book, isLoading, error } = useBook(bookSlug)
  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />

  if (book.stories.length === 0) {
    return (
      <div className="m-5 p-5 rounded-xl border-2 border-dashed flex flex-row items-center space-x-3">
        <ButtonAdd to="../stories/_add" canEdit={canEditBook(book)}>
          Add Story
        </ButtonAdd>
        <ImportMenu book={book} />
        <p>There are no stories yet.</p>
      </div>
    )
  }

  return (
    <PagePadding>
      <div className="flex items-center justify-between flex-wrap sm:flex-nowrap pb-2">
        <PageHeading title="Stories" className="flex-1">
          Stories
        </PageHeading>
        <div className="flex items-center px-4 py-2 space-x-2">
          <ButtonAdd to="_add" canEdit={canEditBook(book)}>
            Add Story
          </ButtonAdd>
          <ImportMenu book={book} />
        </div>
      </div>

      <StoryList book={book} stories={book.stories} initialFilter={filter} />
    </PagePadding>
  )
}
