import { Spinner } from '@life/components'
import { Book, readPdfPreviewUrl } from '@life/frontend-model'
import { StoryId } from '@life/model'
import { useState } from 'react'

type Props = {
  book: Book
  storyId: StoryId
  disabled?: boolean
  /** Reason the link is disabled */
  reason?: string
}
export function PdfPreviewLink({ book, storyId, disabled, reason }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)

  async function navToPreview(): Promise<void> {
    if (disabled) return
    setIsLoading(true)
    const url = await readPdfPreviewUrl(book.bookId, storyId)
    window.open(url, 'life-pdf-preview', 'noreferrer')
    setIsLoading(false)
  }

  if (!disabled) reason = 'Opens in new tab'
  return (
    <button type="button" onClick={navToPreview} className={disabled ? 'cursor-default' : 'cursor-pointer'}>
      <div className="whitespace-nowrap flex flex-row">
        {isLoading && <Spinner className="mr-2 h-5 w-5" aria-hidden="true" />}
        <span title={reason} className={disabled ? 'text-gray-400 italic' : ''}>
          Preview PDF in Chapter
        </span>
      </div>
    </button>
  )
}
