import { classNames, WithChildren } from '@life/components'
import { Person } from '@life/frontend-model'
import { Link } from 'react-router-dom'

type Props = WithChildren & {
  person: Person
}

export function PersonLink({ person, className, children }: Props): JSX.Element {
  return (
    <Link to={person.link} className={classNames('text-cyan-600 hover:text-cyan-500 hover:underline', className)}>
      {children ?? person.formalName}
    </Link>
  )
}
