import { classNames } from '.'
import { WithChildren } from './types'

type Props = WithChildren & {
  narrow?: boolean
  wide?: boolean
  noPadding?: boolean
}

export function PagePadding({ className = '', children, noPadding, narrow, wide }: Props): JSX.Element {
  return (
    <div
      id="PagePadding"
      className={classNames(
        className,
        'h-full',
        narrow ? 'max-w-3xl' : wide ? 'w-full' : 'max-w-7xl',
        noPadding ? '' : 'p-2 sm:p-4 lg:p-6'
      )}
    >
      {children}
    </div>
  )
}
