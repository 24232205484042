import { useAuth0 } from '@auth0/auth0-react'
import { ButtonRefresh, HideUntil, MessagePage, NotReady } from '@life/components'
import { Outlet } from 'react-router-dom'
import { setAuth } from '@life/frontend-model'

export function LayoutWithAuth(): JSX.Element {
  const auth = useAuth0()
  if (auth.isLoading || auth.error) {
    return (
      <NotReady type="User" isLoading={auth.isLoading} error={auth.error}>
        <HideUntil wait={auth.isLoading ? 5000 : 0}>
          <LoginButtons />
        </HideUntil>
      </NotReady>
    )
  }

  if (!auth.isAuthenticated) {
    const target = new URL(window.location.href)
    const redirectTarget = target.pathname === '/' ? target.origin + '/start' : target.href
    auth.loginWithRedirect({
      appState: { target: redirectTarget },
    })
    return <MessagePage title="Redirect" description="Redirecting you to login page. Please wait..." />
  }

  // set the auth globally so it's available later on when we call our apis and need the token
  setAuth(auth)

  return (
    <div className="h-full">
      <Outlet />
    </div>
  )
}

/**
 * Sometimes we fail to login properly. I haven't figured out why. There's most likely a bug or race condition somewhere.
 * If this happens, we display some login buttons below to help them navigate manually.
 */
function LoginButtons(): JSX.Element {
  const auth = useAuth0()

  return (
    <div className="text-center w-full space-x-5">
      <ButtonRefresh
        onClick={() => {
          auth.logout({
            returnTo: window.location.origin,
          })
        }}
      >
        Sign Out
      </ButtonRefresh>
      <ButtonRefresh
        onClick={() => {
          auth.loginWithRedirect()
        }}
      >
        Sign In
      </ButtonRefresh>
    </div>
  )
}
