import * as Model from '@life/model'
import {
  createEmptyText,
  createParagraph,
  isDefined,
  isEmpty,
  isStoryElement,
  isStorySubstory,
  StoryContent,
} from '@life/model'
import { Book } from './book'
import { BookItem, UnsavedBookItem } from './book-item'
import { Image } from './image'
import { Person } from './person'

export class UnsavedStory extends UnsavedBookItem {
  title: string
  status: Model.StoryStatusType
  content: Model.StoryContent[]
  occurred?: Model.StoryDate
  private peopleCache: Model.PersonId[] | undefined
  private imagesCache: Model.ImageId[] | undefined

  constructor(book: Book, data: Model.UnsavedStory) {
    super(book)
    this.title = data.title
    this.status = data.status
    this.content = initializeContent(data.content)
    this.occurred = data.occurred
  }

  override get key(): string {
    return this.title
  }

  override toUnsavedModel(): Model.UnsavedStory {
    return {
      bookId: this.book.bookId,
      title: this.title,
      status: this.status,
      content: this.content,
      occurred: this.occurred,
    }
  }

  get people(): Model.PersonId[] {
    if (!this.peopleCache) {
      this.peopleCache = this.peopleIds(this.content)
    }
    return this.peopleCache
  }

  private peopleIds(content: Model.StoryContent[]): Model.PersonId[] {
    if (Model.isEmpty(content)) return []
    const ids = content.flatMap((row) => {
      if (Model.isStoryPerson(row)) {
        return row.id
      }
      if (Model.isStoryParagraph(row)) {
        return this.peopleIds(row.children)
      }
      return []
    })
    return ids
  }

  get images(): Model.ImageId[] {
    if (!this.imagesCache) {
      this.imagesCache = this.imageIds(this.content)
    }
    return this.imagesCache
  }

  private imageIds(content: Model.StoryContent[]): Model.ImageId[] {
    if (Model.isEmpty(content)) return []
    const ids = content.flatMap((row) => {
      if (Model.isStoryImage(row)) {
        return row.id
      }
      if (Model.isStoryParagraph(row)) {
        return this.imageIds(row.children)
      }
      return []
    })
    return ids
  }

  isPersonInStory(person: Person): boolean {
    return this.isPersonInStoryContent(person.personId, this.content)
  }

  private isPersonInStoryContent(personId: Model.PersonId, content: Model.StoryContent[]): boolean {
    if (Model.isEmpty(content)) return false
    return content.some((row) => {
      if (Model.isStoryPerson(row)) {
        return row.id === personId
      }
      if (Model.isStoryParagraph(row)) {
        return this.isPersonInStoryContent(personId, row.children)
      }
      return false
    })
  }

  isImageInStory(image: Image): boolean {
    return this.isImageInStoryContent(image.imageId, this.content)
  }

  private isImageInStoryContent(imageId: Model.ImageId, content: Model.StoryContent[]): boolean {
    if (Model.isEmpty(content)) return false
    return content.some((row) => {
      if (Model.isStoryImage(row)) {
        return row.id === imageId
      }
      if (Model.isStoryParagraph(row)) {
        return this.isImageInStoryContent(imageId, row.children)
      }
      return false
    })
  }
}

export class Story extends UnsavedStory implements BookItem {
  readonly storyId: Model.StoryId
  readonly version: number

  constructor(book: Book, data: Model.Story) {
    super(book, data)
    this.storyId = data.storyId
    this.version = data.version
  }

  override get key(): string {
    return this.storyId
  }

  get link(): string {
    return this.book.link + '/stories/' + this.storyId
  }

  get parent(): Story | undefined {
    return this.book.stories.find((story) => story.hasSubstory(this.storyId))
  }

  get fullTitle(): string {
    const { title, parent } = this
    if (parent) return parent.fullTitle + ' > ' + title
    return title
  }
  public getTopStory(): Story {
    const { parent } = this
    return parent?.getTopStory() ?? parent ?? this
  }

  private hasSubstory(storyId: Model.StoryId): boolean {
    return !!this.content.find((el) => isStorySubstory(el) && el.id === storyId)
  }

  get substories(): Story[] {
    const getStory = (element: StoryContent): Story | undefined => {
      if (isStorySubstory(element)) return this.book.findStory(element.id)
      return undefined
    }
    return this.content.map(getStory).filter(isDefined)
  }

  /**
   * Level in the Table of Contents.
   * - 0: not in ToC
   * - 1: Chapter
   * - 2: Section
   * - 3: Subsection
   */
  get level(): number {
    if (!this.book.toc.isInToc(this)) return 0
    const parent = this.parent
    if (!parent) return 1
    return parent.level + 1
  }

  /** Finds the total number of levels in the story (1 plus substory levels) */
  get levelCount(): number {
    const counts = this.substories.map((sub) => sub.levelCount)
    return 1 + (counts.length > 0 ? Math.max(...counts) : 0)
  }

  get isInToc(): boolean {
    return this.book.toc.isInToc(this)
  }

  /**
   * Determines if a story is a this story or one of its substories (all depths).
   */
  containsStory(storyId: Model.StoryId): boolean {
    if (this.storyId === storyId) return true
    if (this.hasSubstory(storyId)) return true
    return this.substories.some((sub) => sub.hasSubstory(storyId))
  }

  toModel(): Model.Story {
    return {
      storyId: this.storyId,
      version: this.version,
      ...this.toUnsavedModel(),
    }
  }
}

function initializeContent(content?: StoryContent[]): StoryContent[] {
  if (isEmpty(content)) return [createParagraph()]
  content.forEach((c) => {
    if (isStoryElement(c)) {
      if (isEmpty(c.children)) {
        c.children = createEmptyText()
      }
    }
  })
  return content
}
