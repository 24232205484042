import * as Model from '@life/model'
import { Book } from './book'
import { BookItem, UnsavedBookItem } from './book-item'
import { Image } from './image'
import { Story } from './story'

export class UnsavedPerson extends UnsavedBookItem {
  lastName: string
  givenNames: string
  /** Sr. or Jr. */
  suffix?: string
  /** A short familiar name */
  nickName?: string
  /** Other last names by which this person is known, e.g., maiden name */
  otherLastNames?: string[]
  birthDate?: Model.DateBreakdown
  deathDate?: Model.DateBreakdown
  imageId?: Model.ImageId

  constructor(book: Book, data: Model.UnsavedPerson) {
    super(book)
    // Set fields to undefined if trimmed field is empty
    this.lastName = data.lastName.trim()
    this.givenNames = data.givenNames.trim()
    this.suffix = data.suffix?.trim() || undefined
    this.nickName = data.nickName?.trim() || undefined
    this.birthDate = data.birthDate
    this.deathDate = data.deathDate
    this.imageId = data.imageId
    this.otherLastNames = data.otherLastNames
  }

  override get key(): string {
    return this.lastName
  }
  get image(): Image | undefined {
    return this.book.findImage(this.imageId)
  }

  get otherLastNamesDisplay(): string {
    return this.otherLastNames?.join(', ') ?? ''
  }

  get firstName(): string {
    const { givenNames, nickName } = this
    return nickName || givenNames
  }

  get formalName(): string {
    const { givenNames, lastName, suffix } = this
    return `${givenNames} ${lastName} ${suffix || ''}`.trim()
  }

  get formalNameLastFirst(): string {
    const { givenNames, lastName, suffix } = this
    return lastName + `, ${givenNames} ${suffix || ''}`.trim()
  }

  override toUnsavedModel(): Model.UnsavedPerson {
    return {
      bookId: this.bookId,
      lastName: this.lastName,
      givenNames: this.givenNames,
      suffix: this.suffix,
      nickName: this.nickName,
      otherLastNames: this.otherLastNames,
      birthDate: this.birthDate,
      deathDate: this.deathDate,
      imageId: this.imageId,
      status: 'stub',
    }
  }
}

export class Person extends UnsavedPerson implements BookItem {
  readonly personId: Model.PersonId
  private imagesCache: Image[] | undefined
  private storiesCache: Story[] | undefined

  constructor(book: Book, data: Model.Person) {
    super(book, data)
    this.personId = data.personId
  }

  override get key(): string {
    return this.personId
  }
  get imagesWithPerson(): Image[] {
    if (!this.imagesCache) {
      this.imagesCache = this.book.images.filter((i) => i.isPersonInImage(this))
    }
    return this.imagesCache
  }
  get storiesWithPerson(): Story[] {
    if (!this.storiesCache) {
      this.storiesCache = this.book.stories.filter((s) => s.isPersonInStory(this))
    }
    return this.storiesCache
  }

  get link(): string {
    return this.book.link + '/people/' + this.personId
  }

  toModel(): Model.Person {
    return {
      personId: this.personId,
      ...this.toUnsavedModel(),
    }
  }
}
