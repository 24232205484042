import {
  BookIcon,
  ComputerIcon,
  NotReady,
  PageHeading,
  PagePadding,
  SupportIcon,
  useRequiredParam,
} from '@life/components'
import { Story, useBook } from '@life/frontend-model'
import { useNavigate } from 'react-router-dom'
import { AddStoryForm } from './AddStoryForm'

export function AddStory() {
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)
  const navigate = useNavigate()

  if (isLoading || error || !book || !bookSlug) {
    return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  }

  function handleAdd(story: Story): void {
    navigate(story.link + '/edit')
  }

  return (
    <PagePadding narrow>
      <PageHeading title="Add Story">Add Story</PageHeading>
      <div className="pt-4">
        <AddStoryForm book={book} onAdd={handleAdd} />
      </div>
      <AddStoryHelpText />
    </PagePadding>
  )
}

function AddStoryHelpText(): JSX.Element {
  return (
    <div className="text-gray-600">
      <h2 className="flex items-center mt-10 text-lg font-bold text-gray-600">
        <SupportIcon className="h-8" />
        Need Help?
      </h2>
      <p className="my-5">
        Adding people to your story will help to connect your stories together. People you add to your book, will
        automatically be added to the <BookIcon className="h-5 inline" /> physical book&rsquo;s Index and create
        <span className="underline px-1">hyperlinks</span>on the <ComputerIcon className="h-5 inline mx-1" />
        web version.
      </p>
      <p className="my-5">
        Consider adding anyone you mention in a story or you know in a photograph. This may include the following:
      </p>
      <ul className="pl-5 list-disc list-inside leading-relaxed">
        <li>You</li>
        <li>Your parents and grandparents</li>
        <li>Your descendents</li>
        <li>Your descendents&rsquo; spouses and stepchildren</li>
        <li>Friends who made a notable difference in your life</li>
        <li>Coworkers who played a key role in your career</li>
        <li>Mentors, coaches, and religious leaders who changed your life</li>
      </ul>
    </div>
  )
}
