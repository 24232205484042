import { SortDirection, ViewType } from './item-list'
import React, { useEffect, useState } from 'react'
import { Logger } from '@life/model'

const logger = new Logger('component-prefs')

export type ListPrefs = {
  viewType: ViewType
  sortType: string | undefined
  direction: SortDirection
}
export type Preference = {
  searchTerm: string
  bookList: ListPrefs
  storyList: ListPrefs
  imageList: ListPrefs
  personList: ListPrefs
}

export function usePreference<K extends keyof Preference, T extends Preference[K] = Preference[K]>(
  key: K | undefined,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(getPreference(key, defaultValue))
  useEffect(() => {
    try {
      if (!key) return
      if (!value) localStorage.removeItem(key)
      else localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      // ignore ... not saving prefs is sad but not fatal
      logger.info(`Unable to save preference ${key} to ${value}`, e)
    }
  }, [key, value])

  return [value, setValue]
}

function getPreference<K extends keyof Preference, T extends Preference[K]>(key: K | undefined, defaultValue: T): T {
  if (!key) return defaultValue
  const value = localStorage.getItem(key)
  if (value !== null) return value && JSON.parse(value)
  return defaultValue
}
