import { StoryText } from '@life/model'

type Props = {
  text: StoryText
}

export function ViewText({ text }: Props): JSX.Element {
  const classes = []
  if (text.bold) {
    classes.push('font-bold')
  }
  if (text.code) {
    classes.push('font-mono')
  }
  if (text.italic) {
    classes.push('italic')
  }
  if (text.underline) {
    classes.push('underline')
  }
  return classes.length > 0 ? <span className={classes.join(' ')}>{text.text}</span> : <>{text.text}</>
}
