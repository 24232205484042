import { API, StoryDate, StoryStatusType } from '@life/model'
import { useMutation, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { bookKey } from './book-api'
import { Story, UnsavedStory } from './story'

export type AddStoryState = {
  isAdding: boolean
  add: (story: UnsavedStory) => Promise<Story>
}
export function useAddStory(): AddStoryState {
  const queryClient = useQueryClient()
  const mutation = useMutation((story: UnsavedStory) => addStory(story))
  async function add(story: UnsavedStory): Promise<Story> {
    try {
      const output = await mutation.mutateAsync(story)
      queryClient.setQueryData<Book | undefined>(bookKey(story.book), (previous) => {
        if (!previous) return previous
        previous.stories.push(output)
        return previous
      })
      return output
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, add, isAdding: mutation.isLoading }
}

type UpdateStoryOptions = {
  title?: string
  status?: StoryStatusType
  occurred?: StoryDate | null
}
export type UpdateStoryState = {
  isUpdating: boolean
  update: (story: Story, options?: UpdateStoryOptions) => Promise<Story>
}
export function useUpdateStory(): UpdateStoryState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: { story: Story; options?: UpdateStoryOptions }) => updateStory(input))
  async function update(story: Story, options?: UpdateStoryOptions): Promise<Story> {
    try {
      const output = await mutation.mutateAsync({ story, options })
      queryClient.setQueryData<Book | undefined>(bookKey(story.book), (previous) => {
        if (!previous) return previous
        const s = previous.findStory(story.storyId)
        if (s) Object.assign(s, output)
        return previous
      })
      return output
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update, isUpdating: mutation.isLoading }
}

export type RemoveStoryState = {
  isRemoving: boolean
  remove: (story: Story) => Promise<void>
}
export function useRemoveStory(): RemoveStoryState {
  const queryClient = useQueryClient()
  const mutation = useMutation((story: Story) => removeStory(story))
  async function remove(story: Story): Promise<void> {
    try {
      await mutation.mutateAsync(story)
      queryClient.setQueryData<Book | undefined>(bookKey(story.book), (previous) => {
        if (!previous) return previous
        const index = previous.stories.findIndex((s) => s.storyId === story.storyId)
        if (index >= 0) previous.stories.splice(index, 1)
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { isRemoving: mutation.isLoading, remove }
}

async function addStory(story: UnsavedStory): Promise<Story> {
  const response = await serverRequest<API.StoryAddInput, API.StoryAddSuccess>('/story/add', {
    story: story.toUnsavedModel(),
  })
  return new Story(story.book, response.story)
}

async function updateStory({ story, options }: { story: Story; options?: UpdateStoryOptions }): Promise<Story> {
  const response = options ? await updateStoryPartial(story, options) : await updateStoryAll(story)
  return new Story(story.book, response.story)
}

async function updateStoryAll(story: Story): Promise<API.StoryUpdateSuccess> {
  return await serverRequest<API.StoryUpdateInput, API.StoryUpdateSuccess>('/story/update', { story: story.toModel() })
}

async function updateStoryPartial(story: Story, values: UpdateStoryOptions): Promise<API.StoryUpdateSuccess> {
  const request = {
    bookId: story.bookId,
    storyId: story.storyId,
    ...values,
  }
  return await serverRequest<API.StoryUpdatePartialInput, API.StoryUpdateSuccess>('/story/update', request)
}

async function removeStory({ bookId, storyId }: Story): Promise<void> {
  await serverRequest<API.StoryRemoveInput, API.StoryRemoveSuccess>('/story/remove', { bookId, storyId })
}
