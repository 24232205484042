import { dateToString } from '@life/components'
import { Book, Location, Person } from '@life/frontend-model'
import { StoryNoun } from '@life/model'
import { PersonImage } from '../person/PersonImage'

type NounProps = {
  book: Book
  noun: StoryNoun
}
export function NounInfo({ book, noun }: NounProps): JSX.Element {
  return (
    <>
      {noun.type === 'person' && <PersonInfo person={book.findPerson(noun.id)} />}
      {noun.type === 'location' && <LocationInfo location={book.findLocation(noun.id)} />}
      {/* {noun.type === 'thing' && <ThingInfo thing={book.findThing(noun.id)} />} */}
    </>
  )
}

type PersonProps = {
  person: Person | undefined
}
function PersonInfo({ person }: PersonProps): JSX.Element {
  if (!person) return <></>
  return (
    <div className="flex flex-col space-y-2">
      <h1 className="text-xl font-bold text-gray-800">{person.formalName}</h1>
      {person.image && <PersonImage image={person.image} className="w-36 h-36" />}
      {person.nickName && <h2 className="text-lg text-gray-800">Nickname: {person.nickName}</h2>}
      {person.otherLastNames && (
        <h2 className="text-lg text-gray-800">Other last name(s): {person.otherLastNamesDisplay}</h2>
      )}
      {person.birthDate && <h2 className="text-lg text-gray-800">Born: {dateToString(person.birthDate)}</h2>}
      {person.deathDate && <h2 className="text-lg text-gray-800">Died: {dateToString(person.deathDate)}</h2>}
    </div>
  )
}

type LocationProps = {
  location: Location | undefined
}
function LocationInfo({ location }: LocationProps): JSX.Element {
  if (!location) return <></>
  return (
    <div className="flex flex-col space-y-2">
      <h1 className="text-xl font-bold text-gray-800">{location.place}</h1>
      {location.geo && (
        <h2 className="text-lg text-gray-800">
          Where: {location.geo.latitude}, {location.geo.longitude}
        </h2>
      )}
      {/* <PersonImage image={location.image} /> */}
      {location.description && <h2 className="text-lg text-gray-800">Other last name(s): {location.description}</h2>}
    </div>
  )
}

/*
type ThingProps = {
 thing: Thing | undefined
}
function ThingInfo({ thing }: ThingProps): JSX.Element {
  return (
    <div className="flex flex-col space-y-2">
      <h1 className="text-xl font-bold text-gray-800">{thing.formalName}</h1>
      {thing.nickName && <h2 className="text-lg text-gray-800">Nickname: {thing.nickName}</h2>}
      {thing.otherLastNames && (
        <h2 className="text-lg text-gray-800">Other last name(s): {thing.otherLastNamesDisplay}</h2>
      )}
      {thing.birthDate && <h2 className="text-lg text-gray-800">Born: {dateToString(thing.birthDate)}</h2>}
      {thing.deathDate && <h2 className="text-lg text-gray-800">Died: {dateToString(thing.deathDate)}</h2>}
    </div>
  )
}
*/
