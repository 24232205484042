import {
  createParagraph,
  createSubstory,
  ImageId,
  isEmpty,
  isStoryBlock,
  Logger,
  PersonId,
  StoryBlockElement,
  StoryContent,
  StoryElement,
  StoryId,
  StoryImage,
  StoryPerson,
} from '@life/model'
import { BasePoint, BaseSelection, Editor, Element, Node, Point, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { Format, StoryEditor } from './types'

/**
 * This file includes several helper functions to mutate the editor. The Slate editor's
 * content is immutable by design and requires special classes (Transforms) to make changes.
 *
 * TODO - In this file there is a utility class that accepts an `editor` on construction. I'm not
 * sure which pattern is better (a single class or individual functions). Eventually, we should
 * likely use just one design pattern here.
 */

const logger = new Logger('story-edit-utils')

export function hasSelectionWithEditor(editor: StoryEditor): boolean {
  return hasSelection(editor.selection)
}

export function hasSelection(selection: BaseSelection): boolean {
  if (!selection) return false
  return !Range.isCollapsed(selection)
}

export function findRootElement(editor: StoryEditor): Point | undefined {
  const para = Editor.nodes(editor, {
    match: (n) => isStoryBlock(n as StoryContent),
  })
  for (const n of para) {
    return Editor.start(editor, n[1])
  }
  return undefined
}

export function getLinkAtSelection(editor: StoryEditor): StoryPerson | undefined {
  if (!editor.selection) return undefined
  return Editor.above<StoryPerson>(editor, {
    match: (n) => (n as StoryPerson).type === 'person',
  })?.[0]
}

export function isLinkAtSelection(editor: StoryEditor): boolean {
  return !!getLinkAtSelection(editor)
}

export function getImageAtCursor(editor: StoryEditor): StoryImage | undefined {
  if (!editor.selection) return undefined
  return Editor.above<StoryImage>(editor, {
    match: (n) => (n as StoryImage).type === 'image',
  })?.[0]
}

export function isImageAtCursor(editor: StoryEditor): boolean {
  return !!getImageAtCursor(editor)
}

/**
 * Adds a Person link at the selected editor text.
 * Returns true iff a link was added.
 */
export function addLinkAtSelection(editor: StoryEditor, id: PersonId): boolean {
  if (isLinkAtSelection(editor)) return false // already a link
  if (editor.selection && Range.isCollapsed(editor.selection)) return false // no selection
  Transforms.wrapNodes(editor, { type: 'person', id, children: [{ text: '' }] }, { split: true })
  return true
}

/**
 * Removes a Person link at the selected editor text.
 * Returns true iff a link was removed.
 */
export function removeLinkAtSelection(editor: StoryEditor): boolean {
  if (!isLinkAtSelection(editor)) return false // no link to remove
  Transforms.unwrapNodes(editor, {
    match: (n) => Element.isElement(n) && n.type === 'person',
  })
  return false
}

/**
 * Updates a Person link at the selected editor text.
 * Returns true iff a link was updated.
 */
export function updateLinkAtSelection(editor: StoryEditor, id: PersonId): boolean {
  if (!isLinkAtSelection(editor)) return false // no link to update
  Transforms.setNodes(
    editor,
    { type: 'person', id, children: [{ text: '' }] },
    { match: (n) => Element.isElement(n) && n.type === 'person' }
  )
  return false
}

export function addSubstoryAtSelection(editor: StoryEditor, id: StoryId): undefined | string {
  const para = findRootElement(editor)
  if (!para) {
    const error = "can't find the start of the paragraph. Exiting without adding substory"
    logger.warn(error)
    return error
  }

  Transforms.insertNodes<StoryElement>(editor, createSubstory(id), { at: para })
}

export type PartialStoryImage = {
  id?: ImageId
  caption?: string
  size?: StoryImage['size']
}
/**
 * Adds an Image at the selected editor text.
 * Returns true iff an image was added.
 */
export function addImageAtCursor(editor: StoryEditor, image: PartialStoryImage): boolean {
  if (!image.id) throw new Error('Adding image without ID')
  if (isImageAtCursor(editor)) return false // already an image
  if (editor.selection && !Range.isCollapsed(editor.selection)) return false // there's a selection
  const para = findRootElement(editor)
  if (!para) {
    alert("can't find start of paragraph. Exiting without inserting image")
    return false
  }
  Transforms.insertNodes<StoryElement>(
    editor,
    {
      type: 'image',
      id: image.id,
      size: image.size,
      caption: image.caption,
      children: [],
    },
    {
      at: para,
    }
  )
  return true
}

/**
 * Updates an Image at the selected editor text.
 * Returns true iff an image was updated.
 */
export function updateImageAtCursor(editor: StoryEditor, image: PartialStoryImage): boolean {
  let img = getImageAtCursor(editor)
  if (!img) {
    logger.warn('tried to update an image, but an image was not there')
    return false
  }
  img = { ...img, ...image }
  Transforms.setNodes<StoryElement>(editor, img)
  return true
}

export function toggleFormat(editor: StoryEditor, format: Format): void {
  if (isFormatActive(editor, format)) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export function isFormatActive(editor: StoryEditor, format: Format): boolean {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export class EditorUtil {
  isStringEmpty(): boolean {
    const element = this.getElementAtRow()
    if (!element) return true
    return isEmpty(Node.string(element))
  }
  constructor(private editor: StoryEditor) {}

  getSelectedRow(): number | false {
    const { selection } = this.editor
    if (!selection) return false
    const [row] = selection.focus.path
    return row
  }

  moveUp(): void {
    const row = this.getSelectedRow()
    if (row === false || row === 0) return
    Transforms.moveNodes(this.editor, {
      at: [row],
      to: [row - 1],
    })
  }

  moveDown(): void {
    const row = this.getSelectedRow()
    if (row === false) return
    Transforms.moveNodes(this.editor, {
      at: [row],
      to: [row + 1],
    })
  }

  erase(): void {
    if (!this.editor.selection) return
    function getRow(point: BasePoint): number {
      return point.path[0]
    }

    const { anchor, focus } = this.editor.selection
    const focusRow = getRow(focus)
    const anchorRow = getRow(anchor)

    for (let i = focusRow; i >= anchorRow; i--) {
      Transforms.removeNodes(this.editor, { at: [i] })
    }

    if (this.isEmpty()) this.insertNewLine()
  }

  private isEmpty(): boolean {
    return isEmpty(this.editor.children)
  }

  private insertNewLine(): void {
    Transforms.insertNodes<StoryElement>(this.editor, createParagraph())
  }

  getElementAtRow(): StoryBlockElement | undefined {
    const row = this.getSelectedRow()
    if (row === false) return
    const descendant = this.editor.children[row]
    if (isStoryBlock(descendant)) return descendant
    return
  }

  getElementAtFocus(): StoryElement | undefined {
    const row = this.getSelectedRow()
    if (row === false) return
    const descendant = this.editor.children[row]
    if (isStoryBlock(descendant)) return descendant
    return
  }

  isFirstRow(): boolean {
    return this.getSelectedRow() === 0
  }

  isLastRow(): boolean {
    return this.getSelectedRow() === this.editor.children.length - 1
  }

  isRowVoid(): boolean {
    const element = this.getElementAtFocus()
    if (!element) return false
    return this.editor.isVoid(element)
  }

  hasSelection(): boolean {
    return hasSelectionWithEditor(this.editor)
  }

  getSelectedText(): string {
    const { selection } = this.editor
    if (!selection || !this.hasSelection()) return ''
    return Editor.string(this.editor, selection)
  }

  getSelectedFragments(): StoryContent[] {
    const { selection } = this.editor
    if (!selection || !this.hasSelection()) return []
    return Node.fragment(this.editor, selection)
  }

  focus(): void {
    ReactEditor.focus(this.editor)
  }

  isFocused(): boolean {
    return ReactEditor.isFocused(this.editor)
  }
}
