import { API, API_VERSION, Logger } from '@life/model'

const logger = new Logger('api-core')

type App = {
  auth: Auth | null
}

const app: App = {
  auth: null,
}

export function setAuth(auth: Auth) {
  app.auth = auth
}

type IdToken = {
  __raw: string
}

type Auth = {
  getIdTokenClaims: () => Promise<IdToken | undefined>
  isAuthenticated: boolean
  loginWithRedirect: () => void
}

const baseUrl = getBaseUrl()
export const serverBaseUrl = baseUrl
function getBaseUrl() {
  return process.env.REACT_APP_API_URL
}

async function getOptions(options: RequestInit, auth = true): Promise<RequestInit> {
  let authorization = {}
  if (auth) {
    if (!app.auth?.isAuthenticated) {
      logger.info('app.auth', app.auth)
      logger.warn('You are not logged in. Please refresh')
      app.auth?.loginWithRedirect()
      return {}
    }
    const tokenClaim = await app.auth?.getIdTokenClaims()
    const token = tokenClaim?.__raw
    if (!token) {
      logger.warn('You are not logged in. Please refresh')
      app.auth?.loginWithRedirect()
      return {}
    }
    authorization = { Authorization: `Bearer ${token}` }
  }

  return {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      ...authorization,
    },
    ...options,
  }
}

/**
 * Makes a request to the @Life backend server and either returns an API.ResponseSuccess
 * or throws an API.ReponseError.
 */
export async function serverRequest<I, O extends API.ResponseSuccess>(
  path: string,
  input?: I,
  auth = true
): Promise<O> {
  let response
  try {
    response = await httpPost<I, O>(path, auth)(input)
  } catch (error) {
    throw API.toResponseError(error)
  }
  if (response.type === 'success') return response
  throw response
}

export async function serverRequestNoAuth<I, O extends API.ResponseSuccess>(path: string, input?: I): Promise<O> {
  return serverRequest(path, input, false)
}

type UnexpectedError = API.ResponseError<'Server'>

function httpPost<I, O>(path: string, auth: boolean): (input?: I) => Promise<O | UnexpectedError> {
  async function call<I, O>(input?: I): Promise<O | UnexpectedError> {
    logger.info(`post to path ${path} with input`, input)
    const options = await getOptions(
      {
        method: 'POST',
        body: JSON.stringify(input || '{}'),
      },
      auth
    )

    const url = baseUrl + path
    return fetch(url, options).then(async (response): Promise<O | UnexpectedError> => {
      const { status } = response
      if (status >= 200 && status < 400) {
        const body = await response.json()
        validateApiVersion(body)
        return body
      }
      return {
        type: 'error',
        error: 'Server',
        message: await response.text(),
      }
    })
  }
  return call
}

function validateApiVersion(body: API.ResponseOutput<unknown>): void {
  const apiVersion = body.apiVersion
  if (apiVersion && +apiVersion > API_VERSION) {
    logger.info(`API_VERSION mismatch. Expected ${API_VERSION}, got ${apiVersion}`)
    throw API.toResponseError('Web client is out of date. Please refresh your browser to upgrade.')
  }
}
