import { classNames, PersonIcon, TaskCompleteIcon, Tooltip } from '@life/components'
import { Book } from '@life/frontend-model'
import { createParagraph, isEmpty, isStoryImage, isStoryPerson, isStorySubstory, StoryElement } from '@life/model'
import { Link } from 'react-router-dom'
import { Node, Transforms } from 'slate'
import { DefaultElement, RenderElementProps, RenderLeafProps } from 'slate-react'
import { insertData } from './insertData'
import { StoryImage } from './StoryImage'
import { StorySubstory } from './StorySubstory'
import { ToolbarActionEvent } from './Toolbar'
import { StoryEditor } from './types'
import { EditorUtil } from './utils'

export function useEditorConfig(editor: StoryEditor, book: Book, handleAction?: ToolbarActionEvent) {
  const { isVoid, isInline, insertBreak, deleteBackward } = editor
  const util = new EditorUtil(editor)
  editor.isVoid = (element) => {
    if (isStoryImage(element)) return true
    if (isStorySubstory(element)) return true
    return isVoid(element)
  }
  editor.isInline = (element) => {
    if (isStoryPerson(element)) return true
    return isInline(element)
  }

  editor.insertBreak = (...args) => {
    if (util.isRowVoid()) {
      Transforms.move(editor, {
        distance: -1,
        unit: 'line',
      })
      Transforms.insertNodes(editor, createParagraph())
    } else {
      insertBreak(...args)
    }
  }

  editor.deleteBackward = (...args) => {
    if (util.isRowVoid() || util.isStringEmpty()) {
      util.erase()
    } else {
      deleteBackward(...args)
    }
  }

  function renderElement(props: RenderElementProps): JSX.Element {
    const selected = props.element === util.getElementAtRow()
    return renderElementWithOptions(props, book, selected, handleAction)
  }

  editor.insertData = (data) => insertData(editor, data)

  return { renderElement, renderLeaf }
}

function renderElementWithOptions(
  props: RenderElementProps,
  book: Book,
  selected: boolean,
  handleAction?: ToolbarActionEvent
): JSX.Element {
  const { element, children, attributes } = props
  switch (element.type) {
    case 'substory':
      return <StorySubstory {...props} selected={selected} book={book} />
    case 'image':
      return <StoryImage {...props} selected={selected} book={book} handleAction={handleAction} />
    case 'comment':
      return (
        <span className="bg-yellow-200" {...attributes}>
          {children}
        </span>
      )
    case 'task':
      return (
        <div {...attributes} className="flex max-w-prose py-5 space-x-2 relative text-green-700">
          <TaskCompleteIcon className="w-6 cursor-default" />
          {isNodeEmpty(element) && (
            <p contentEditable={false} className="absolute left-6 italic text-gray-400">
              Enter task here...
            </p>
          )}
          <p className="flex-1 font-light">{children}</p>
        </div>
      )
    case 'paragraph': {
      const padding = element.leftPadding ?? 0
      return (
        <div
          className={classNames('pb-4 leading-8 max-w-prose text-xl text-gray-700 font-serif', padding > 0 && 'pl-10')}
          {...attributes}
        >
          {children}
        </div>
      )
    }
    case 'person': {
      const person = book.findPerson(element.id)
      return (
        <span {...attributes} className="inline-flex items-start">
          <span className="hover:underline text-cyan-600">{children}</span>
          {person ? (
            <Link to={person.link} className="cursor-pointer" contentEditable={false}>
              <Tooltip message="Go to Person">
                <PersonIcon />
              </Tooltip>
            </Link>
          ) : (
            <span contentEditable={false} className="text-xs ml-1 text-red-700 cursor-default">
              Missing from Book
            </span>
          )}
        </span>
      )
    }
    default:
      console.warn('No implementation for', element)
      return <DefaultElement {...props} />
  }
}

function isNodeEmpty(element: StoryElement): boolean {
  return isEmpty(Node.string(element))
}

function renderLeaf({ attributes, children, leaf }: RenderLeafProps): JSX.Element {
  let el = <>{children}</>

  if (leaf.bold) {
    el = <strong className="font-bold">{el}</strong>
  }

  if (leaf.code) {
    el = <code>{el}</code>
  }

  if (leaf.italic) {
    el = <em>{el}</em>
  }

  if (leaf.underline) {
    el = <u>{el}</u>
  }

  return <span {...attributes}>{el}</span>
}
