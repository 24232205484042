import { ButtonBase, NavIcon, NotReady, PageHeading, PagePadding, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { useNavigate } from 'react-router-dom'
import { PdfViewer } from './PdfViewer'

export function PdfController(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const navigate = useNavigate()
  const { book, isLoading, error } = useBook(bookSlug)
  if (isLoading || error || !book) return <NotReady type="PdfInfo" id="" isLoading={isLoading} error={error} />

  function navToSite(): void {
    navigate(`/site/${bookSlug}`)
  }
  return (
    <PagePadding>
      <div className="flex flex-col flex-wrap">
        <PageHeading title={['Book', book.title, 'Preview']} className="flex-1">
          Book Preview
        </PageHeading>
        <h1 className="text-lg font-bold text-gray-800 pt-4">Preview PDF</h1>
        <PdfViewer book={book} />
        <h1 className="text-lg font-bold text-gray-800 pt-4">Preview Viewer Site</h1>
        <div className="pt-8 pl-11 space-y-4">
          <div>Preview the site your invitees will see when they view your book.</div>
          <ButtonBase
            className="bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
            icon={<NavIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
            onClick={navToSite}
          >
            Go to Site
          </ButtonBase>
        </div>
      </div>
    </PagePadding>
  )
}
