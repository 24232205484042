import * as Model from '@life/model'
import { Book } from './book'
import { BookItem, UnsavedBookItem } from './book-item'

export class UnsavedLocation extends UnsavedBookItem {
  place: string
  geo?: {
    latitude: number
    longitude: number
  }
  imageId?: Model.ImageId
  description?: string

  constructor(book: Book, data: Model.UnsavedLocation) {
    super(book)
    this.place = data.place
    this.geo = data.geo
    this.imageId = data.imageId
    this.description = data.description
  }

  override get key(): string {
    return this.place
  }

  override toUnsavedModel(): Model.UnsavedLocation {
    return {
      bookId: this.bookId,
      place: this.place,
      geo: this.geo,
      imageId: this.imageId,
      description: this.description,
      status: 'stub',
    }
  }
}

export class Location extends UnsavedLocation implements BookItem {
  readonly locationId: Model.LocationId

  constructor(book: Book, data: Model.Location) {
    super(book, data)
    this.locationId = data.locationId
  }

  override get key(): string {
    return this.locationId
  }

  toModel(): Model.Location {
    return {
      locationId: this.locationId,
      ...this.toUnsavedModel(),
    }
  }
}
