import type { UserId } from './user'

export const API_VERSION = 1
export const DB_VERSION = 1

export type Slug = string

export type DateBreakdown = {
  around?: boolean
  year: number
  month?: number
  day?: number
}
export type DateSingle = DateBreakdown & {
  type: 'single'
}
export type DateRange = {
  type: 'range'
  start: DateBreakdown
  end: DateBreakdown
}

export type LifeDate = DateBreakdown | DateSingle | DateRange
export type LifeDateType = DateSingle['type'] | DateRange['type'] | 'breakdown'

export type AuditProps = {
  createdAt?: number
  createdBy?: UserId
  updatedAt?: number
  updatedBy?: UserId
  isDeleted?: boolean
}
