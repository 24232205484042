import { NotReady, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { useEffect, useRef } from 'react'
import { ParentStoryLink } from '../ParentStoryLink'
import { InformationBar } from './InformationBar'
import { StoryHeading } from './StoryHeading'
import { StoryReader } from './StoryReader'

export function StoryPage(): JSX.Element {
  const [bookSlug, storyId] = useRequiredParam(['bookSlug', 'storyId'])
  const { book, isLoading, error } = useBook(bookSlug)
  const scrollDiv = useRef<HTMLDivElement>(null)
  useEffect(() => {
    scrollDiv.current?.scrollTo({
      top: 0,
    })
  }, [scrollDiv, storyId])

  if (isLoading || error || !book || !storyId) {
    return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  }
  const story = book.findStory(storyId)
  if (!story) return <NotReady type="Story" id={storyId} notFound />

  // StoryPage doesn't use PagePadding div (like other pages) since it needs custom padding and layout
  return (
    <div className="w-full h-full flex flex-row items-stretch">
      <div className="h-full flex-1 flex flex-col">
        <div ref={scrollDiv} className="h-full flex-1 overflow-y-auto">
          <ParentStoryLink parent={story.parent} />
          <StoryHeading book={book} story={story} />
          <StoryReader book={book} story={story} />
        </div>
      </div>
      <InformationBar story={story} />
    </div>
  )
}
