import { Book } from '@life/frontend-model'
import { StoryNoun } from '@life/model'
import { Link } from 'react-router-dom'
import { ViewContent } from './ViewContent'

type Props = {
  book: Book
  noun: StoryNoun
}

export function ViewLink({ noun, book }: Props): JSX.Element {
  return (
    <Link to={'../people/' + noun.id} className="cursor-pointer hover:underline text-cyan-600">
      <ViewContent book={book} content={noun.children} />
    </Link>
  )
}
