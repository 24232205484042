import { StoryParagraphType, StoryStatusType } from '@life/model'

export class Translate {
  public static StoryStatus(status: StoryStatusType): string {
    switch (status) {
      case 'approved':
        return 'Print Ready'
      case 'complete':
        return 'Complete'
      case 'draft':
        return 'Draft'
      case 'stub':
        return 'Idea'
      default:
        return '?'
    }
  }

  public static StoryParagraph(paragraph: StoryParagraphType): string {
    switch (paragraph) {
      case 'image':
        return 'Photo'
      case 'paragraph':
        return 'Paragraph'
      case 'substory':
        return 'Substory'
      case 'task':
        return 'Task'
      default:
        return '?'
    }
  }
}
