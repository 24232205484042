export const ONE_SECOND = 1000
export const ONE_MINUTE = 60 * ONE_SECOND
export const ONE_HOUR = 60 * ONE_MINUTE
export const ONE_DAY = 24 * ONE_HOUR
export const ONE_WEEK = 7 * ONE_DAY
/**
 * 30 days, which is rounded down from the astronomical average
 */
export const ONE_MONTH = 30 * ONE_DAY
/**
 * 365 days, which is rounded down from the astronomical average
 */
export const ONE_YEAR = 365 * ONE_DAY
