import { classNames } from '@life/components'
import { isEmpty } from '@life/model'
import { ImageList } from '../image'
import { PersonList } from '../person'
import { StoryList } from '../story'
import { SearchResult } from './search'

type Props = {
  result: SearchResult
  className?: string
}

export function SearchResults({ result, className }: Props): JSX.Element {
  if (!result.book) return <></>
  if (isEmpty(result.stories) && isEmpty(result.images) && isEmpty(result.people) && isEmpty(result.locations)) {
    return (
      <div className="m-5 p-5 rounded-xl border-2 border-dashed flex flex-row items-center space-x-3">
        <p>No results found.</p>
      </div>
    )
  }

  const headerStyles = 'mt-2 pt-1 border-t-2 text-lg font-bold italic'
  return (
    <div className={classNames('flex flex-col', className)}>
      {result.stories.length > 0 && (
        <>
          <div className={headerStyles}>Stories</div>
          <StoryList noHeader book={result.book} stories={result.stories} />
        </>
      )}
      {result.images.length > 0 && (
        <>
          <div className={headerStyles}>Photos</div>
          <ImageList noHeader book={result.book} images={result.images} />
        </>
      )}
      {result.people.length > 0 && (
        <>
          <div className={headerStyles}>People</div>
          <PersonList noHeader book={result.book} people={result.people} />
        </>
      )}
      {result.locations.length > 0 && (
        <>
          <div className={headerStyles}>Locations</div>
        </>
      )}
    </div>
  )
}
