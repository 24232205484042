import { NotReady, PageHeading, PagePadding, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { DropZone } from './DropZone'
import { FileReplacer, FileUploadAttempt } from './FileUploader'

export function ReplaceImage(): JSX.Element {
  const [bookSlug, imageId] = useRequiredParam(['bookSlug', 'imageId'])
  const { book, isLoading, error } = useBook(bookSlug)
  const [uploadFile, setUploadFile] = useState<FileUploadAttempt>()
  // End of Hooks

  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  const image = book.findImage(imageId)
  if (!image) return <NotReady type="Image" id={imageId} notFound />

  function onDrop(files: File[], rejections: FileRejection[]): void {
    if (!book) return
    const filesGood = files.map((file) => ({ file: file }))
    const filesBad = rejections.map((file) => ({ file: file.file, error: file.errors.join(', ') }))
    setUploadFile([...filesGood, ...filesBad][0])
  }

  return (
    <PagePadding>
      <PageHeading title="Replace Photo">Replace Photo</PageHeading>

      {!uploadFile ? (
        <DropZone onDrop={onDrop} singleFile />
      ) : (
        <div className="rounded-xl bg-gray-100 p-2">
          <FileReplacer image={image} replaceAttempt={uploadFile} onCancel={() => setUploadFile(undefined)} />
        </div>
      )}
    </PagePadding>
  )
}
