import { classNames, SearchIcon } from '@life/components'
import { ChangeEvent, useEffect } from 'react'

let lastSearchText = ''
export function resetSearchTerm(): void {
  lastSearchText = ''
}

type Props = {
  onSearch: (text: string) => void
  className?: string
}
export function SearchBar({ onSearch, className }: Props): JSX.Element {
  useEffect(() => {
    if (lastSearchText) handleSearch({ target: { value: lastSearchText } } as ChangeEvent<HTMLInputElement>)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- purposely execute just once
  }, [])

  function handleSearch(event: ChangeEvent<HTMLInputElement>): void {
    lastSearchText = event.target.value
    onSearch(lastSearchText)
  }

  return (
    <div className={classNames('flex', className)}>
      <div className="relative items-stretch w-full mb-4 xl:w-96">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <SearchIcon className="h-6 w-6 text-gray-500" />
        </span>
        <input
          type="search"
          className="pl-10 w-full shadow-sm text-xl rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Search..."
          onChange={handleSearch}
          value={lastSearchText}
        />
      </div>
    </div>
  )
}
