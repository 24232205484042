import { API, FullBook, isStoryImage, Logger, Slug, StoryImage } from '@life/model'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { serverRequest, serverRequestNoAuth } from './api'
import { Book, BookInfo, BookView, TableOfContents, UnsavedBook } from './book'
import { ONE_MINUTE } from './time'

const logger = new Logger('api-book')

const bookInfoKey = (slug: Slug) => `BOOK_INFO:${slug}`
const bookIdKey = (slug: Slug) => `BOOK:${slug}`
export const bookKey = (book: BookInfo) => bookIdKey(book.slug || book.bookId || 'undefined')
export const booksKey = 'BOOKVIEWS'

export const queryOptions = { retry: 1, staleTime: 15 * ONE_MINUTE }

export type BookState = {
  isLoading: boolean
  error?: API.GetErrors
  book?: Book
}
export function useBook(slug: Slug): BookState {
  if (!slug) logger.error('undefined or empty slug passed to useBook')
  const query = useQuery<Book | undefined, API.GetErrors>(bookIdKey(slug), () => readBook(slug), queryOptions)
  return { ...query, error: query.error ?? undefined, book: query.data }
}

export type BookInfoState = {
  isLoading: boolean
  error?: API.GetErrors
  book?: BookInfo
}
export function useBookInfo(slug: Slug): BookInfoState {
  if (!slug) logger.error('undefined or empty slug passed to useBookInfo')
  const query = useQuery<BookInfo | undefined, API.GetErrors>(bookInfoKey(slug), () => readBookInfo(slug), queryOptions)
  return { ...query, error: query.error ?? undefined, book: query.data }
}

export type BooksState = {
  isLoading: boolean
  error?: API.ListErrors
  list?: BookView[]
}
export function useBooks(): BooksState {
  const query = useQuery<BookView[], API.ListErrors>(booksKey, async () => listBooks(), queryOptions)
  return { ...query, error: query.error ?? undefined, list: query.data }
}

export type AddBookState = {
  isAdding: boolean
  add: (book: UnsavedBook) => Promise<BookInfo>
}
export function useAddBook(): AddBookState {
  const queryClient = useQueryClient()
  const mutation = useMutation((book: UnsavedBook) => addBook(book))
  async function add(book: UnsavedBook): Promise<BookInfo> {
    try {
      const output = await mutation.mutateAsync(book)
      queryClient.setQueryData<BookView[] | undefined>(booksKey, (previous) => {
        if (!previous) return previous
        return [...previous, new BookView({ ...output.toModel(), role: 'Owner' })]
      })
      return output
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, add, isAdding: mutation.isLoading }
}

export type UpdateBookState = {
  isUpdating: boolean
  update: (book: BookInfo) => Promise<BookInfo>
}
export function useUpdateBook(): UpdateBookState {
  const queryClient = useQueryClient()
  const mutation = useMutation((book: BookInfo) => updateBook(book))
  async function update(book: BookInfo): Promise<BookInfo> {
    try {
      const output = await mutation.mutateAsync(book)
      queryClient.setQueryData<Book | undefined>(bookKey(book), (previous) => {
        if (!previous) return previous
        return Object.assign(previous, output)
      })
      return output
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update, isUpdating: mutation.isLoading }
}

export type RemoveBookState = {
  isRemoving: boolean
  remove: (book: BookInfo) => Promise<void>
}
export function useRemoveBook(): RemoveBookState {
  const queryClient = useQueryClient()
  const mutation = useMutation((book: BookInfo) => removeBook(book))
  async function remove(book: BookInfo): Promise<void> {
    try {
      await mutation.mutateAsync(book)
      queryClient.setQueryData<Book | undefined>(bookKey(book), () => undefined)
      queryClient.setQueryData<BookView[] | undefined>(booksKey, (previous) => {
        if (!previous) return previous
        return previous.filter((b) => b.bookId !== book.bookId)
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, remove, isRemoving: mutation.isLoading }
}

export type UpdateTocState = {
  isUpdating: boolean
  update: (toc: TableOfContents) => Promise<void>
}
export function useUpdateToc(): UpdateTocState {
  const queryClient = useQueryClient()
  const mutation = useMutation((toc: TableOfContents) => updateTableOfContents(toc))
  async function update(toc: TableOfContents): Promise<void> {
    try {
      await mutation.mutateAsync(toc)
      queryClient.setQueryData<Book | undefined>(bookKey(toc.book), (previous) => {
        if (!previous) return previous
        previous.toc = toc
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update, isUpdating: mutation.isLoading }
}

async function readBook(id: Slug): Promise<Book | undefined> {
  const response = await serverRequest<API.BookGetInput, API.BookGetSuccess>('/book/get', { id })
  return response.book && new Book(fixLegacy(response.book))
}

async function readBookInfo(slug: Slug): Promise<BookInfo | undefined> {
  const response = await serverRequestNoAuth<API.BookInfoInput, API.BookInfoSuccess>('/book/info', { slug })
  return response.book && new BookInfo(response.book)
}

async function listBooks(): Promise<BookView[]> {
  const response = await serverRequest<API.BookListInput, API.BookListSuccess>('/book/list')
  const books = response.list.map((b) => new BookView(b))
  books.sort((a, b) => a.title.localeCompare(b.title))
  return books
}

async function addBook(book: UnsavedBook): Promise<BookInfo> {
  const response = await serverRequest<API.BookAddInput, API.BookAddSuccess>('/book/add', {
    book: book.toUnsavedModel(),
  })
  return new BookInfo(response.book)
}

async function updateBook(book: BookInfo): Promise<BookInfo> {
  const response = await serverRequest<API.BookUpdateInput, API.BookUpdateSuccess>('/book/update', {
    book: book.toModel(),
  })
  return new BookInfo(response.book)
}

async function removeBook({ bookId }: BookInfo): Promise<void> {
  await serverRequest<API.BookRemoveInput, API.BookRemoveSuccess>('/book/remove', { bookId })
}

async function updateTableOfContents(toc: TableOfContents): Promise<void> {
  await serverRequest<API.BookTocUpdateInput, API.BookTocUpdateSuccess>('/book/toc/update', {
    bookId: toc.bookId,
    toc: toc.toModel(),
  })
}

// TODO: This probably isn't needed anymore. Logging to make sure.
function fixLegacy(book: FullBook): FullBook {
  // Copy image caption to Story 'image' element
  book.content.images.forEach((image) => {
    const caption = (image as { caption?: string }).caption
    if (caption) {
      book.content.stories.forEach((s) => {
        const imageEls = s.content
          .filter((el) => (el as StoryImage).type === 'image' && (el as StoryImage).id === image.imageId)
          .filter(isStoryImage)
        imageEls.forEach((img) => {
          if (!img.caption) {
            logger.warn(`Fixing legacy image caption for ${book.info.bookId}, ${caption}`)
            img.caption = caption
          }
        })
      })
    }
  })
  // Copy image caption to notes
  book.content.images.forEach((i) => {
    const legacy = i as { caption?: string }
    if (legacy.caption && !i.notes) {
      logger.warn(`Fixing legacy image notes for ${book.info.bookId}, ${legacy.caption}`)
      i.notes = legacy.caption
      delete legacy.caption
    }
  })
  return book
}
