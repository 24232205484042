import { ButtonAdd, NotReady, PageHeading, PagePadding, useQueryParam, useRequiredParam } from '@life/components'
import { Book, useBook } from '@life/frontend-model'
import { isEmpty } from '@life/model'
import { canEditBook, linkToPerson } from '../../common'
import { PersonList } from './PersonList'

export function PeopleController(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const filter = useQueryParam('filter')
  const { book, isLoading, error } = useBook(bookSlug)
  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />

  if (isEmpty(book.people)) {
    return (
      <PagePadding narrow>
        <PageHeading title="People">People</PageHeading>
        <div className="mt-3 p-5 rounded-xl border-2 border-dashed flex items-center space-x-3">
          <ButtonAddPerson book={book} />
          <p>There are no people in your book yet.</p>
        </div>
      </PagePadding>
    )
  }

  return (
    <PagePadding wide>
      <div className="w-full flex flex-col space-y-3">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <PageHeading title="People" className="flex-1">
            People
          </PageHeading>
          <div className="flex justify-between space-x-2">
            <ButtonAddPerson book={book} />
          </div>
        </div>
        <PersonList book={book} people={book.people} initialFilter={filter} />
      </div>
    </PagePadding>
  )
}

function ButtonAddPerson({ book }: { book: Book }) {
  return (
    <ButtonAdd to={linkToPerson(book)} canEdit={canEditBook(book)}>
      Add Person
    </ButtonAdd>
  )
}
