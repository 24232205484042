import { classNames, RemoveIcon, Tooltip } from '@life/components'
import { Story } from '@life/frontend-model'
import { Link } from 'react-router-dom'
import { InsertStory } from './InsertStory'

type ChapterGroupProp = {
  chapters: Story[] | undefined
  setChapters: (chapters: Story[]) => void
  selectedStory: Story | undefined
  selectStory: (story: Story | undefined) => void
  startingNumber?: number
}

export function ChapterGroup({
  selectedStory,
  chapters,
  selectStory,
  setChapters,
  startingNumber = 1,
}: ChapterGroupProp): JSX.Element {
  return (
    <div className={classNames('pb-3 pl-3', selectedStory ? '' : 'pt-4')}>
      {selectedStory && (
        <InsertStory
          onClick={() => {
            setChapters(insertStoryAtIndex(selectedStory, chapters, 0))
            selectStory(undefined)
          }}
          index={startingNumber}
        />
      )}
      {chapters &&
        chapters.map((chapter, chapterIndex) => {
          const chapterNumber = chapterIndex + startingNumber
          return (
            <div key={chapter.storyId} className="w-full">
              <div
                className={classNames(
                  'flex justify-between items-start w-full text-lg hover:bg-slate-50',
                  selectedStory ? '' : 'mb-4'
                )}
              >
                <div className="">
                  <div className="flex space-x-3">
                    <div>{chapterNumber}</div>
                    <Link to={chapter.link} className="hover:underline hover:text-cyan-600">
                      {chapter.title}
                    </Link>
                  </div>
                  <div className="pl-6">
                    {chapter.substories.map((section, index) => {
                      const sectionNumber = index + 1
                      return (
                        <div key={section.key}>
                          <div className="flex space-x-3 text-base">
                            <div>
                              {chapterNumber}.{sectionNumber}
                            </div>
                            <Link to={section.link} className="hover:underline hover:text-cyan-600">
                              {section.title}
                            </Link>
                          </div>
                          <div className="pl-8">
                            {section.substories.map((subsection, index) => {
                              const subSectionNumber = index + 1
                              return (
                                <div key={subsection.key} className="flex space-x-3 text-sm">
                                  <div>
                                    {chapterNumber}.{sectionNumber}.{subSectionNumber}
                                  </div>
                                  <Link to={subsection.link} className="hover:underline hover:text-cyan-600">
                                    {subsection.title}
                                  </Link>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <Tooltip message="Remove Story from Outline">
                  <RemoveIcon
                    onClick={() => {
                      setChapters(chapters.filter((c) => c !== chapter))
                    }}
                    className={classNames('w-6 text-gray-400', 'cursor-pointer hover:text-red-700')}
                  />
                </Tooltip>
              </div>
              {selectedStory && (
                <InsertStory
                  onClick={() => {
                    setChapters(insertStoryAtIndex(selectedStory, chapters, chapterIndex + 1))
                    selectStory(undefined)
                  }}
                  index={chapterNumber + 1}
                />
              )}
            </div>
          )
        })}
    </div>
  )
}

function insertStoryAtIndex(story: Story, chapters: Story[] = [], chapterIndex = 0): Story[] {
  const before = chapters.slice(0, chapterIndex)
  const after = chapters.slice(chapterIndex)
  return [...before, story, ...after]
}
