import { Book, TocSection } from '@life/frontend-model'
import { Logger, StoryId } from '@life/model'
import {
  createContext,
  ReactNode,
  RefObject,
  UIEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

const logger = new Logger('site-scroll')

/**
 * Scroll State Manager.
 * The Scroll State Manager provides a Provider and two hooks to help control scrolling
 * to particular chapters and links. The Provider maintains state while the hooks are used
 * by link state producers and consumers.
 *
 * Link State producers use the `useStoryInView` hook, which provides a reference and ID for
 * the HTML element, plus whether or not the element is on the page ("in view").
 * Currently, only Chapters/Sections/Subsections produce link state.
 *
 * Consumers modify their display depending on which producers are in view and scroll
 * to a view when it needed. They use the `useScrollState` hook to obtain that information.
 * Currently, the Outline is a consumer which highlights the top-most visible story;
 * and Person/Location/Thing links in the document are consumers that scroll to a link
 * when clicked.
 */

/**
 * The top margin of the scrolling area relative to the viewport.
 * This is the height of the TopNav. We add a little padding so the prior story will
 * show very slightly before it gets set to "current".
 */
const TOP_MARGIN = 64 + 20

type StoryState = { storyId: StoryId; ref: RefObject<HTMLElement> }[]
export type ScrollStateState = {
  currentStory: StoryId | undefined
  currentSection: TocSection
  scrollTo: (storyId: StoryId, linkId: string) => void
  register: (storyId: StoryId, ref: RefObject<HTMLElement>) => void
  handleScroll: (event: UIEvent) => void
}
const ScrollStateContext = createContext<ScrollStateState | null>(null)

type ProviderProps = {
  book: Book
  children: ReactNode
}
export function ScrollStateProvider({ book, children }: ProviderProps): JSX.Element {
  const [currentStory, setCurrentStory] = useState<StoryId>()
  const [currentSection, setCurrentSection] = useState<TocSection>('body')
  const storiesRef = useRef<StoryState>([])

  const register = useCallback((storyId: StoryId, ref: RefObject<HTMLElement>): void => {
    const stories = storiesRef.current
    if (!stories.find((ss) => ss.storyId === storyId)) {
      logger.verbose('register', storyId, ref)
      stories.push({ storyId, ref })
      stories.sort(
        (a, b) => (a.ref.current?.getBoundingClientRect().top ?? 0) - (b.ref.current?.getBoundingClientRect().top ?? 0)
      )
      setCurrentStory(stories[0].storyId)
      logger.verbose(
        '  stories:',
        stories.map((s) => `${s.storyId}, ${s.ref.current?.getBoundingClientRect().top}`)
      )
    }
  }, [])
  const scrollTo = useCallback(
    (storyId: StoryId, linkId: string): void => {
      function scroll(): void {
        const target = document.getElementById(linkId)
        target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
      const section = book.toc.findStorySection(storyId) ?? 'body'
      if (section === currentSection) {
        scroll()
      } else {
        storiesRef.current = []
        setCurrentSection(section)
        setTimeout(() => scroll(), 20)
      }
      setCurrentStory(storyId)
    },
    [book, currentSection]
  )
  const handleScroll = useCallback((): void => {
    // Stories are sorted highest on the page to lowest.
    // Find the lowest element whose top is at or above the top margin
    const stories = storiesRef.current
    const abovePage = stories.filter((s) => (s.ref.current?.getBoundingClientRect().top ?? 0) <= TOP_MARGIN)
    if (abovePage.length > 0) setCurrentStory(abovePage[abovePage.length - 1].storyId)
  }, [])

  return (
    <ScrollStateContext.Provider value={{ currentStory, currentSection, scrollTo, register, handleScroll }}>
      {children}
    </ScrollStateContext.Provider>
  )
}

export function useScrollState(): ScrollStateState {
  const state = useContext(ScrollStateContext)
  if (!state) throw new Error('useScrollState must be used within a ScrollStateContext')
  return state
}

export type StoryInView<T extends HTMLElement = HTMLElement> = {
  ref: RefObject<T>
  id: string
}
/** Returns attributes to spread into the HTML element. */
export function useStoryMarker<T extends HTMLElement = HTMLElement>(storyId: StoryId): StoryInView<T> {
  const { register } = useScrollState()
  const ref = useRef<T>(null)
  useEffect(() => {
    register(storyId, ref)
  }, [storyId, register])
  return { ref, id: storyId }
}
