import { useErrorNotification } from '@life/components'
import { Story, useUpdateStory } from '@life/frontend-model'
import { useState } from 'react'
import { TitleDialog } from '../TitleDialog'

type Props = {
  story: Story
}

export function Title({ story }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const updater = useUpdateStory()
  const { showError } = useErrorNotification()

  async function handleSave(title: string): Promise<void> {
    try {
      await updater.update(story, { title })
      setIsOpen(false)
    } catch (error) {
      showError('Error Updating title', error)
    }
  }

  return (
    <div
      onClick={() => setIsOpen(true)}
      className="text-center sm:text-left p-1 rounded-md hover:ring-2 cursor-pointer"
    >
      {story.title}
      <TitleDialog
        key={String(isOpen)}
        isOpen={isOpen}
        title={story.title}
        isSaving={updater.isUpdating}
        onSave={handleSave}
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}
