import { useNavigate } from 'react-router-dom'
import { BackIcon } from '../icons'
import { ButtonBase } from './ButtonBase'

export function ButtonBack() {
  const navigate = useNavigate()
  return (
    <ButtonBase
      onClick={() => {
        navigate(-1)
      }}
      className="bg-slate-600 hover:bg-slate-700 focus:ring-slate-500"
      icon={<BackIcon className="sm:-ml-0.5 sm:mr-2 h-4" aria-hidden="true" />}
    >
      <span className="hidden sm:block">Back</span>
    </ButtonBase>
  )
}
