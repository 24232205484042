import * as Model from '@life/model'
import { Book } from './book'
import { BookItem, UnsavedBookItem } from './book-item'

export class UnsavedInvite extends UnsavedBookItem {
  /** The date the invite expires */
  private _expires: number

  constructor(book: Book, data: Model.UnsavedInvite) {
    super(book)
    this._expires = data.expires
  }

  get key(): string {
    return this.bookId + '-' + this.expires
  }

  get expires(): number {
    return this._expires
  }
  set expires(exp: number) {
    this._expires = exp
  }
  set expiresIn(days: number) {
    this._expires = Date.now() + days * 24 * 60 * 60 * 1000
  }

  toUnsavedModel(): Model.UnsavedInvite {
    return {
      bookId: this.bookId,
      expires: this.expires,
    }
  }
}

export class Invite extends UnsavedInvite implements BookItem {
  inviteId: Model.InviteId
  /** URL used to accept the invite (generated by backend) */
  /** Person who initiated this invite (should be the book owner) */
  userId: Model.UserId
  url: string
  /** List of users who have accepted this invite */
  acceptedBy: { user: Model.UserId; when: number }[]

  constructor(book: Book, data: Model.Invite, url: string) {
    super(book, data)
    this.inviteId = data.inviteId
    this.userId = data.userId
    this.url = url
    this.acceptedBy = data.acceptedBy
  }

  get key(): string {
    return this.inviteId
  }

  toModel(): Model.Invite {
    return {
      inviteId: this.inviteId,
      userId: this.userId,
      acceptedBy: this.acceptedBy,
      ...this.toUnsavedModel(),
    }
  }
}
