import React from 'react'
import { FieldError } from 'react-hook-form'
import { classNames } from '../classNames'
import { HelpText } from './HelpText'

export type InputType = 'text'

type InputProps = {
  name: string
  label?: string
  help?: string
  type?: InputType
  error?: FieldError
  className?: string
  /**
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
   autoComplete?: 'off' | 'name' | 'email' | 'given-name' | 'last-name'
  } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(props, ref): JSX.Element {
  const { name, label, type = 'text', help, error, className, ...remaining } = props
  return (
    <div className="pt-2 px-1">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          ref={ref}
          {...remaining}
          name={name}
          type={type}
          aria-label={label}
          className={classNames(
            className,
            error
              ? 'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ring-red-400 border-red-300'
              : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
            'w-full shadow-sm text-sm rounded-md'
          )}
        />
        <HelpText error={error}>{help}</HelpText>
      </div>
    </div>
  )
})

