import { Link } from 'react-router-dom'
import { classNames } from '../classNames'
import { Spinner } from '../Spinner'
import { WithChildren } from '../types'

export type ButtonProps = WithChildren & {
  to?: string
  type?: 'submit' | 'reset' | 'button' | undefined
  toolTip?: string
  clicked?: boolean
  onClick?: VoidFunction
  disabled?: boolean | undefined
  icon?: JSX.Element | null
}

export function ButtonBase({
  to = '',
  clicked = false,
  className = '',
  onClick,
  disabled = false,
  icon,
  children,
  type = 'button',
}: ButtonProps) {
  if (to) {
    return (
      <Link
        to={clicked || disabled ? '' : to}
        onClick={onClick}
        className={classNames(
          'inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium',
          'rounded-md text-white whitespace-nowrap',
          'focus:outline-none focus:ring-2 focus:ring-offset-2',
          clicked ? 'bg-slate-600 hover:bg-slate-700' : disabled ? 'bg-gray-300 text-gray-100' : className,
          clicked || disabled ? '' : 'cursor-pointer'
        )}
      >
        {clicked ? <Spinner className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" /> : icon}
        <div className="whitespace-nowrap">{children}</div>
      </Link>
    )
  }
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium',
        'rounded-md text-white whitespace-nowrap',
        'focus:outline-none focus:ring-2 focus:ring-offset-2',
        clicked ? 'bg-slate-600 hover:bg-slate-700' : disabled ? 'bg-gray-300 text-gray-100' : className,
        disabled ? '' : 'cursor-pointer'
      )}
    >
      {clicked ? <Spinner className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" /> : icon}
      <div className="whitespace-nowrap">{children}</div>
    </button>
  )
}
