import { NotReady, PageHeading, PagePadding, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { useState } from 'react'
import { search, SearchResult } from './search'
import { SearchBar } from './SearchBar'
import { SearchResults } from './SearchResults'

export function SearchController(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)
  const [term, setTerm] = useState<string>()
  const [result, setResult] = useState<SearchResult>(search(book, undefined))
  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />

  function onSearch(text: string | undefined): void {
    setTerm(text?.trim())
    setResult(search(book, text))
  }

  return (
    <PagePadding>
      <PageHeading title="Search" className="flex-1">
        Search
      </PageHeading>
      <SearchBar onSearch={onSearch} className="my-3" />

      {term && <SearchResults result={result} className="-mt-4" />}
    </PagePadding>
  )
}
