import { Dialog, Transition } from '@headlessui/react'
import { createContext, Fragment, ReactNode, useCallback, useContext, useState } from 'react'
import { CloseIcon } from './icons'

type Props = {
  isOpen: boolean
  title: string
  modal?: boolean
  size?: 'lg' | 'md' | 'sm' | 'full'
  onClose: () => void
  children?: ReactNode
}
export function InfoPanel({ isOpen, title, modal, size = 'md', onClose, children }: Props): JSX.Element {
  const widths = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-2xl',
    full: 'max-w-full',
  }
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={modal ? () => undefined : onClose}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed top-16 bottom-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className={`pointer-events-auto w-screen ${widths[size]}`}>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <CloseIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export type InfoPanelConfig = {
  modal?: boolean
  size?: Props['size']
}
export type InfoPanelState = {
  isOpen: boolean
  openInfoPanel: (title: string, content: ReactNode, options?: InfoPanelConfig) => void
  closeInfoPanel: () => void
}
const InfoPanelContext = createContext<InfoPanelState | null>(null)

type ProviderProps = {
  children: ReactNode
  modal?: boolean
}
export function InfoPanelProvider({ modal = false, children }: ProviderProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [options, setOptions] = useState<InfoPanelConfig>()
  const [content, setContent] = useState<ReactNode>()
  const openInfoPanel = useCallback((t: string, c: ReactNode, o?: InfoPanelConfig) => {
    setTitle(t)
    setContent(c)
    setOptions(o)
    setIsOpen(true)
  }, [])
  const closeInfoPanel = useCallback(() => setIsOpen(false), [])
  const value = { isOpen, openInfoPanel, closeInfoPanel }
  return (
    <>
      <InfoPanelContext.Provider value={value}>{children}</InfoPanelContext.Provider>
      <InfoPanel isOpen={isOpen} title={title} modal={modal} {...options} onClose={() => setIsOpen(false)}>
        {content}
      </InfoPanel>
    </>
  )
}

export function useInfoPanel(): InfoPanelState {
  const state = useContext(InfoPanelContext)
  if (!state) throw new Error('useInfoPanel must be used within an InfoPanelProvider')
  return state
}
