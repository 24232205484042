import { TaskCompleteIcon } from '@life/components'
import { Book } from '@life/frontend-model'
import { StoryTask } from '@life/model'
import { ViewContent } from './ViewContent'

type Props = {
  book: Book
  task: StoryTask
}

export function ViewTask({ book, task }: Props): JSX.Element {
  return (
    <div className="flex max-w-prose py-5 space-x-2 relative text-green-700">
      <TaskCompleteIcon className="w-6 cursor-default" />
      <p className="flex-1 font-light">
        <ViewContent book={book} content={task.children} />
      </p>
    </div>
  )
}
