import { ButtonCancel, DeleteIcon, LifeDialog, WarningIcon } from '@life/components'
import { Logger, StoryContent, StoryId } from '@life/model'

const logger = new Logger('autosave')

const storageKey = (id: StoryId) => `editor-backup-${id}`

export function hasEditBackup(storyId: StoryId): boolean {
  return !!localStorage.getItem(storageKey(storyId))
}

export function readEditBackup(storyId: StoryId): StoryContent[] | undefined {
  const contents = localStorage.getItem(storageKey(storyId))
  logger.info('read contents', storyId)
  return contents ? JSON.parse(contents) : undefined
}

export function storeEditBackup(storyId: StoryId, contents: StoryContent[]): void {
  logger.info('store contents', storyId)
  localStorage.setItem(storageKey(storyId), JSON.stringify(contents))
}

export function clearEditBackup(storyId: StoryId): void {
  logger.info('clear contents', storyId)
  localStorage.removeItem(storageKey(storyId))
}

type RestorePromptProps = {
  isOpen: boolean
  onClose: (shouldRestore: boolean) => void
}
export function RestorePrompt({ isOpen, onClose }: RestorePromptProps): JSX.Element {
  if (!isOpen) return <></>
  return (
    <LifeDialog modal isOpen={isOpen} onClose={() => onClose(false)}>
      <LifeDialog.Content className="max-w-xl">
        <div className="flex items-center justify-center">
          <span className="p-1 rounded-lg bg-indigo-800">
            <WarningIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <p className="ml-3 text-normal text-gray-500">
            You have unsaved changes from an earlier session. Would you like to recover those changes?
          </p>
        </div>
      </LifeDialog.Content>
      <LifeDialog.Actions>
        <div className="flex flex-row-reverse justify-between gap-10">
          <ButtonCancel onClick={() => onClose(true)} icon={null}>
            Use Changes
          </ButtonCancel>
          <ButtonCancel
            onClick={() => onClose(false)}
            icon={<DeleteIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
            className="bg-red-600"
          >
            Trash Changes
          </ButtonCancel>
        </div>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
