/**
 * This follows the pattern in the now-removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * It closely follows the implementation in the open source Logto project
 * @see: https://github.com/remix-run/react-router/issues/8139
 * @see: https://github.com/logto-io/logto/pull/1409/files#diff-1b4127fa06d32178d73ae26fcbc61fe61f273c03b38832d9ed99e3bc89f887a5
 */
import { useContext, useEffect, useState } from 'react'
import { NavigateFunction, Navigator, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { ButtonCancel } from './buttons'
import { DeleteIcon, WarningIcon } from './icons'
import { LifeDialog } from './LifeDialog'

// Types needed for react-router-dom hacks
type Transition = {
  retry: () => void
}
type Blocker = (transition: Transition) => void
type BlockerNavigator = Navigator & {
  block(blocker: Blocker): () => void
}

type Props = {
  unsavedChanges: boolean
  message?: string
}
export function NavigationConfirmation({ unsavedChanges, message }: Props): JSX.Element {
  const { navigator } = useContext(NavigationContext)
  const [promptIsOpen, setPromptIsOpen] = useState(false)
  const [transition, setTransition] = useState<Transition>()

  useEffect(() => {
    if (!unsavedChanges) return
    const { block } = navigator as BlockerNavigator
    const unblock = block((transition) => {
      setPromptIsOpen(true)
      setTransition({
        ...transition,
        retry() {
          unblock()
          transition.retry()
        },
      })
    })
    return unblock // allow navigation on unmount
  }, [navigator, unsavedChanges])

  function handleClose(confirmNav: boolean): void {
    if (confirmNav) {
      transition?.retry()
    }
    setPromptIsOpen(false)
  }

  return <NavPrompt isOpen={promptIsOpen} message={message} onClose={handleClose} />
}

type NavPromptProps = {
  isOpen: boolean
  message?: string
  onClose: (confirmNav: boolean) => void
}
function NavPrompt({ isOpen, message = 'You have unsaved changes.', onClose }: NavPromptProps): JSX.Element {
  if (!isOpen) return <></>
  return (
    <LifeDialog modal isOpen={isOpen} onClose={() => onClose(false)}>
      <LifeDialog.Content>
        <div className="flex items-center justify-center">
          <span className="p-1 rounded-lg bg-red-600">
            <WarningIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <p className="ml-3 text-normal max-w-prose text-gray-500">{message}</p>
        </div>
      </LifeDialog.Content>
      <LifeDialog.Actions>
        <div className="flex flex-row-reverse justify-between gap-10">
          <ButtonCancel onClick={() => onClose(false)} icon={null}>
            Keep Changes
          </ButtonCancel>
          <ButtonCancel
            onClick={() => onClose(true)}
            icon={<DeleteIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
            className="bg-red-600"
          >
            Trash Changes
          </ButtonCancel>
        </div>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}

/**
 * Using NavPrompt on a page with a Save button that navigates after saving is problematic
 * with the NavPrompt implementation. The only solution I've found is to insert a short delay
 * between saving and navigating. This function makes it easier to accomplish that.
 *
 * Note that when using NavPrompt on a page of this type, it is necessary to either reset()
 * a Form (so that `isDirty` gets set to `false`) or to add `&& !isSaved` to the `unsavedChanges`
 * attribute in NavPrompt. Otherwise, the NavPrompt will display when navigating.
 */
export async function navigateConfirmed(navigate: NavigateFunction, path: string | number): Promise<void> {
  await delay(1)
  navigate(path as string)
}

async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
