import { classNames } from '../classNames'
import { HelpIcon, SearchIcon, SortAscendingIcon, SortDescendingIcon, ViewGridIcon, ViewListIcon } from '../icons'
import { DropdownMenu } from './DropdownMenu'
import { SortDef, SortDirection, ViewType } from './ItemList'

type HeaderProps<S extends string> = {
  sortDefs: SortDef<S>[]
  sortType?: S
  setSortType?: (type: S) => void
  sortDirection: SortDirection
  setSortDirection: (direction: SortDirection) => void
  filter: string
  setFilter: (filter: string) => void
  viewType: ViewType
  setViewType: (viewType: ViewType) => void
  showHelp?: () => void
}
export function Header<S extends string>({
  sortDefs,
  sortType,
  setSortType,
  sortDirection,
  setSortDirection,
  filter,
  setFilter,
  viewType,
  setViewType,
  showHelp,
}: HeaderProps<S>): JSX.Element {
  return (
    <div className="flex w-full flex-nowrap mb-2 pb-2 border-b-2">
      <div className="flex flex-row w-full justify-between space-x-2 items-center">
        <div className="flex-1 relative">
          <span className="absolute inset-y-0 left-0 items-center mt-3 pl-2 hidden sm:block">
            <SearchIcon className="h-6 w-6 text-gray-500" />
          </span>
          <input
            type="search"
            className="sm:pl-10 w-full shadow-sm text-xl rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Search..."
            value={filter}
            onChange={(element) => setFilter(element.target.value)}
          />
        </div>
        <div className="flex flex-row">
          {showHelp && (
            <button onClick={showHelp}>
              <div className="p-1 rounded-full hover:bg-slate-200">
                <HelpIcon className="h-6 w-6 text-gray-500" />
              </div>
            </button>
          )}
          {sortDefs.length > 0 && (
            <DropdownMenu
              title={sortDefs.find((f) => f.sortType === sortType)?.description ?? ''}
              items={sortDefs}
              onClick={(sortDef) => {
                setSortType?.(sortDef.sortType)
                setSortDirection(sortDef.defaultDirection ?? 'ascending')
              }}
            />
          )}
          <button
            onClick={() => setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')}
            className="inline-flex items-center ml-2 px-2 py-2 border border-transparent text-sm font-medium rounded-md text-slate-700 bg-slate-50 hover:bg-slate-200"
          >
            {sortDirection === 'ascending' ? (
              <SortDescendingIcon className="w-5" />
            ) : (
              <SortAscendingIcon className="w-5" />
            )}
          </button>
        </div>
        <div className="flex flex-row sm:space-x-2 text-slate-700">
          <button
            onClick={() => setViewType('list')}
            className={classNames(
              'p-2 rounded-md',
              viewType === 'list'
                ? 'bg-blue-300 hidden sm:block'
                : 'bg-gray-300 hover:bg-slate-700 hover:text-slate-100'
            )}
          >
            <ViewListIcon className="w-6" />
          </button>
          <button
            onClick={() => setViewType('grid')}
            className={classNames(
              'p-2 rounded-md',
              viewType === 'grid'
                ? 'bg-blue-300 hidden sm:block'
                : 'bg-gray-300 hover:bg-slate-700 hover:text-slate-100'
            )}
          >
            <ViewGridIcon className="w-6" />
          </button>
        </div>
      </div>
    </div>
  )
}
