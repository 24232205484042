import { classNames, S3ImageView } from '@life/components'
import { Book, Image } from '@life/frontend-model'
import { S3Image, StoryImage } from '@life/model'
import { Link } from 'react-router-dom'
import { ViewContent } from './ViewContent'

type Props = {
  book: Book
  image: StoryImage
  alignImageLeft: boolean
}

export function ViewImage({ book, image, alignImageLeft }: Props): JSX.Element {
  function rowImage(row: StoryImage): Image {
    const img = book.findImage(row.id)
    if (img) return img
    return new Image(book, {
      original: '',
      web: '',
      thumb: '',
    } as S3Image)
  }

  return (
    <div
      className={classNames(
        'pt-2 w-full',
        image.size === 'small' ? 'sm:w-1/2' : 'pb-4',
        image.size === 'small' && (alignImageLeft ? 'sm:float-left sm:pr-2 sm:mr-2' : 'sm:float-right sm:pl-2 sm:ml-2')
      )}
    >
      <Link to={'../photos/' + image.id}>
        <figure>
          <S3ImageView image={rowImage(image)} size="web" alt={image.caption} className="rounded-lg" />
          <figcaption className="my-2 text-base text-gray-500">{image.caption}</figcaption>
        </figure>
        <ViewContent book={book} content={image.children} />
      </Link>
    </div>
  )
}
