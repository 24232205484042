import { ONE_SECOND } from '@life/frontend-model'
import { Fragment, useEffect, useState } from 'react'
import { WithChildren } from './types'

type Props = WithChildren & {
  className?: string | undefined
  wait?: number
}
export function HideUntil({ wait = ONE_SECOND, className, children }: Props): JSX.Element {
  const [hidden, hide] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      hide(false)
    }, wait)
  }, [wait])

  if (hidden) return <Fragment />

  return <div className={className}>{children}</div>
}
