import { classNames } from '@life/components'
import { Book, Story } from '@life/frontend-model'
import { useNavigate } from 'react-router-dom'
import { ViewContent } from './ViewContent'

type Props = {
  book: Book
  story: Story
  enableLinks?: boolean
}

export function StoryReader({ book, story }: Props) {
  const navigate = useNavigate()
  return (
    <div
      className={'max-w-prose p-2 lg:p-10'}
      title="Double-click to Edit"
      onDoubleClick={() => navigate(story.link + '/edit')}
    >
      <ViewContent book={book} content={story.content} />
    </div>
  )
}

export function StoryPreview({ book, story, enableLinks = true }: Props): JSX.Element {
  const navigate = useNavigate()

  return (
    <div className="pl-5 pb-5 clear-both">
      <div
        onClick={() => enableLinks && navigate(story.link)}
        className={classNames('h-40 overflow-hidden relative', enableLinks && 'hover:bg-yellow-100 cursor-pointer')}
      >
        <div className="p-1">
          <p className="pb-4 leading-8 text-xl text-gray-700 font-bold font-sans">{story.title}</p>
          <ViewContent book={book} content={story.content.slice(0, 2)} />
          <div
            className={classNames('absolute w-full h-36 from-transparent to-white', 'bottom-0 h-36 bg-gradient-to-b')}
          ></div>
        </div>
      </div>
    </div>
  )
}
