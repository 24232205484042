import { classNames, DropDownIcon, LifeMenu, SpinnableIcon, StatusIcon } from '@life/components'
import { Translate } from '@life/frontend-model'
import { StoryStatus, StoryStatusType } from '@life/model'

type StatusMenuProps = {
  status: StoryStatusType
  onChange: (status: StoryStatusType) => void
  saving?: boolean
}

export function StatusMenu({ status, onChange, saving = false }: StatusMenuProps): JSX.Element {
  return (
    <LifeMenu>
      <LifeMenu.Button className="px-2 py-2 shadow-sm text-sm font-medium text-slate-700 flex space-x-1">
        <StatusIcon status={status} />
        <span>{Translate.StoryStatus(status)}</span>
        <SpinnableIcon icon={DropDownIcon} spinning={saving} className="-mr-1 h-5 w-5" />
      </LifeMenu.Button>
      <LifeMenu.Items>
        {StoryStatus.map((status) => (
          <LifeMenu.Item key={status}>
            {({ active }) => (
              <button
                type="button"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'flex w-full text-left px-4 py-2 space-x-2 text-sm whitespace-nowrap'
                )}
                onClick={() => onChange(status)}
              >
                <StatusIcon status={status} />
                <span>{Translate.StoryStatus(status)}</span>
              </button>
            )}
          </LifeMenu.Item>
        ))}
      </LifeMenu.Items>
    </LifeMenu>
  )
}
