export type ResponseOutput<T> = (ResponseSuccess | ResponseError<T>) & {
  apiVersion?: number
}

export type ResponseSuccess = {
  type: 'success'
}

export type ResponseError<T> = {
  type: 'error'
  error: T
  message?: string
}

export const isResponseSuccess = (r: unknown): r is ResponseSuccess =>
  typeof r === 'object' && (r as ResponseSuccess).type === 'success'
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Other types result in client-side errors or warnings
export const isResponseError = (r: unknown): r is ResponseError<any> =>
  typeof r === 'object' && (r as ResponseError<unknown>).type === 'error' && !!(r as ResponseError<unknown>).error

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Other types result in client-side errors or warnings
export const toResponseError = (error: unknown): ResponseError<any> => {
  if (isResponseError(error)) return error
  if (error instanceof Error) return { type: 'error', error: 'Server', message: error.message }
  if (typeof error === 'string') return { type: 'error', error: 'Server', message: error }
  return { type: 'error', error: 'Server', message: JSON.stringify(error) }
}

/**
 * Errors that can be returned on every request.
 * - Server is analogous to a 500 error
 * - InvalidRequest is analogous to a 400 error
 * - NotAuthorized is analogous to a 401 error
 */
export type GeneralErrors = ResponseError<'Server' | 'InvalidRequest' | 'NotAuthorized'>

/**
 * Errors that can be returned when get an item from a collection (such as a Book).
 * - NotFound if collection ID or item ID doesn't exist
 */
export type GetErrors = GeneralErrors | ResponseError<'NotFound'>

/**
 * Errors that can be returned when listing items in a collection (such as a Book).
 * - NotFound if collection ID doesn't exist
 */
export type ListErrors = GeneralErrors | ResponseError<'NotFound'>

/**
 * Errors that can be returned when adding an item to a collection (such as a Book).
 * - NotFound if collection ID doesn't exist
 * - NoAccess if user isn't an Editor on the collection
 */
export type AddErrors = GeneralErrors | ResponseError<'NotFound' | 'NoAccess'>

/**
 * Errors that can be returned when updating an item in a collection.
 * - NotFound if collection ID or item ID doesn't exist
 * - NoAccess if user isn't an Editor on the collection
 */
export type UpdateErrors = GeneralErrors | ResponseError<'NotFound' | 'NoAccess' | 'VersionMismatch'>

/**
 * Errors that can be returned when removing an item from a collection.
 * - NotFound if collection ID or item ID doesn't exist
 * - NoAccess if user isn't an Editor on the collection
 */
export type RemoveErrors = GeneralErrors | ResponseError<'NotFound' | 'NoAccess'>

/**
 * @deprecated
 */
export type ErrorMessage = {
  message: string
  code?: string
  debug?: string
}

// export type ErrorThrottle = ErrorGeneral<'Throttle'> & {
//   retrySeconds: number
// }
