import * as Model from '@life/model'
import { isDefined, Logger } from '@life/model'
import { Image } from './image'
import { Location } from './location'
import { Person } from './person'
import { Story } from './story'

const logger = new Logger('api-book')

export type TocSection = 'frontMatter' | 'body' | 'backMatter'

export class UnsavedBook {
  slug: string
  title: string
  subtitle?: string
  author?: string
  maxCollaborators?: number

  constructor(data: Model.UnsavedBook) {
    this.slug = data.slug
    this.title = data.title
    this.subtitle = data.subtitle
    this.author = data.author
    this.maxCollaborators = data.maxCollaborators
  }

  get key(): string {
    return this.title
  }

  toUnsavedModel(): Model.UnsavedBook {
    return {
      slug: this.slug,
      title: this.title,
      subtitle: this.subtitle,
      author: this.author,
      maxCollaborators: this.maxCollaborators,
    }
  }
}

export class BookInfo extends UnsavedBook {
  readonly bookId: Model.BookId
  readonly dbVersion: number | undefined

  constructor(data: Model.Book) {
    super(data)
    this.bookId = data.bookId
    this.dbVersion = data.dbVersion
    this.validateDbVersion()
  }

  override get key(): string {
    return this.bookId
  }

  toModel(): Model.Book {
    if (!this.bookId) throw new Error('Must be saved before calling toModel')
    return {
      ...this.toUnsavedModel(),
      bookId: this.bookId,
      dbVersion: this.dbVersion,
    }
  }

  private validateDbVersion(): void {
    const dbVersion = this.dbVersion
    if (dbVersion && dbVersion > Model.DB_VERSION) {
      logger.info(`DB_VERSION mismatch. Expected ${Model.DB_VERSION}, got ${dbVersion}`)
      throw new Error('Web client is out of date. Please refresh your browser to upgrade.')
    }
  }
}

export class BookView extends BookInfo {
  role?: Model.CollaboratorRole
  readonly lastAccessed: number

  constructor(data: Model.BookView) {
    super(data)
    this.role = data.role
    this.lastAccessed = data.lastAccessed || 0
  }
}

export class Book extends BookView {
  toc: TableOfContents
  readonly stories: Story[]
  readonly images: Image[]
  readonly people: Person[]
  readonly locations: Location[]

  constructor(data: Model.FullBook) {
    super({ ...data.info, ...data.collaborator })
    this.stories = data.content.stories.map((s) => new Story(this, s))
    this.images = data.content.images.map((i) => new Image(this, i))
    this.people = data.content.people.map((p) => new Person(this, p))
    this.locations = data.content.locations.map((i) => new Location(this, i))
    // Call only after this.stories is initialized
    this.toc = new TableOfContents(this, data.content.toc)
  }

  findStory(storyId: Model.StoryId | undefined): Story | undefined {
    return storyId ? this.stories.find((story) => story.storyId === storyId) : undefined
  }
  findStories(storyIds: Model.StoryId[]): Story[] {
    // Keep stories in same order as 'storyIds'
    return storyIds.map((id) => this.findStory(id)).filter(isDefined)
  }

  findPerson(personId: Model.PersonId | undefined): Person | undefined {
    return personId ? this.people.find((person) => person.personId === personId) : undefined
  }
  findPeople(personIds: Model.PersonId[]): Person[] {
    // Keep people in same order as 'personIds'
    return personIds.map((id) => this.findPerson(id)).filter(isDefined)
  }

  findLocation(locationId: Model.LocationId | undefined): Location | undefined {
    return locationId ? this.locations.find((location) => location.locationId === locationId) : undefined
  }
  findLocations(locationIds: Model.LocationId[]): Location[] {
    // Keep locations in same order as 'locationIds'
    return locationIds.map((id) => this.findLocation(id)).filter(isDefined)
  }

  findImage(imageId: Model.ImageId | undefined): Image | undefined {
    return imageId ? this.images.find((image) => image.imageId === imageId) : undefined
  }
  findImages(imageIds: Model.ImageId[]): Image[] {
    return this.images.filter((image) => imageIds.includes(image.imageId))
  }

  get link(): string {
    return '/book/' + (this.slug || this.bookId)
  }
}

export class TableOfContents {
  readonly book: Book
  beforeToc: Story[]
  afterToc: Story[]
  chapters: Story[]
  appendix: Story[]

  constructor(book: Book, data: Model.TableOfContents) {
    this.book = book
    this.beforeToc = book.findStories(data.beforeToc ?? [])
    this.afterToc = book.findStories(data.afterToc ?? [])
    this.chapters = book.findStories(data.chapters2 ?? [])
    this.appendix = book.findStories(data.appendix2 ?? [])
  }

  clone(): TableOfContents {
    return new TableOfContents(this.book, this.toModel())
  }

  get bookId(): Model.BookId {
    return this.book.bookId
  }

  get allChapters(): Story[] {
    return [...this.beforeToc, ...this.afterToc, ...this.chapters, ...this.appendix]
  }

  get frontMatter(): Story[] {
    return [...this.beforeToc, ...this.afterToc]
  }

  get body(): Story[] {
    return this.chapters
  }

  get backMatter(): Story[] {
    return this.appendix
  }

  get notInToc(): Story[] {
    return this.book.stories.filter((story) => {
      const top = story.getTopStory()
      return top === story && !this.isInToc(top)
    })
  }

  /**
   * Is a story in the table of contents?
   */
  public isInToc(story: Story | undefined): boolean {
    if (!story) return false
    const chapter = story.getTopStory()
    return !!this.allChapters.find((c) => chapter.storyId === c.storyId)
  }

  findStorySection(storyId: Model.StoryId): TocSection | undefined {
    if (this.frontMatter.some((s) => s.containsStory(storyId))) return 'frontMatter'
    if (this.body.some((s) => s.containsStory(storyId))) return 'body'
    if (this.backMatter.some((s) => s.containsStory(storyId))) return 'backMatter'
    return undefined
  }

  toModel(): Model.TableOfContents {
    return {
      beforeToc: this.beforeToc.map((s) => s.storyId),
      afterToc: this.afterToc.map((s) => s.storyId),
      chapters2: this.chapters.map((s) => s.storyId),
      appendix2: this.appendix.map((s) => s.storyId),
    }
  }
}
