import { useAuth0 } from '@auth0/auth0-react'
import { ButtonAdd, NotReady, PageHeading, PagePadding, useRequiredParam } from '@life/components'
import { useBookInfo } from '@life/frontend-model'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function ViewInvite(): JSX.Element {
  const auth = useAuth0()
  const [bookSlug, inviteId] = useRequiredParam(['bookSlug', 'inviteId'])
  const { book, isLoading, error } = useBookInfo(bookSlug)
  const navigate = useNavigate()
  const navToAcceptInvite = useCallback((): void => {
    navigate(`/accept/${bookSlug}/${inviteId}`)
  }, [bookSlug, inviteId, navigate])
  useEffect(() => {
    if (auth?.isAuthenticated) navToAcceptInvite()
  }, [auth, navToAcceptInvite])

  if (isLoading || error || !book)
    return <NotReady type="Invite" id={`${bookSlug}/${inviteId}`} isLoading={isLoading} error={error} />

  return (
    <PagePadding className="flex flex-col items-center gap-4">
      <PageHeading title="Invite" back={false} className="pt-20">
        Accept Invitation
      </PageHeading>
      <p>You have been invited to view the Unforgotten Life book &ldquo;{book.title}&rdquo;.</p>
      <p>To accept the invitation and view the book you must sign in or sign up.</p>
      <div className="flex flex-row gap-8">
        <ButtonAdd onClick={navToAcceptInvite}>Sign In or Sign Up</ButtonAdd>
      </div>
    </PagePadding>
  )
}
