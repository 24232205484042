import { BookIcon, useOptionalParam } from '@life/components'
import { Link } from 'react-router-dom'

type Props = {
  close: VoidFunction
}

export function Logo({ close }: Props): JSX.Element {
  const bookSlug = useOptionalParam('bookSlug')
  const home = bookSlug ? '/book/' + bookSlug : '/'
  return (
    <div className="flex items-start xl:w-64">
      <div className="flex-shrink-0 flex items-center">
        <Link to={home} className="text-slate-50 hover:text-slate-200" onClick={close}>
          <BookIcon className="h-10 w-auto" aria-label="Unforgotten Logo" />
        </Link>
      </div>
    </div>
  )
}
