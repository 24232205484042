import { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { TitleDialog } from '../TitleDialog'
import { StoryFormFields } from './EditStory'

type TitleProps = {
  form: UseFormReturn<StoryFormFields>
  title: string
}
export function Title({ form, title }: TitleProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Controller
      render={({ field }) => (
        <span onClick={() => setIsOpen(true)} className="py-1 px-2 rounded-md hover:ring-2 cursor-pointer">
          {title}
          <TitleDialog
            key={String(isOpen)}
            isOpen={isOpen}
            title={title}
            onSave={(title) => {
              field.onChange(title)
              setIsOpen(false)
            }}
            onClose={() => setIsOpen(false)}
          />
        </span>
      )}
      control={form.control}
      name="title"
    />
  )
}
