import { ActionMenuIcon, classNames, LifeMenu, useErrorNotification } from '@life/components'
import { Book, docxContentType } from '@life/frontend-model'
import { ChangeEvent, useRef, useState } from 'react'
import { WordDocImporter } from './DocxImporter'

type Props = {
  book: Book
}
export function ImportMenu({ book }: Props): JSX.Element {
  const [file, setFile] = useState<File>()
  const inputFile = useRef<HTMLInputElement>(null)
  const { showError } = useErrorNotification()

  function selectWordFile(): void {
    inputFile.current?.click()
  }
  async function fileSelected(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    event.stopPropagation()
    event.preventDefault()
    const file = event.target.files?.[0]
    if (file?.size && file?.size > 1024 * 1024 * 1024) {
      showError('Unable to import file', 'File is too large. Please break it into smaller files less than 1GB in size.')
      setFile(undefined)
    } else {
      setFile(file)
    }
  }

  return (
    <>
      <LifeMenu>
        <LifeMenu.Button className="p-1 rounded-full text-white">
          <ActionMenuIcon className="h-5 w-5 text-black hover:text-gray-400" aria-hidden="true" />
        </LifeMenu.Button>
        <LifeMenu.Items>
          <LifeMenu.Item>
            {({ active }) => (
              <div className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer')}>
                <button type="button" onClick={selectWordFile}>
                  <div className="whitespace-nowrap">Create from Word Doc</div>
                </button>
              </div>
            )}
          </LifeMenu.Item>
        </LifeMenu.Items>
      </LifeMenu>
      <input
        key={'input' + String(!!file)} // Force component to reload when file is selected.
        type="file"
        accept={'.docx,' + docxContentType}
        ref={inputFile}
        onChange={fileSelected}
        style={{ display: 'none' }}
      />
      <WordDocImporter
        key={'import' + String(!!file)} // Force component to reload when file is selected.
        isOpen={!!file}
        book={book}
        file={file}
        onClose={() => setFile(undefined)}
      />
    </>
  )
}
