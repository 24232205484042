import { FieldError } from 'react-hook-form'
import { WithChildren } from '../types'

type Props = WithChildren & {
  error?: FieldError | undefined
}

export function HelpText({ children, error }: Props) {
  if (error) return <p className="mt-1 pl-3 text-sm text-red-500">{error.message}</p>
  return <p className="mt-1 pl-4 text-sm text-gray-500 italic">{children}</p>
}
