import { NotReady, PageHeading, PagePadding, S3ImageView, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { StoryList } from '../../story'
import { ImageMenu } from '../ImageMenu'
import { ImageDate } from './ImageDate'
import { ImageNotes } from './ImageNotes'
import { PeopleInImage } from './PeopleInImage'

export function ImagePage(): JSX.Element {
  const [bookSlug, imageId] = useRequiredParam(['bookSlug', 'imageId'])
  const { book, isLoading, error } = useBook(bookSlug)
  // End of Hooks

  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  const image = book.findImage(imageId)
  if (!image) return <NotReady type="Image" id={imageId} notFound />
  const stories = image.storiesWithImage

  return (
    <PagePadding wide>
      <PageHeading title="Photo" right={<ImageMenu image={image} />}>
        Photo Preview
      </PageHeading>
      <div className="flex flex-col lg:flex-row w-full space-x-5 mt-1">
        <div className="w-full lg:w-2/3">
          <S3ImageView image={image} size="web" className="flex-1 max-h-[50rem]" />
        </div>
        <div className="lg:w-1/3">
          <ImageDate image={image} />
          <PeopleInImage image={image} />
          <div className="py-4">
            <h3 className="text-lg font-bold">Stories with Photo</h3>
            {stories.length > 0 ? (
              <StoryList book={book} stories={stories} />
            ) : (
              <div className="italic text-gray-500 text-sm pl-2">
                This photo has not been included in any story yet. Use the Story Editor to include this photo.
              </div>
            )}
          </div>
          <ImageNotes image={image} />
        </div>
      </div>
    </PagePadding>
  )
}
