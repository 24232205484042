import { ButtonAdd, NotReady, PageHeading, PagePadding, useQueryParam, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { canEditBook } from '../common'
import { ImageList } from './list'

export function ImagesController(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const filter = useQueryParam('filter')
  const { book, isLoading, error } = useBook(bookSlug)
  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />

  if (book.images.length === 0) {
    return (
      <div className="m-5 p-5 rounded-xl border-2 border-dashed flex flex-row items-center space-x-3">
        <ButtonAdd to={'../photos/_add'} canEdit={canEditBook(book)}>
          Add Photo
        </ButtonAdd>
        <p>There are no photos yet.</p>
      </div>
    )
  }

  return (
    <PagePadding wide>
      <div className="w-full flex flex-col space-y-3">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <PageHeading title="Photos" className="flex-1">
            Photos
          </PageHeading>
          <div className="flex justify-between space-x-2">
            <ButtonAdd to="_add" canEdit={canEditBook(book)}>
              Add Photos
            </ButtonAdd>
          </div>
        </div>
        <ImageList book={book} images={book.images} initialFilter={filter} />
      </div>
    </PagePadding>
  )
}
