import { ButtonAdd, classNames, dateToString, Tooltip } from '@life/components'
import { Story } from '@life/frontend-model'
import { isEmpty } from '@life/model'

type NewStoriesProp = {
  stories: Story[]
  selectedStory: Story | undefined
  selectStory: (story: Story | undefined) => void
}
export function NewStoriesList({ stories, selectedStory, selectStory }: NewStoriesProp): JSX.Element {
  return (
    <div className={classNames('flex-1', selectedStory ? 'hidden sm:block' : '')}>
      <div className="text-right">
        <ButtonAdd to="../stories/_add">Add Story</ButtonAdd>
      </div>
      <div className="text-xl">Select Stories to Add to Your Book</div>
      <ul className="pl-2 py-3">
        {isEmpty(stories) && (
          <div className="text-sm text-gray-600">Great! All your stories have been added to the book</div>
        )}
        {stories.map((story) => {
          const selected = selectedStory?.storyId === story.storyId
          return (
            <Tooltip
              key={story.key}
              message={selected ? 'Now insert into the outline or click here to unselect' : 'Click here to select'}
            >
              <li
                onClick={() => {
                  selectStory(selected ? undefined : story)
                }}
                className={classNames(
                  'w-full flex flex-row p-2 justify-between items-center space-x-1 cursor-pointer select-none',
                  selected ? 'bg-gray-300' : 'bg-white hover:bg-slate-100'
                )}
              >
                <div className="text-gray-800 text-left">{story.title}</div>
                <div className="text-xs text-gray-500 text-right">{dateToString(story.occurred)}</div>
              </li>
            </Tooltip>
          )
        })}
      </ul>
    </div>
  )
}
