import { ItemList, ItemListMode, SortDef } from '@life/components'
import { Book, Person } from '@life/frontend-model'
import { useState } from 'react'
import { useItemNavigation } from '../../common'
import { searchPeople } from '../../search'
import { PersonSortType, sortPeople } from '../util'
import { GridPerson, ListPerson } from './PersonCard'

const sortDefs: SortDef<PersonSortType>[] = [
  { sortType: 'mentions', description: 'Mentions', defaultDirection: 'descending' },
  { sortType: 'lastName', description: 'Last Name' },
  { sortType: 'givenNames', description: 'First Name' },
  { sortType: 'firstName', description: 'Nickname' },
  { sortType: 'birth', description: 'Birth' },
  { sortType: 'death', description: 'Death', defaultDirection: 'descending' },
  { sortType: 'in-use', description: 'Used in Book' },
  { sortType: 'no-images', description: 'No. Photos' },
]

type Props = {
  mode?: ItemListMode
  book: Book
  people: readonly Person[]
  initialFilter?: string
  onSelect?: (person: Person) => void
  isSelected?: (item: Person) => boolean
  onChoose?: (person: Person) => void
  noHeader?: boolean
}
export function PersonList({
  mode,
  book,
  people,
  initialFilter,
  onSelect,
  isSelected,
  onChoose,
  noHeader,
}: Props): JSX.Element {
  const { navToPerson } = useItemNavigation(book)
  const [lastNameFirst, setLastNameFirst] = useState(false)

  function filterSort(people: readonly Person[], sortType: PersonSortType | undefined, filter?: string): Person[] {
    const newPeople = filter ? searchPeople(people, filter) : [...people]
    if (sortType) sortPeople(newPeople, sortType)
    setLastNameFirst(sortType === 'lastName')
    return newPeople
  }

  return (
    <ItemList
      mode={mode}
      items={people}
      prefsKey="personList"
      onChoose={onChoose}
      onSelect={onSelect}
      isSelected={isSelected}
      onNavigate={navToPerson}
      sortDefs={sortDefs}
      doFilterSort={filterSort}
      initialFilter={initialFilter}
      noHeader={noHeader}
      searchHelp={<PersonSearchHelp />}
    >
      {(viewType, person) => (
        <>
          {viewType === 'grid' && <GridPerson person={person} lastNameFirst={lastNameFirst} />}
          {viewType === 'list' && <ListPerson person={person} lastNameFirst={lastNameFirst} />}
        </>
      )}
    </ItemList>
  )
}

function PersonSearchHelp(): JSX.Element {
  return (
    <>
      <p>Search people by names or years.</p>
      <p className="py-2">Advanced search:</p>
      <ul className="pl-8 list-disc">
        <li>&lsquo;photos:&rsquo; searches number of photos with person; e.g., photos:0</li>
        <li>&lsquo;stories:&rsquo; searches number of stories mentioning person; e.g., stories:0</li>
      </ul>
    </>
  )
}
