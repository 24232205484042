import { dateToString } from '@life/components'
import { StoryDate } from '@life/model'
import { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { OccurredDialog } from '../OccurredDialog'
import { StoryFormFields } from './EditStory'

type OccurredProps = {
  form: UseFormReturn<StoryFormFields>
  occurred: StoryDate | undefined
}
export function Occurred({ form, occurred }: OccurredProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Controller
      render={({ field }) => (
        <span
          onClick={() => setIsOpen(true)}
          className="flex space-x-2 items-center text-md text-slate-700 p-1 rounded-md hover:ring-2 cursor-pointer"
        >
          <span className="font-bold pr-1">Occurred:</span>
          {dateToString(occurred) || <span className="italic">No Specific Date</span>}
          <OccurredDialog
            key={String(isOpen)}
            isOpen={isOpen}
            occurred={occurred}
            onSave={(occurred) => {
              field.onChange(occurred)
              setIsOpen(false)
            }}
            onClose={() => setIsOpen(false)}
          />
        </span>
      )}
      control={form.control}
      name="occurred"
    />
  )
}
