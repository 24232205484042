import {
  AnnotationIcon,
  BackspaceIcon,
  EnlargeIcon,
  IndentIcon,
  LinkIcon,
  MoveDownIcon,
  MoveUpIcon,
  PhotoIcon,
  ReplyIcon,
  SubstoryIcon,
  TaskIcon,
} from '../icons'
import { WithChildren } from '../types'
import { ButtonProp, FormatButton } from './button'

export type ToolbarButtons = {
  block: string | undefined
  moveUp: ButtonProp
  moveDown: ButtonProp
  bold: ButtonProp
  italic: ButtonProp
  underline: ButtonProp
  indent: ButtonProp
  undo: ButtonProp
  redo: ButtonProp
  erase: ButtonProp
  imageEdit: ButtonProp
  imageInsert: ButtonProp
  sizeImage: ButtonProp
  comment: ButtonProp
  substory: ButtonProp
  task: ButtonProp
  tag: ButtonProp
}
type ToolbarProps = {
  buttons: ToolbarButtons
}

export function Toolbar({ buttons }: ToolbarProps): JSX.Element {
  return (
    <div className="border-t-2 p-1 px-2 h-10">
      <div className="flex justify-between max-w-3xl">
        <div id="story-editor-bar-left" className="flex divide-x divide-gray-300">
          <ButtonGroup>
            <FormatButton {...buttons.undo} tooltip="Undo">
              <ReplyIcon className="w-5" />
            </FormatButton>
            <FormatButton {...buttons.redo} tooltip="Redo">
              <ReplyIcon className="w-5 -scale-x-100" />
            </FormatButton>
            <FormatButton {...buttons.moveUp} tooltip="Move up">
              <MoveUpIcon className="w-5" />
            </FormatButton>
            <FormatButton {...buttons.moveDown} tooltip="Move down">
              <MoveDownIcon className="w-5" />
            </FormatButton>
          </ButtonGroup>
          <ButtonGroup>
            <div className="text-sm text-gray-700">{buttons.block}</div>
            <FormatButton {...buttons.erase}>
              <BackspaceIcon className="w-5" />
            </FormatButton>
          </ButtonGroup>
          <ButtonGroup>
            <FormatButton {...buttons.imageEdit}>
              <PhotoIcon className="w-5" />
            </FormatButton>
            <FormatButton {...buttons.sizeImage}>
              <EnlargeIcon className="w-5" />
            </FormatButton>
            <FormatButton {...buttons.indent}>
              <IndentIcon className="w-5 -scale-x-100" />
            </FormatButton>
            <FormatButton {...buttons.bold} tooltip="Bold">
              <span className="font-semibold">B</span>
            </FormatButton>
            <FormatButton {...buttons.italic} tooltip="Italics">
              <span className="font-mono font-semibold italic pt-[2px]">I</span>
            </FormatButton>
            <FormatButton {...buttons.underline} tooltip="Underline">
              <span className="underline font-semibold">U</span>
            </FormatButton>
            <FormatButton {...buttons.tag}>
              <LinkIcon className="w-5" />
            </FormatButton>
            <FormatButton {...buttons.comment}>
              <AnnotationIcon className="w-5" />
            </FormatButton>
          </ButtonGroup>
        </div>
        <div id="story-editor-bar-right" className="flex divide-x divide-gray-300">
          <ButtonGroup>
            <div className="text-sm text-gray-700">Insert</div>
            <FormatButton {...buttons.substory}>
              <SubstoryIcon className="w-5" />
            </FormatButton>
            <FormatButton {...buttons.imageInsert}>
              <PhotoIcon className="w-5" />
            </FormatButton>
            <FormatButton {...buttons.task}>
              <TaskIcon className="w-5" />
            </FormatButton>
          </ButtonGroup>
        </div>
      </div>
    </div>
  )
}

export function ButtonGroup({ children }: WithChildren): JSX.Element {
  return <div className="flex space-x-1 items-center px-2">{children}</div>
}

function getModifierKey(): string {
  return isMac() ? 'Ctrl+' : '⌘'
}

function isMac(): boolean {
  return true
}
