import { ButtonCancel, ButtonSave, classNames, HelpText, LifeDialog, useErrorNotification } from '@life/components'
import { Image, useUpdateImage } from '@life/frontend-model'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { canEditBook } from '../../common'

type Props = {
  image: Image
}
export function ImageNotes({ image }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const updater = useUpdateImage()
  const { showError } = useErrorNotification()
  const canEdit = canEditBook(image.book)

  async function save(image: Image): Promise<void> {
    try {
      await updater.update(image)
    } catch (error) {
      showError('Error Updating Photo', error)
    }
    setIsOpen(false)
  }

  return (
    <>
      <div
        className={classNames(
          'border-2 rounded-lg  p-2 flex-1 cursor-pointer',
          image.notes ? '' : 'text-gray-500 italic'
        )}
        onClick={canEdit ? () => setIsOpen(true) : () => undefined}
      >
        {image.notes || 'Optional Notes'}
      </div>
      <MetadataDialog
        key={String(isOpen)}
        isOpen={isOpen}
        image={image}
        saving={updater.isLoading}
        onSave={save}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

type MetadataProps = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  image: Image
  isOpen: boolean
  saving: boolean
  onSave: (image: Image) => void
  onClose: () => void
}
function MetadataDialog({ image, isOpen, saving, onSave, onClose }: MetadataProps): JSX.Element {
  type FormFields = { notes: string }
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormFields>({
    defaultValues: { notes: image.notes },
  })

  function handleSave(fields: FormFields): void {
    image.notes = fields.notes
    onSave(image)
  }

  const shortcuts = { Escape: onClose, Enter: () => handleSubmit(handleSave)() }
  return (
    <LifeDialog modal title="Photo Notes" isOpen={isOpen} onClose={onClose} shortcuts={shortcuts}>
      <form onSubmit={handleSubmit(handleSave)}>
        <LifeDialog.Content className="w-full sm:w-96">
          <textarea
            className="focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 w-full h-24 rounded-md"
            {...register('notes')}
          />
          <HelpText>Photo notes help you organize your photos. They are not displayed in the book.</HelpText>
        </LifeDialog.Content>
        <LifeDialog.Actions>
          <ButtonCancel onClick={onClose} />
          <ButtonSave disabled={!isDirty} clicked={saving} />
        </LifeDialog.Actions>
      </form>
    </LifeDialog>
  )
}
