import {
  ButtonCancel,
  ButtonEdit,
  ButtonRemove,
  ButtonSave,
  classNames,
  CopyIcon,
  DeleteIcon,
  DropDownIcon,
  LifeDialog,
  LifeMenu,
  useErrorNotification,
} from '@life/components'
import { Book, Invite, UnsavedInvite, useCreateInvite, useInvites, useRemoveInvite } from '@life/frontend-model'
import { useState } from 'react'

type Props = {
  book: Book
  className?: string
}
export function Invites({ book, className }: Props): JSX.Element {
  const { invites, error } = useInvites(book)
  const { create, isCreating } = useCreateInvite()
  const [removingInvite, setRemovingInvite] = useState<Invite>()
  const { showError } = useErrorNotification()
  const [expiration, setExpiration] = useState(30)
  const [copiedInvite, setCopiedInvite] = useState<Invite>()

  async function createInviteLink(): Promise<void> {
    const invite = new UnsavedInvite(book, { bookId: book.bookId, expires: 0 })
    invite.expiresIn = expiration
    try {
      await create(new UnsavedInvite(book, invite))
    } catch (error) {
      showError('Error getting a link', error)
    }
  }
  function copyUrl(invite: Invite): void {
    navigator.clipboard.writeText(invite.url)
    setCopiedInvite(invite)
    setTimeout(() => setCopiedInvite(undefined), 2500)
  }
  function toDays(expires: number): number {
    return Math.round((expires - Date.now()) / (24 * 60 * 60 * 1000))
  }

  invites?.sort((a, b) => b.expires - a.expires)
  return (
    <div className={className}>
      {!book.slug && (
        <div>
          <span className="pr-4">
            The book does not have a Website Url. Do you wish to add one before creating an invite?
          </span>
          <ButtonEdit to={`${book.link}/settings`}>Edit Book</ButtonEdit>
        </div>
      )}
      <div className="flex flex-row items-center space-x-8">
        <div className="-mr-6">Expiration:</div>
        <ExpirationMenu value={expiration} onChange={(exp) => setExpiration(exp)} />
        <ButtonSave clicked={isCreating} onClick={createInviteLink}>
          Get Link
        </ButtonSave>
      </div>
      {invites && invites.length > 0 && (
        <>
          <p className="py-1">
            Copy a link below, send it to your new collaborators (for example, email or text message).
          </p>
          {invites?.map((inv) => (
            <div key={inv.inviteId}>
              <div className="pl-6 flex flex-row max-w-fit items-center space-x-3">
                <div onClick={() => copyUrl(inv)} className="hover:underline text-cyan-600 cursor-pointer">
                  {inv.url}
                </div>
                {!copiedInvite && (
                  <div
                    title="Copy to Clipboard"
                    onClick={() => copyUrl(inv)}
                    className="p-1 hover:bg-slate-200 rounded-lg text-slate-700 cursor-pointer"
                  >
                    <CopyIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                )}
                {copiedInvite?.inviteId === inv.inviteId && (
                  <div className="px-1 rounded-md text-slate-700 bg-slate-200">Copied!</div>
                )}
                <div
                  title="Delete this invite"
                  onClick={() => setRemovingInvite(inv)}
                  className="p-1 hover:bg-slate-200 rounded-lg text-slate-700 cursor-pointer"
                >
                  <DeleteIcon className="h-5 w-5" aria-hidden="true" />
                </div>
              </div>
              <div className="pl-8 text-sm text-slate-500">(Expires in {toDays(inv.expires)} days)</div>
            </div>
          ))}
        </>
      )}
      {error && <div>Error loading links. Error: {error.message}</div>}
      {removingInvite && (
        <ConfirmDialog invite={removingInvite} isOpen={!!removingInvite} onClose={() => setRemovingInvite(undefined)} />
      )}
    </div>
  )
}

type ExpirationMenuProps = {
  value: number
  onChange: (value: number) => void
}
export function ExpirationMenu({ value, onChange }: ExpirationMenuProps): JSX.Element {
  return (
    <LifeMenu>
      <LifeMenu.Button className="px-2 py-2 shadow-sm text-sm font-medium border-slate-800 text-slate-700 flex space-x-1">
        {value} days
        <DropDownIcon className="-mr-1 h-5 w-5" />
      </LifeMenu.Button>
      <LifeMenu.Items>
        {[1, 2, 5, 10, 30, 60, 90].map((exp) => (
          <LifeMenu.Item key={exp}>
            {({ active }) => (
              <button
                type="button"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'flex w-full text-left px-4 py-2 space-x-2 text-sm whitespace-nowrap'
                )}
                onClick={() => onChange(exp)}
              >
                {exp} days
              </button>
            )}
          </LifeMenu.Item>
        ))}
      </LifeMenu.Items>
    </LifeMenu>
  )
}

type ConfirmProps = {
  invite: Invite
  isOpen: boolean
  onClose: VoidFunction
}
function ConfirmDialog({ isOpen, invite, onClose }: ConfirmProps): JSX.Element {
  const { remove, isRemoving } = useRemoveInvite()
  const { showError } = useErrorNotification()

  async function removeInvite(): Promise<void> {
    try {
      await remove(invite)
      onClose()
    } catch (error) {
      showError('Error removing link', error)
    }
  }

  const shortcuts = { Escape: onClose }
  return (
    <LifeDialog modal title="Confirmation" isOpen={isOpen} shortcuts={shortcuts} onClose={onClose}>
      <LifeDialog.Content>Are you sure you want to remove this invite link?</LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose} />
        <ButtonRemove confirm={false} clicked={isRemoving} onClick={removeInvite} />
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
