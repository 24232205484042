import { ButtonBase, ButtonSave, DownloadIcon, NotReady, Tooltip } from '@life/components'
import { Book, createPdfBookInS3, PdfInfo, useGetPdfInfo } from '@life/frontend-model'
import { Logger } from '@life/model'
import { useEffect, useState } from 'react'

const logger = new Logger('pdf-viewer')

type Props = {
  book: Book
}
export function PdfViewer({ book }: Props): JSX.Element {
  const { error, pdfInfo: initialPdfInfo, isLoading, refetch } = useGetPdfInfo(book)
  const [pdfInfo, setPdfInfo] = useState<PdfInfo>()
  const [state, setState] = useState<'noPdf' | 'ready' | 'pending' | 'error'>()
  useEffect(() => {
    setPdfInfo(initialPdfInfo)
  }, [initialPdfInfo])
  useEffect(() => {
    if (!pdfInfo) setState('noPdf')
    else if (pdfInfo.pendingVersion) setState('pending')
    else if (pdfInfo.createError) setState('error')
    else if (pdfInfo.url) setState('ready')
    else setState(undefined)
  }, [pdfInfo])

  if (isLoading || (error && error.error !== 'NotFound')) {
    return <NotReady type="PdfInfo" id="" isLoading={isLoading} error={error} />
  }

  async function createPdf(): Promise<void> {
    setState('pending')
    try {
      const result = await createPdfBookInS3(book)
      setPdfInfo(result)
    } catch (error) {
      // ignore since the only error we expect is a timeout
      logger.error('PdfViewer.createPdf', error)
    }
  }

  return (
    <div className="flex flex-col space-y-3 p-11">
      <div>
        <ButtonSave type="button" clicked={state === 'pending'} onClick={createPdf}>
          {state === 'pending' ? 'Generating' : 'Generate PDF'}
        </ButtonSave>
      </div>
      {state === 'pending' && (
        <div className="pt-8">
          The PDF is being generated. It may take several minutes. You can continue working and check back here later.
          <ButtonBase
            type="button"
            onClick={refetch}
            className="mt-4 bg-slate-600 hover:bg-slate-700 focus:ring-slate-500"
          >
            Check Now
          </ButtonBase>
        </div>
      )}
      {state === 'error' && (
        <div>
          An error occurred while generating the PDF:
          <div className="text-red-700">{pdfInfo?.createError}</div>
        </div>
      )}
      {state === 'ready' && (
        <Tooltip message="Opens in new window" className="w-fit">
          <a
            href={pdfInfo?.url}
            target="_blank"
            rel="noreferrer"
            className="rounded-md px-3 py-2 inline-flex items-center justify-center text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <DownloadIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            <div className="whitespace-nowrap">Download PDF</div>
          </a>
        </Tooltip>
      )}
    </div>
  )
}
