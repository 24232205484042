import { API } from '@life/model'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { User } from './user'

export type UserState = {
  isLoading: boolean
  error?: API.GetErrors
  user?: User
}
/** Gets the current User. */
export function useUser(): UserState {
  const query = useQuery<User, API.GetErrors>('user', () => readUser(), {
    refetchOnWindowFocus: false,
  })
  return { ...query, error: query.error ?? undefined, user: query.data }
}

export type AddUserState = {
  isLoading: boolean
  error?: API.AddErrors
  user?: User
}
/** Gets the current User and also Adds the User if not registered. */
export function useAddUser(): AddUserState {
  const query = useQuery<User, API.AddErrors>('user', () => addUser(), { retry: 1 })
  return { ...query, error: query.error ?? undefined, user: query.data }
}

export type UpdateUserState = {
  isLoading: boolean
  update: (user: User) => Promise<void>
}
export function useUpdateUser(): UpdateUserState {
  const queryClient = useQueryClient()
  const mutation = useMutation((user: User) => updateUser(user))
  async function update(user: User): Promise<void> {
    try {
      await mutation.mutateAsync(user)
      queryClient.invalidateQueries('user')
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update }
}

export type RemoveUserState = {
  isLoading: boolean
  remove: (user: User) => Promise<void>
}
export function useRemoveUser(): RemoveUserState {
  const queryClient = useQueryClient()
  const mutation = useMutation((user: User) => removeUser(user))
  async function remove(user: User): Promise<void> {
    try {
      await mutation.mutateAsync(user)
      queryClient.invalidateQueries('user')
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, remove }
}

async function readUser(user?: User): Promise<User> {
  const response = await serverRequest<API.UserGetInput, API.UserGetSuccess>('/user/get', { userId: user?.userId })
  return new User(response.user)
}

async function addUser(): Promise<User> {
  const response = await serverRequest<API.UserAddInput, API.UserAddSuccess>('/user/add')
  return new User(response.user)
}

async function updateUser(user: User): Promise<void> {
  await serverRequest<API.UserUpdateInput, API.UserUpdateSuccess>('/user/update', { user: user.toModel() })
}

async function removeUser({ userId }: User): Promise<void> {
  await serverRequest<API.UserRemoveInput, API.UserRemoveSuccess>('/user/remove', { userId })
}
