import { ButtonCancel, ButtonSave, classNames, DatesForm, HelpText, useErrorNotification } from '@life/components'
import { Book, Story, UnsavedStory, useAddStory } from '@life/frontend-model'
import { StoryContent, StoryDate } from '@life/model'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { canEditBook } from '../common'
import { ViewContent } from './reader'

export type StoryFormProps = {
  onAdd: (story: Story) => void
  onClose?: () => void
  book: Book
  content?: StoryContent[]
}

export function AddStoryForm({ onAdd, onClose, book, content }: StoryFormProps) {
  type FormFields = { title: string; occurred?: StoryDate }
  const [occurredError, setOccurredError] = useState<string>()
  const adder = useAddStory()
  const { showError } = useErrorNotification()
  const form = useForm<FormFields>({
    defaultValues: { title: '' },
  })
  // End of hooks

  function handleSubmitWithoutPropagation(e: React.FormEvent): void {
    e.preventDefault()
    e.stopPropagation()
    form.handleSubmit(add)(e)
  }

  async function add(fields: FormFields): Promise<void> {
    if (!book) return // should never happen
    try {
      const story = new UnsavedStory(book, { ...fields, bookId: book.bookId, status: 'stub', content: content || [] })
      const saved = await adder.add(story)
      onAdd(saved)
    } catch (error) {
      showError('Error Adding Story', error)
    }
  }

  const {
    register,
    control,
    formState: { errors, isDirty },
  } = form

  return (
    <div className="max-w-prose w-full sm:w-96">
      <form key="add-story" onSubmit={handleSubmitWithoutPropagation} className="pt-8 px-1">
        <div className="mt-1 mb-4 relative rounded-md shadow-sm\">
          <input
            type="text"
            autoFocus
            className={classNames(
              errors.title
                ? 'ring-red-400 border-red-400'
                : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
              'block w-full sm:text-sm rounded-md'
            )}
            placeholder="Title"
            {...register('title', {
              required: {
                value: true,
                message: 'Title is required',
              },
            })}
          />
        </div>
        <div className="">
          <label htmlFor="occurred" className="block text-sm font-medium text-gray-700">
            Story Date
          </label>
          <Controller
            render={({ field }) => (
              <DatesForm
                types={['single', 'range']}
                optional
                defaultValue={field.value}
                onChange={(value, error) => {
                  field.onChange(value)
                  setOccurredError(error)
                }}
              />
            )}
            control={control}
            name="occurred"
            defaultValue={undefined}
          />
          <HelpText error={occurredError ? { type: 'value', message: occurredError } : undefined}>
            When did this story occur?
          </HelpText>
        </div>
        {content && (
          <div className="mt-5">
            <label htmlFor="occurred" className="block text-sm font-medium text-gray-700">
              Story Content (preview)
            </label>
            <div className="p-2 h-40 overflow-y-auto relative border rounded-md bg-gray-50">
              <ViewContent book={book} content={content} />
            </div>
            <HelpText error={occurredError ? { type: 'value', message: occurredError } : undefined}>
              You can edit this text later.
            </HelpText>
          </div>
        )}
        <div className="mt-8 flex justify-between p-1">
          {onClose && <ButtonCancel onClick={onClose}></ButtonCancel>}
          <ButtonSave disabled={!isDirty || !!occurredError} clicked={adder.isAdding} canEdit={canEditBook(book)}>
            Add Story
          </ButtonSave>
        </div>
      </form>
    </div>
  )
}
