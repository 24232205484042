import { Person, Image, Story } from '@life/frontend-model'
import { ReactNode, useEffect, useState } from 'react'
import { ButtonCancel } from '../buttons'
import { classNames } from '../classNames'
import { LifeDialog } from '../LifeDialog'
import { usePreference } from '../Preferences'
import { Header } from './Header'
import { SelectOverlay } from './SelectOverlay'

export type SortDirection = 'ascending' | 'descending'
export type SortDef<S extends string> = {
  sortType: S // Meaning defined by caller
  description: string
  defaultDirection?: SortDirection // Defaults to ascending
}
export type ItemType = Story | Image | Person
export type ViewType = 'grid' | 'list'
export type ItemListMode = 'view' | 'choose' | 'select'

type Props<T extends ItemType, S extends string> = {
  mode?: ItemListMode // default 'view'
  items: readonly T[]
  prefsKey?: 'storyList' | 'imageList' | 'personList'
  sortDefs?: SortDef<S>[]
  noHeader?: boolean
  searchHelp?: ReactNode
  initialFilter?: string
  onSelect?: (item: T) => void
  isSelected?: (item: T) => boolean
  onChoose?: (item: T) => void
  onNavigate?: (item: T) => void
  /** Filters and sorts items. `filter` will be lowercase. Must return a new list if it has changed! */
  doFilterSort?: (items: readonly T[], sortType: S | undefined, filter?: string) => T[]
  children: (viewType: ViewType, item: T) => ReactNode
}
export function ItemList<T extends ItemType, S extends string>({
  mode = 'view',
  items = [],
  prefsKey,
  sortDefs = [],
  noHeader = false,
  searchHelp,
  doFilterSort,
  ...props
}: Props<T, S>): JSX.Element {
  const defaultPrefs = {
    viewType: 'grid' as ViewType,
    sortType: sortDefs?.[0]?.sortType,
    direction: sortDefs?.[0].defaultDirection ?? 'ascending',
  }
  const [filter, setFilter] = useState(props.initialFilter ?? '')
  const [prefs, setPrefs] = usePreference(prefsKey, defaultPrefs)
  const { viewType, sortType, direction } = prefs
  const [displayItems, setDisplayItems] = useState<readonly T[]>()
  const [isHelpOpen, setIsHelpOpen] = useState(false)

  useEffect(() => {
    if (!doFilterSort || noHeader) {
      setDisplayItems(items)
    } else {
      const display = doFilterSort(items, sortType, filter.toLocaleLowerCase())
      if (direction === 'descending') display.reverse()
      setDisplayItems(display)
    }
  }, [doFilterSort, items, sortType, direction, filter, noHeader])

  function handleClickItem(item: T): void {
    switch (mode) {
      case 'view':
        props.onNavigate?.(item)
        break
      case 'choose':
        props.onChoose?.(item)
        break
      case 'select':
        props.onSelect?.(item)
        break
    }
  }
  function handlePrefs(partialPrefs: Partial<typeof defaultPrefs>): void {
    setPrefs((p) => ({ ...p, ...partialPrefs }))
  }

  const showHeader = filter || (!noHeader && items.length > 4)
  const showOverlay = mode === 'choose' || mode === 'select'

  return (
    <div className="pb-2">
      {showHeader && (
        <Header
          sortDefs={sortDefs}
          sortType={sortType}
          setSortType={(sortType) => handlePrefs({ sortType })}
          sortDirection={direction}
          setSortDirection={(direction) => handlePrefs({ direction })}
          filter={filter}
          setFilter={(filter) => setFilter(filter)}
          viewType={viewType}
          setViewType={(viewType) => handlePrefs({ viewType })}
          showHelp={searchHelp ? () => setIsHelpOpen(true) : undefined}
        />
      )}
      <ul
        role="list"
        className={classNames(
          '',
          viewType === 'grid' ? 'flex flex-row flex-wrap justify-start items-start' : 'flex flex-col space-y-2'
        )}
      >
        {displayItems && displayItems.length > 0 ? (
          displayItems.map((item) => {
            return (
              <div
                key={`${viewType}-${item.key}`}
                onClick={() => handleClickItem(item)}
                className={classNames(
                  'relative group cursor-pointer',
                  viewType === 'grid' ? 'w-1/3 lg:w-48' : 'w-full'
                )}
              >
                {props.children(viewType, item)}
                {showOverlay && <SelectOverlay selected={props.isSelected?.(item)} mode={mode} />}
              </div>
            )
          })
        ) : (
          <div className="p-4 text-gray-600">No matches</div>
        )}
      </ul>
      {searchHelp && (
        <SearchHelp isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)}>
          {searchHelp}
        </SearchHelp>
      )}
    </div>
  )
}

type HelpProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
}
function SearchHelp({ isOpen, onClose, children }: HelpProps): JSX.Element {
  return (
    <LifeDialog title="Search Help" isOpen={isOpen} onClose={onClose}>
      <LifeDialog.Content className="max-w-xl">{children}</LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose}>Done</ButtonCancel>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
