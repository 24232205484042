import { age, ageToString, ButtonEdit, UserGroupIcon } from '@life/components'
import { Image } from '@life/frontend-model'
import { canEditBook } from '../../common'
import { PersonLink } from '../../person'

type Props = {
  image: Image
}
export function PeopleInImage({ image }: Props): JSX.Element | null {
  const canEdit = canEditBook(image.book)

  return (
    <div className="mt-5">
      <h3 className="flex justify-between items-center">
        <span className="text-lg font-bold">People in Photo</span>
        <ButtonEdit
          to="people"
          canEdit={canEdit}
          icon={<UserGroupIcon className="sm:-ml-0.5 sm:mr-2 h-6 w-6 sm:h-4 sm:w-4" aria-hidden="true" />}
        >
          Tag
        </ButtonEdit>
      </h3>
      {image.who.length > 0 ? (
        <div className="flex flex-row gap-3 flex-wrap">
          {image.who.map((person) => {
            const personAge = ageToString(age(person.birthDate, image?.when))
            return (
              <div key={person.key}>
                <PersonLink person={person} />
                {personAge && <span className="ml-2 text-gray-600 text-sm">({personAge})</span>}
              </div>
            )
          })}
        </div>
      ) : (
        <div className="italic text-gray-500 text-sm pl-2">
          No one is tagged yet. Click the button above to identify the person or people in the photo.
        </div>
      )}
    </div>
  )
}
