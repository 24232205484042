import {
  ButtonSave,
  compareDates,
  DatesForm,
  Input,
  navigateConfirmed,
  NavigationConfirmation,
  NotReady,
  PageHeading,
  PagePadding,
  useErrorNotification,
  useLifeForm,
  useRequiredParam,
} from '@life/components'
import { Person, useBook, useUpdatePerson } from '@life/frontend-model'
import { DateBreakdown, Logger } from '@life/model'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { canEditBook } from '../common'

const logger = new Logger('person-edit')

export function EditPerson() {
  type FormFields = {
    lastName: string
    givenNames: string
    suffix: string
    nickName: string
    otherLastNames: string
    birthDate: DateBreakdown
    deathDate: DateBreakdown
  }
  const [bookSlug, personId] = useRequiredParam(['bookSlug', 'personId'])
  const { book, isLoading, error } = useBook(bookSlug)
  const [birthDateError, setBirthDateError] = useState<string>()
  const [deathDateError, setDeathDateError] = useState<string>()
  const navigate = useNavigate()
  const [isSaved, setIsSaved] = useState(false)
  const updater = useUpdatePerson()
  const { showError } = useErrorNotification()
  const person = book?.findPerson(personId)
  const form = useLifeForm<FormFields>({
    lastName: person?.lastName,
    givenNames: person?.givenNames,
    suffix: person?.suffix,
    nickName: person?.nickName,
    otherLastNames: person?.otherLastNamesDisplay,
    birthDate: person?.birthDate,
    deathDate: person?.deathDate,
  })
  // End of Hooks

  if (isLoading || error || !book || !bookSlug) {
    return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  }
  if (!person) {
    return <NotReady type="Person" id={personId} notFound />
  }

  async function update(fields: FormFields): Promise<void> {
    if (!book) return // should never happen
    try {
      // Convert otherLastNames to [] with each part trimmed
      const otherLastNames = fields.otherLastNames?.split(',')
      otherLastNames?.forEach((p, index) => otherLastNames.splice(index, 1, p.trim()))
      const person = new Person(book, { ...fields, otherLastNames, bookId: book.bookId, personId, status: 'stub' })
      await updater.update(person)
      setIsSaved(true)
      await navigateConfirmed(navigate, -1)
    } catch (error) {
      showError('Error Updating Person', error)
    }
  }

  const { register, control, formState, watch } = form

  return (
    <PagePadding narrow>
      <form onSubmit={form.handleSubmit(update)}>
        <PageHeading
          title={['Person', person.formalName, 'Edit']}
          right={
            <ButtonSave
              disabled={!formState.isDirty || !!birthDateError || !!deathDateError}
              clicked={updater.isUpdating}
              canEdit={canEditBook(book)}
            />
          }
        >
          Edit Person
        </PageHeading>
        <Input
          {...register('givenNames', {
            required: {
              value: true,
              message: 'Given name is required',
            },
          })}
          label="Given name(s)"
          placeholder="Given names"
          help="Enter the given name or names of the person"
          error={formState.errors.givenNames}
        />
        <Input
          {...register('lastName', {
            required: {
              value: true,
              message: 'Last name is required',
            },
          })}
          label="Last name"
          placeholder="Last name"
          help="Enter the last name or family name of the person. You can choose if this should be a married or maiden name. The Last name is used to sort people in your book's index."
          autoComplete="name"
          error={formState.errors.lastName}
        />
        <Input
          {...register('otherLastNames')}
          placeholder="Last name(s)"
          label="Other Last Name(s) (e.g., maiden name)"
          help="Enter additional last names separated by comma (e.g., &ldquo;Flintstone, Rubble&rdquo;)"
          error={formState.errors.otherLastNames}
        />
        <Input
          {...register('suffix', {
            maxLength: {
              value: 15,
              message: 'Max length for suffix is 15 characters',
            },
          })}
          label="Suffix"
          placeholder=""
          autoComplete="name"
          help="Optional suffix like Jr or Sr"
          error={formState.errors.suffix}
        />
        <Input
          {...register('nickName', {
            maxLength: {
              value: 15,
              message: 'Max length for suffix is 15 characters',
            },
          })}
          placeholder="Nickname"
          label="Nickname"
          autoComplete="name"
          help="A familiar name you would normally use to call them"
          error={formState.errors.suffix}
        />
        <div className="flex">
          <div className="flex-1">
            <label htmlFor="birthDate" className="block text-sm font-bold text-gray-700 mt-6">
              Birth
            </label>
            <Controller
              control={control}
              name="birthDate"
              render={({ field: { onChange, value } }) => (
                <DatesForm
                  types={['breakdown']}
                  defaultValue={value}
                  onChange={(value, error) => {
                    onChange(value)
                    setBirthDateError(error)
                  }}
                />
              )}
            />
            {birthDateError && <div className="mt-1 pl-3 text-sm text-red-500">{birthDateError}</div>}
          </div>
          <div className="flex-1">
            <label htmlFor="deathDate" className="block text-sm font-bold text-gray-700 mt-6">
              Death
            </label>
            <Controller
              control={control}
              name="deathDate"
              render={({ field: { onChange, value } }) => (
                <DatesForm
                  types={['breakdown']}
                  optional
                  defaultValue={value}
                  onChange={(value, error) => {
                    onChange(value)
                    setDeathDateError(undefined)
                    if (error) {
                      logger.error(error)
                      setDeathDateError(error)
                    } else if (value) {
                      const birthDate = watch('birthDate')
                      if (compareDates(birthDate, value as DateBreakdown) > 0) {
                        setDeathDateError('Death date cannot be before birth date')
                      }
                    }
                  }}
                />
              )}
            />
            {deathDateError && <div className="mt-1 pl-3 text-sm text-red-500">{deathDateError}</div>}
          </div>
        </div>{' '}
        <NavigationConfirmation unsavedChanges={formState.isDirty && !isSaved} />
      </form>
    </PagePadding>
  )
}
