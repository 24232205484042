import { API, Logger } from '@life/model'
import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { classNames } from './classNames'
import { CloseIcon, WarningIcon } from './icons'
import { LifeDialog } from './LifeDialog'

const logger = new Logger('component-errornotice')

type Severity = 'ERROR' | 'WARN' | 'INFO'
export type NotificationState = {
  isOpen: boolean
  open: (severity: Severity, title: string, message?: string) => void
}
const NotificationContext = createContext<NotificationState | null>(null)

type NotificationProps = {
  children?: ReactNode
}
export function NotificationProvider({ children }: NotificationProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState<string>()
  const [severity, setSeverity] = useState<Severity>('INFO')
  const open = useCallback((s: Severity, t: string, m?: string) => {
    setTitle(t)
    setMessage(m)
    setSeverity(s)
    setIsOpen(true)
  }, [])
  const value = { isOpen, open }
  return (
    <>
      <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
      <ErrorDialog
        isOpen={isOpen}
        title={title}
        message={message}
        severity={severity}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

export function useNotifications(): NotificationState {
  const state = useContext(NotificationContext)
  if (!state) throw new Error('useNotifications must be used within a NotificationProvider')
  return state
}

export type ErrorNotificationState = {
  showError: (title: string, error?: unknown) => void
  showWarning: (title: string, message: string) => void
  showInfo: (title: string, message: string) => void
}
export function useErrorNotification(): ErrorNotificationState {
  const { open } = useNotifications()

  const showError = useCallback(
    (title: string, error?: unknown) => {
      logger.error(title, error)
      let message: string | undefined
      if (API.isResponseError(error)) {
        if (error.error === 'VersionMismatch') {
          message =
            'Version mismatch: This can occur when you or another user has saved a version since you last reloaded. Try refreshing your browser and try again.'
        } else {
          message = `Error: ${error.message}`
        }
      } else if (error instanceof Error) {
        message = process.env.NODE_ENV !== 'production' ? `Error: ${error.message}` : undefined
      } else {
        message = process.env.NODE_ENV !== 'production' ? `Error: ${String(error)}` : undefined
      }
      open('ERROR', title, message)
    },
    [open]
  )
  const showWarning = useCallback(
    (title: string, message: string) => {
      logger.warn(message)
      open('WARN', title, message)
    },
    [open]
  )
  const showInfo = useCallback(
    (title: string, message: string) => {
      open('INFO', title, message)
    },
    [open]
  )
  return { showError, showWarning, showInfo }
}

type DialogProps = {
  isOpen: boolean
  title: string
  message?: string
  severity?: Severity
  onClose: () => void
}
function ErrorDialog({ isOpen, title, message, severity = 'INFO', onClose }: DialogProps): JSX.Element {
  return (
    <LifeDialog isOpen={isOpen} onClose={onClose}>
      <LifeDialog.Content>
        <div className="flex items-center justify-center">
          <span
            className={classNames(
              'flex p-2 rounded-lg',
              severity === 'INFO' ? 'bg-indigo-800' : '',
              severity === 'WARN' ? 'bg-yellow-500' : '',
              severity === 'ERROR' ? 'bg-red-800' : ''
            )}
          >
            <WarningIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <div className="ml-3 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 max-w-prose text-sm text-gray-500">{message}</p>
          </div>
          <div className="ml-4 flex-shrink-0 flex">
            <button
              type="button"
              className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onClose}
            >
              <span className="sr-only">Dismiss</span>
              <CloseIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </LifeDialog.Content>
    </LifeDialog>
  )
}
