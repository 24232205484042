import {
  ButtonCancel,
  ButtonRemove,
  ButtonSave,
  classNames,
  DeleteIcon,
  DropDownIcon,
  ExpandIcon,
  LifeDialog,
  LifeMenu,
  NotReady,
  SpinnableIcon,
  useErrorNotification,
} from '@life/components'
import {
  Book,
  Collaborator,
  useCollaborators,
  useRemoveCollaborator,
  useUpdateBook,
  useUpdateCollaborator,
} from '@life/frontend-model'
import { CollaboratorRole } from '@life/model'
import { useEffect, useState } from 'react'

type Props = {
  book: Book
  className?: string
}
export function ManageCollaborators({ book, className }: Props): JSX.Element {
  const { collaborators, isLoading, error } = useCollaborators(book)
  const { update: updateCollaborator, isUpdating: isUpdatingCollaborator } = useUpdateCollaborator()
  const { update: updateBook, isUpdating: isUpdatingBook } = useUpdateBook()
  const [savingCollaborator, setSavingCollaborator] = useState<Collaborator>()
  const [removingCollaborator, setRemovingCollaborator] = useState<Collaborator>()
  const [expanded, setExpanded] = useState(true)
  const [maxCollaborators, setMaxCollaborators] = useState(book.maxCollaborators)
  const { showError } = useErrorNotification()
  useEffect(() => {
    if (isLoading || !collaborators) return
    if (collaborators.length > 10) setExpanded(false)
  }, [isLoading, collaborators])

  async function saveMaxCollaborators(): Promise<void> {
    book.maxCollaborators = maxCollaborators || undefined // 0 and undefined mean "infinite"
    try {
      await updateBook(book)
    } catch (error) {
      showError('Error saving max collaborators', error)
    }
  }
  async function saveRole(coll: Collaborator, role: CollaboratorRole): Promise<void> {
    coll.role = role
    try {
      setSavingCollaborator(coll)
      await updateCollaborator(coll)
    } catch (error) {
      showError('Error saving new role', error)
    } finally {
      setSavingCollaborator(undefined)
    }
  }
  function buildCollabsText(): string {
    if (!collaborators) return ''
    const collabs = collaborators.length - 1 // Subtract one for the Owner
    const editors = collaborators?.filter((c) => c.role === 'Editor')?.length ?? 0
    const viewers = collaborators?.filter((c) => c.role === 'Viewer')?.length ?? 0
    let str = collabs !== 1 ? `You have ${collabs} collaborators` : 'You have 1 collaborator'
    if (editors > 0 || viewers > 0) {
      const details = []
      if (editors > 0) details.push(editors !== 1 ? `${editors} Editors` : `1 Editor`)
      if (viewers > 0) details.push(viewers !== 1 ? `${viewers} Viewers` : `1 Viewer`)
      str += ' (' + details.join(' and ') + ')'
    }
    return str
  }

  return (
    <div className={className}>
      <div className="flex flex-row items-center space-x-8">
        <label htmlFor="max" className="-mr-6">
          Limit collaborators to:
        </label>
        <input
          type="number"
          name="max"
          value={maxCollaborators || ''}
          placeholder={maxCollaborators ? '' : 'Unlimited'}
          min="0"
          onChange={(e) => setMaxCollaborators(+e.target.value)}
          className="focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 w-28 shadow-sm text-sm rounded-md"
        />
        <ButtonSave clicked={isUpdatingBook} onClick={saveMaxCollaborators} />
      </div>
      {collaborators && (
        <>
          <div className="pt-2 space-y-4">
            <div>{buildCollabsText()}</div>
            <div className="flex items-center flex-1 space-x-2">
              <ExpandIcon
                className={classNames(
                  'h-4 w-4 cursor-pointer',
                  expanded ? 'transition-transform rotate-90' : 'transition-transform rotate-0'
                )}
                onClick={() => setExpanded((previous) => !previous)}
              />
              <span className="font-bold">Collaborators</span>
            </div>
            {expanded && (
              <table className="min-w-fit">
                <thead className="border-b">
                  <tr>
                    <th scope="col" className="text-sm font-bold px-6 py-1 text-left">
                      Name
                    </th>
                    <th scope="col" className="text-sm font-bold px-6 py-1 text-left">
                      Role
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {collaborators.reduce((list, coll) => {
                    if (coll.role !== 'Owner')
                      list.push(
                        <tr key={coll.userId} className="border-b">
                          <td className="px-6 py-1 whitespace-nowrap text-sm font-medium">{coll.formalName}</td>
                          <td className="px-6 py-1 whitespace-nowrap text-sm font-medium">
                            <RoleMenu
                              role={coll.role}
                              saving={savingCollaborator?.userId === coll.userId && isUpdatingCollaborator}
                              onChange={(r) => saveRole(coll, r)}
                              onRemove={() => setRemovingCollaborator(coll)}
                            />
                          </td>
                        </tr>
                      )
                    return list
                  }, [] as JSX.Element[])}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}
      {!collaborators && <NotReady type="Collaborator" isLoading={isLoading} />}
      {error && <div>Error loading collaborators. Error: {error.message}</div>}
      {removingCollaborator && (
        <ConfirmDialog
          collaborator={removingCollaborator}
          isOpen={!!removingCollaborator}
          onClose={() => setRemovingCollaborator(undefined)}
        />
      )}
    </div>
  )
}

type RoleMenuProps = {
  role: CollaboratorRole
  saving: boolean
  onChange: (role: CollaboratorRole) => void
  onRemove: () => void
}
function RoleMenu({ role, saving, onChange, onRemove }: RoleMenuProps): JSX.Element {
  const values: CollaboratorRole[] = ['Viewer', 'Editor']
  return (
    <LifeMenu>
      <LifeMenu.Button className="px-2 py-2 shadow-sm text-sm font-medium text-slate-700 flex space-x-2">
        <span className="pr-0.5">{role}</span>
        <SpinnableIcon icon={DropDownIcon} spinning={saving} className="-mr-1 h-5 w-5" />
      </LifeMenu.Button>
      <LifeMenu.Items>
        {values.map((r) => (
          <LifeMenu.Item key={r}>
            {({ active }) => (
              <button
                type="button"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'flex w-full text-left px-4 py-2 space-x-2 text-sm whitespace-nowrap'
                )}
                onClick={() => onChange(r)}
              >
                {r}
              </button>
            )}
          </LifeMenu.Item>
        ))}
        <LifeMenu.Item>
          {({ active }) => (
            <button
              type="button"
              className={classNames(
                'flex w-full items-center text-left px-4 py-2 text-sm rounded-md',
                active ? 'bg-red-500 text-white' : 'text-gray-700'
              )}
              onClick={onRemove}
            >
              <DeleteIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              Remove
            </button>
          )}
        </LifeMenu.Item>
      </LifeMenu.Items>
    </LifeMenu>
  )
}

type ConfirmProps = {
  collaborator: Collaborator
  isOpen: boolean
  onClose: VoidFunction
}
function ConfirmDialog({ isOpen, collaborator, onClose }: ConfirmProps): JSX.Element {
  const { remove, isRemoving } = useRemoveCollaborator()
  const { showError } = useErrorNotification()

  async function removeCollaborator(): Promise<void> {
    try {
      await remove(collaborator)
      onClose()
    } catch (error) {
      showError('Error removing collaborator', error)
    }
  }

  const shortcuts = { Escape: onClose }
  return (
    <LifeDialog modal title="Confirmation" isOpen={isOpen} shortcuts={shortcuts} onClose={onClose}>
      <LifeDialog.Content>
        <p className="pb-1">Are you sure you want to remove the collaborator?</p>
        <p className="pb-1">This cannot be easily undone.</p>
        <p className="pb-1">To restore a collaborator, send them another invitation and ask them to accept it.</p>
      </LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose} />
        <ButtonRemove confirm={false} clicked={isRemoving} onClick={removeCollaborator} />
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
