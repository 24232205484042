import { API, BookId } from '@life/model'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { queryOptions } from './book-api'
import { Collaborator } from './collaborator'

const listKey = (bookId: BookId) => `COLL:${bookId}`

export type CollaboratorState = {
  isLoading: boolean
  error?: API.GetErrors
  collaborators?: Collaborator[]
}
export function useCollaborators(book: Book): CollaboratorState {
  const query = useQuery<Collaborator[], API.GetErrors>(
    listKey(book.bookId),
    () => listCollaborators(book),
    queryOptions
  )
  return { ...query, error: query.error ?? undefined, collaborators: query.data }
}

export type UpdateCollaboratorState = {
  isUpdating: boolean
  update: (collaborator: Collaborator) => Promise<void>
}
export function useUpdateCollaborator(): UpdateCollaboratorState {
  const queryClient = useQueryClient()
  const mutation = useMutation((collaborator: Collaborator) => updateCollaborator(collaborator))
  async function update(collaborator: Collaborator): Promise<void> {
    try {
      await mutation.mutateAsync(collaborator)
      queryClient.setQueryData<Collaborator[] | undefined>(listKey(collaborator.bookId), (previous) => {
        if (!previous) return previous
        return Object.assign(previous, collaborator)
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isUpdating: mutation.isLoading, update }
}

export type RemoveCollaboratorState = {
  isRemoving: boolean
  remove: (collaborator: Collaborator) => Promise<void>
}
export function useRemoveCollaborator(): RemoveCollaboratorState {
  const queryClient = useQueryClient()
  const mutation = useMutation((collaborator: Collaborator) => removeCollaborator(collaborator))
  async function remove(collaborator: Collaborator): Promise<void> {
    try {
      await mutation.mutateAsync(collaborator)
      queryClient.setQueryData<Collaborator[] | undefined>(listKey(collaborator.bookId), (previous) => {
        if (!previous) return previous
        return previous.filter((c) => c.userId !== collaborator.userId)
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isRemoving: mutation.isLoading, remove }
}

async function listCollaborators({ bookId }: Book): Promise<Collaborator[]> {
  const response = await serverRequest<API.CollaboratorListInput, API.CollaboratorListSuccess>('/collaborator/list', {
    bookId,
  })
  return response.list.map((c) => new Collaborator(c))
}

async function updateCollaborator(collaborator: Collaborator): Promise<void> {
  await serverRequest<API.CollaboratorUpdateInput, API.ResponseSuccess>('/collaborator/update', {
    collaborator: collaborator.toModel(),
  })
}

async function removeCollaborator({ bookId, userId }: Collaborator): Promise<void> {
  await serverRequest<API.CollaboratorRemoveInput, API.ResponseSuccess>('/collaborator/remove', { bookId, userId })
}
