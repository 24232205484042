import {
  ButtonAdd,
  ButtonSave,
  navigateConfirmed,
  NavigationConfirmation,
  NotReady,
  PageHeading,
  PagePadding,
  useErrorNotification,
  useRequiredParam,
} from '@life/components'
import { Person, Image, useBook, useUpdateImage } from '@life/frontend-model'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AddPersonDialog, filterPeopleByDate, PersonList } from '../person'

export function PersonManager(): JSX.Element {
  const [bookSlug, imageId] = useRequiredParam(['bookSlug', 'imageId'])
  const { book, isLoading, error } = useBook(bookSlug)
  const [who, setWho] = useState<Person[]>([])
  const [isDirty, setIsDirty] = useState(false)
  const [addIsOpen, setAddIsOpen] = useState(false)
  const navigate = useNavigate()
  const updater = useUpdateImage()
  const { showError } = useErrorNotification()
  let image: Image | undefined = undefined
  useEffect(() => {
    if (!image || !image.who) return
    setWho(image.who)
  }, [image, book?.people])
  // End of Hooks

  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  image = book.findImage(imageId)
  if (!image) return <NotReady type="Image" id={imageId} notFound />

  const bookPeople = filterPeopleByDate(book.people, image.when)

  async function save(image: Image | undefined): Promise<void> {
    if (!image) return // should never happen
    try {
      image.who = who
      await updater.update(image)
      setIsDirty(false)
      await navigateConfirmed(navigate, -1)
    } catch (error) {
      showError('Error Updating Photo', error)
    }
  }

  function toggleSelection(person: Person): void {
    if (isSelected(person)) setWho((w) => w.filter((p) => p.personId !== person.personId))
    else setWho((w) => [...w, person])
    setIsDirty(true)
  }
  function isSelected(person: Person): boolean {
    return !!who.find((p) => p.personId === person.personId)
  }

  function handleAddPerson(person: Person): void {
    setAddIsOpen(false)
    toggleSelection(person)
  }

  return (
    <PagePadding>
      <PageHeading
        title="Tag Photo"
        className="mb-2"
        right={
          <div className="flex space-x-2">
            <ButtonAdd onClick={() => setAddIsOpen(true)}>Add Person</ButtonAdd>
            <ButtonSave clicked={updater.isLoading} disabled={!isDirty} onClick={() => save(image)} />
          </div>
        }
      >
        Tag People in Photo
      </PageHeading>
      <PersonList mode="select" book={book} people={bookPeople} isSelected={isSelected} onSelect={toggleSelection} />
      <NavigationConfirmation unsavedChanges={isDirty} />
      <AddPersonDialog
        key={String(addIsOpen)}
        isOpen={addIsOpen}
        book={book}
        onAdd={handleAddPerson}
        onClose={() => setAddIsOpen(false)}
      />
    </PagePadding>
  )
}
