import { LifeDialog } from '@life/components'
import { AddStoryForm, StoryFormProps } from './AddStoryForm'

type TitleDialogProps = StoryFormProps & {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  isOpen: boolean
  onClose: () => void
}

export function AddStoryDialog({ isOpen, content, book, onAdd, onClose }: TitleDialogProps): JSX.Element {
  const shortcuts = { Escape: onClose }
  return (
    <LifeDialog modal isOpen={isOpen} title="Add Substory" onClose={onClose} shortcuts={shortcuts}>
      <LifeDialog.Content>
        <AddStoryForm onAdd={onAdd} onClose={onClose} book={book} content={content} />
      </LifeDialog.Content>
    </LifeDialog>
  )
}
