import { Disclosure } from '@headlessui/react'
import { CloseIcon, MenuIcon } from '@life/components'

type Props = {
  open: boolean
}

export function ButtonToggleMenu({ open }: Props): JSX.Element {
  return (
    <Disclosure.Button className="bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-400 hover:text-white hover:bg-indigo-600">
      <span className="sr-only">Open main menu</span>
      {open ? (
        <CloseIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
      )}
    </Disclosure.Button>
  )
}
