import { API, BookId, InviteId } from '@life/model'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { booksKey, queryOptions } from './book-api'
import { Invite, UnsavedInvite } from './invite'

const invitesKey = (bookId: BookId) => `INVITES:${bookId}`

export type InviteState = {
  isLoading: boolean
  error?: API.GetErrors
  invites?: Invite[]
}
export function useInvites(book: Book): InviteState {
  const query = useQuery<Invite[], API.GetErrors>(invitesKey(book.bookId), () => readActiveInvites(book), queryOptions)
  return { ...query, error: query.error ?? undefined, invites: query.data }
}

export type CreateInviteState = {
  isCreating: boolean
  create: (invite: UnsavedInvite) => Promise<void>
}
export function useCreateInvite(): CreateInviteState {
  const queryClient = useQueryClient()
  const mutation = useMutation((invite: UnsavedInvite) => createInvite(invite))
  async function create(invite: UnsavedInvite): Promise<void> {
    try {
      const output = await mutation.mutateAsync(invite)
      queryClient.setQueryData<Invite[] | undefined>(invitesKey(invite.bookId), (previous) => {
        if (!previous) return previous
        return [...previous, output]
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isCreating: mutation.isLoading, create }
}

export type RemoveInviteState = {
  isRemoving: boolean
  remove: (invite: Invite) => Promise<void>
}
export function useRemoveInvite(): RemoveInviteState {
  const queryClient = useQueryClient()
  const mutation = useMutation((invite: Invite) => removeInvite(invite))
  async function remove(invite: Invite): Promise<void> {
    try {
      await mutation.mutateAsync(invite)
      queryClient.setQueryData<Invite[] | undefined>(invitesKey(invite.bookId), (previous) => {
        if (!previous) return previous
        return previous.filter((i) => i.inviteId !== invite.inviteId)
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isRemoving: mutation.isLoading, remove }
}

export type AcceptInviteState = {
  isAccepting: boolean
  accept: (bookId: BookId, inviteId: InviteId) => Promise<void>
}
export function useAcceptInvite(): AcceptInviteState {
  const queryClient = useQueryClient()
  const mutation = useMutation((input: { bookId: BookId; inviteId: InviteId }) => acceptInvite(input))
  async function accept(bookId: BookId, inviteId: InviteId): Promise<void> {
    try {
      await mutation.mutateAsync({ bookId, inviteId })
      queryClient.invalidateQueries(booksKey)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, isAccepting: mutation.isLoading, accept }
}

async function readActiveInvites(book: Book): Promise<Invite[]> {
  const response = await serverRequest<API.InviteGetActiveInput, API.InviteGetActiveSuccess>('/invite/get', {
    bookId: book.bookId,
  })
  return response.invites.map((inv) => new Invite(book, inv.invite, inv.url))
}

async function createInvite(invite: UnsavedInvite): Promise<Invite> {
  const response = await serverRequest<API.InviteCreateInput, API.InviteCreateSuccess>('/invite/create', {
    invite: invite.toUnsavedModel(),
  })
  return new Invite(invite.book, response.invite, response.url)
}

async function removeInvite({ bookId, inviteId }: Invite): Promise<void> {
  await serverRequest<API.InviteRemoveInput, API.InviteRemoveSuccess>('/invite/remove', { bookId, inviteId })
}

async function acceptInvite({
  bookId,
  inviteId,
}: {
  bookId: BookId
  inviteId: InviteId
}): Promise<API.ResponseSuccess> {
  return serverRequest<API.InviteAcceptInput, API.InviteAcceptSuccess>('/invite/accept', { id: bookId, inviteId })
}
