import { ButtonEdit, classNames, PageHeading } from '@life/components'
import { Book, Story } from '@life/frontend-model'
import { canEditBook } from '../../common'
import { ReaderMenu } from './ReaderMenu'
import { Title } from './Title'

type Props = {
  book: Book
  story: Story
}

export function StoryHeading({ book, story }: Props): JSX.Element {
  return (
    <PageHeading
      title={['Story', story.title]}
      className={classNames('px-2 sm:px-4 max-w-4xl pt-4')}
      right={
        <>
          <ButtonEdit to="edit" canEdit={canEditBook(book)} />
          <ReaderMenu book={book} story={story} />
        </>
      }
    >
      <Title story={story} />
    </PageHeading>
  )
}
