import { classNames, S3ImageView, UserIcon } from '@life/components'
import { Image } from '@life/frontend-model'
import { Link } from 'react-router-dom'

type Props = {
  className?: string
  image: Image | undefined
  href?: string
}

export function PersonImage({ image, className, href }: Props): JSX.Element {
  const div = (
    <div
      className={classNames(
        'overflow-hidden border rounded-full aspect-square flex items-center justify-center bg-gray-300',
        className
      )}
    >
      {image ? <S3ImageView image={image} size="thumb" /> : <UserIcon className="text-gray-500 w-3/4 mx-auto" />}
    </div>
  )
  if (!href) return div
  return <Link to={href}>{div}</Link>
}
