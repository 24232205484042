import { NotReady, PageHeading, PagePadding, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { isEmpty } from '@life/model'
import { Link, useNavigate } from 'react-router-dom'
import { canEditBook } from '../common'
import { getTasksForBook, Task } from './tasksForBook'

export function BookDashboard(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)
  const navigate = useNavigate()

  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  if (!canEditBook(book)) navigate(`/site/${book.bookId}`)

  const tasks = getTasksForBook(book)
  return (
    <PagePadding>
      <PageHeading title="Dashboard" back={false}>
        {book.title}
        {book.subtitle && <span className="font-normal italic text-2xl">: {book.subtitle}</span>}
      </PageHeading>

      <div className="flex flex-col sm:flex-row pb-5">
        <div className="sm:w-1/3">
          <h2 className="text-xl pt-2">Quick Links</h2>
          <ul className="flex flex-col p-4 text-lg space-y-2">
            <li className="">
              <Link to="people" className="font-bold hover:underline">
                People ({book.people.length})
              </Link>
            </li>
            <li className="">
              <Link to="photos" className="font-bold hover:underline">
                Photos ({book.images.length})
              </Link>
            </li>
            <li className="">
              <Link to="stories" className="font-bold hover:underline">
                Stories ({book.stories.length})
              </Link>
            </li>
          </ul>
        </div>
        <div className="sm:w-2/3">
          <h2 className="text-xl pt-2">Tasks</h2>
          <ul className="pl-2 flex flex-col space-y-2">
            {tasks.length === 0 && (
              <li className="text-slate-500 italic">
                You don&apos;t have any tasks to complete right now. Use the Quick Links to navigate to your story.
              </li>
            )}
            {tasks.map((task) => (
              <li key={task.id} className="">
                <Link to={task.to} className="font-bold hover:underline">
                  {task.label} {getTaskPercent(task)}
                </Link>
                <div className="pl-4">{task.explanation}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </PagePadding>
  )
}

function getTaskPercent(task: Task): string {
  if (task.complete === undefined) return ''
  if (isEmpty(task.total)) return ''
  if (task.total < 1) return ''
  return `(${Math.round((100 * task.complete) / task.total)}% complete)`
}
