import { StoryStatusType } from '@life/model'
import { Controller, UseFormReturn } from 'react-hook-form'
import { StatusMenu } from '../StatusMenu'
import { StoryFormFields } from './EditStory'

type StatusProps = {
  form: UseFormReturn<StoryFormFields>
  status: StoryStatusType
}
export function Status({ form, status }: StatusProps): JSX.Element {
  return (
    <Controller
      render={({ field }) => (
        <div className="flex items-center">
          <span className="text-md font-bold text-slate-700">Status:</span>
          <StatusMenu status={status} onChange={(status) => field.onChange(status)} />
        </div>
      )}
      control={form.control}
      name="status"
    />
  )
}
