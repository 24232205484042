import { ComponentProps } from 'react'
import { Spinner } from '../Spinner'
import { WithChildren } from '../types'

type Props = WithChildren & {
  spinning: boolean
  className: string
  icon: (props: ComponentProps<'svg'>) => JSX.Element
}

export function SpinnableIcon({ spinning, className, icon: Icon }: Props): JSX.Element {
  if (spinning) return <Spinner className={className} aria-hidden="true" />
  return <Icon className={className} aria-hidden="true" />
}
