import { DateBreakdown, DateRange, DateSingle, LifeDate, LifeDateType } from '@life/model'
import { useState } from 'react'
import { DateForm } from './DateForm'
import { compareDates, getDateType, isDateBreakdown, isDateRange, isDateSingle, isValidDate } from './dates'

type InProcessDateBreakdown = Partial<DateBreakdown> | undefined
type InProcessDateSingle = (InProcessDateBreakdown & { type: 'single' }) | undefined
type InProcessDateRange = { type: 'range'; start: InProcessDateBreakdown; end: InProcessDateBreakdown } | undefined
type InProcessDate = InProcessDateBreakdown | InProcessDateSingle | InProcessDateRange

type Props = {
  types: LifeDateType[]
  optional?: boolean
  defaultValue: LifeDate | undefined
  onChange: (value: LifeDate | undefined, error: string | undefined) => void
}
export function DatesForm({ types, optional = false, defaultValue, onChange }: Props): JSX.Element {
  const [date, setDate] = useState<InProcessDate>(defaultValue)
  const [dateType, setDateType] = useState<LifeDateType | undefined>(
    getDateType(defaultValue) ?? (!optional ? types[0] : undefined)
  )

  function handleUpdate(dateType: LifeDateType | undefined, value: InProcessDate): void {
    setDateType(dateType)
    setDate(value)
    const error = validateLifeDate(value)
    onChange(value as LifeDate, error)
  }

  return (
    <div className="m-1">
      <SelectType types={types} optional={optional} date={date} defaultValue={defaultValue} onChange={handleUpdate} />
      {dateType === 'breakdown' && <BreakdownDate date={date as InProcessDateBreakdown} onChange={handleUpdate} />}
      {dateType === 'single' && <SingleDate date={date as InProcessDateSingle} onChange={handleUpdate} />}
      {dateType === 'range' && <RangeDate date={date as InProcessDateRange} onChange={handleUpdate} />}
    </div>
  )
}

type TypeProps = {
  types: Props['types']
  optional: boolean
  defaultValue: LifeDate | undefined
  date: InProcessDate
  onChange: (dateType: LifeDateType | undefined, value: InProcessDate) => void
}
function SelectType({ types, optional, defaultValue, date, onChange }: TypeProps): JSX.Element {
  if (!optional && types.length === 1) return <></>
  return (
    <div className="flex flex-row space-x-2">
      {optional && (
        <label className="text-sm text-gray-600">
          <input
            type="radio"
            name="type"
            value="none"
            className="mr-1"
            checked={!date}
            onChange={() => onChange(undefined, undefined)}
          />
          N/A
        </label>
      )}
      {types.includes('breakdown') && (
        <label className="text-sm text-gray-600">
          <input
            type="radio"
            name="type"
            value="breakdown"
            className="mr-1"
            checked={!!date && isDateBreakdown(date as DateSingle)}
            onChange={() =>
              onChange('breakdown', {
                year: 0,
                ...(defaultValue && isDateRange(defaultValue as DateRange) && (defaultValue as DateRange).start),
                ...(defaultValue && isDateSingle(defaultValue as DateSingle) && defaultValue),
              })
            }
          />
          Date
        </label>
      )}
      {types.includes('single') && (
        <label className="text-sm text-gray-600">
          <input
            type="radio"
            name="type"
            value="single"
            className="mr-1"
            checked={!!date && isDateSingle(date as DateSingle)}
            onChange={() =>
              onChange('single', {
                type: 'single',
                year: 0,
                ...(defaultValue && isDateRange(defaultValue as DateRange) && (defaultValue as DateRange).start),
                ...(defaultValue && isDateSingle(defaultValue as DateSingle) && defaultValue),
              })
            }
          />
          Single
        </label>
      )}
      {types.includes('range') && (
        <label className="text-sm text-gray-600">
          <input
            type="radio"
            name="type"
            value="range"
            className="mr-1"
            checked={!!date && isDateRange(date as DateRange)}
            onChange={() =>
              onChange('range', {
                type: 'range',
                start: {
                  year: 0,
                  ...(defaultValue && isDateSingle(date as DateSingle) && defaultValue),
                },
                end: {
                  year: 0,
                  ...(defaultValue && isDateSingle(defaultValue as DateSingle) && defaultValue),
                },
                ...(defaultValue && isDateRange(defaultValue as DateRange) && defaultValue),
              })
            }
          />
          Range
        </label>
      )}
    </div>
  )
}

type BreakdownDateProps = {
  date: InProcessDateBreakdown
  onChange: (dateType: 'breakdown', date: InProcessDateBreakdown) => void
}
function BreakdownDate({ date, onChange }: BreakdownDateProps): JSX.Element {
  return <DateForm value={date && { year: 0, ...date }} setValue={(value) => onChange('breakdown', value)} />
}

type SingleDateProps = {
  date: InProcessDateSingle
  onChange: (dataType: 'single', date: InProcessDateSingle) => void
}
function SingleDate({ date, onChange }: SingleDateProps): JSX.Element {
  return (
    <DateForm
      value={date && { year: 0, ...date }}
      setValue={(value) => onChange('single', value && { type: 'single', ...value })}
    />
  )
}

type RangeDateProps = {
  date: InProcessDateRange
  onChange: (dataType: 'range', date: InProcessDateRange) => void
}
function RangeDate({ date, onChange }: RangeDateProps): JSX.Element {
  const start = date?.start && { year: 0, ...date.start }
  const end = date?.end && { year: 0, ...date.end }
  return (
    <div className="flex flex-row w-full items-center space-x-2">
      <DateForm
        label="Start"
        value={start}
        setValue={(value) => onChange('range', { type: 'range', end, start: value })}
      />
      <p className="hidden block:sm h-full text-sm text-gray-500">until</p>
      <DateForm label="End" value={end} setValue={(value) => onChange('range', { type: 'range', start, end: value })} />
    </div>
  )
}

function validateLifeDate(v: InProcessDate | undefined): string | undefined {
  if (v === undefined) return undefined
  if (isDateSingle(v as DateSingle) || isDateBreakdown(v as DateSingle)) {
    if (!isValidDate(v as DateSingle)) return 'Invalid date'
  } else {
    if (!isValidDate((v as DateRange).start)) return 'Invalid start date'
    if (!isValidDate((v as DateRange).end)) return 'Invalid end date'
    const compare = compareDates((v as DateRange).start, (v as DateRange).end)
    if (compare === 0) return 'Start and end dates should not be the same. Use a single date instead.'
    if (compare > 0) return 'Start date should be before the end date.'
  }
  return undefined
}
