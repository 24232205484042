import type { BookId } from '../book'
import type { PdfInfo } from '../pdf'
import { StoryId } from '../story'
import type { AddErrors, GetErrors, ResponseSuccess } from './common'

/**
 * /book/pdf/get
 */
export type BookPdfGetInput = {
  bookId: BookId
}
export type BookPdfGetSuccess = ResponseSuccess & {
  pdfInfo: PdfInfo
}
export type BookPdfGetOutput = BookPdfGetSuccess | GetErrors

/**
 * /book/pdf/create
 */
export type BookPdfCreateInput = {
  bookId: BookId
}
export type BookPdfCreateSuccess = ResponseSuccess & {
  pdfInfo: PdfInfo
}
export type BookPdfCreateOutput = BookPdfCreateSuccess | AddErrors

/**
 * /book/pdf/preview/url
 */
export type BookPdfPreviewUrlInput = {
  bookId: BookId
  storyId: StoryId
}
export type BookPdfPreviewUrlSuccess = ResponseSuccess & {
  /** The URI used to access the preview. Must be prefixed with API_HOST, e.g, `GET ${API_HOST}${uri}` */
  uri: string
}
export type BookPdfPreviewUrlOutput = BookPdfPreviewUrlSuccess | AddErrors

/**
 * /book/pdf/preview
 * To support the desired UX where the PDF is immediately loaded
 * into a new browser tab, this endpoint does not follow the normal
 * Input/Output protocol.
 * It accepts a POST in the normal fashion, but returns the PDF file
 * directly from the response, with content-type header indicating PDF.
 */
export type BookPdfPreviewInput = {
  bookId: BookId
  storyId: StoryId
}

export const BookPdfNotCreated = 'Book PDF has not been created yet'
