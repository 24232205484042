import {
  ButtonCancel,
  ButtonRemove,
  classNames,
  DeleteIcon,
  LifeDialog,
  LifeMenu,
  ActionMenuIcon,
  Tooltip,
  useErrorNotification,
} from '@life/components'
import { Person, useRemovePerson } from '@life/frontend-model'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { canEditBook } from '../common'

type Props = {
  person: Person
}
export function PersonMenu({ person }: Props): JSX.Element {
  const canEdit = canEditBook(person.book)
  const [confirmDelete, setConfirmDelete] = useState(false)

  if (!canEdit) return <></>
  const storyCount = person.storiesWithPerson.length

  function DeleteButton({ active }: { active: boolean }): JSX.Element {
    if (storyCount > 0)
      return (
        <Tooltip message="Remove person from stories first">
          <button className="block w-full text-left px-4 py-2 text-sm rounded-md text-gray-300">
            Delete (disabled)
          </button>
        </Tooltip>
      )
    return (
      <button
        className={classNames(
          'flex w-full items-center text-left px-4 py-2 text-sm rounded-md',
          active ? 'bg-red-500 text-white' : 'text-gray-700'
        )}
        onClick={() => setConfirmDelete(true)}
      >
        <DeleteIcon className="mr-2 h-5 w-5" aria-hidden="true" />
        Delete from Book
      </button>
    )
  }

  return (
    <>
      <LifeMenu>
        <LifeMenu.Button className="p-1 rounded-full">
          <ActionMenuIcon className="h-5 w-5 hover:text-gray-400" aria-hidden="true" />
        </LifeMenu.Button>
        <LifeMenu.Items className="w-64">
          <LifeMenu.Item>{DeleteButton}</LifeMenu.Item>
        </LifeMenu.Items>
      </LifeMenu>
      <ConfirmDialog person={person} isOpen={confirmDelete} onClose={() => setConfirmDelete(false)} />
    </>
  )
}

type ConfirmProps = {
  person: Person
  isOpen: boolean
  onClose: VoidFunction
}
function ConfirmDialog({ person, isOpen, onClose }: ConfirmProps): JSX.Element {
  const { isRemoving, remove } = useRemovePerson()
  const { showError } = useErrorNotification()
  const navigate = useNavigate()

  async function handleRemove(): Promise<void> {
    try {
      await remove(person)
      navigate('../people')
    } catch (error) {
      showError('Error Deleting Person', error)
    }
  }

  const shortcuts = { Escape: onClose }
  return (
    <LifeDialog modal title="Confirmation" isOpen={isOpen} shortcuts={shortcuts} onClose={onClose}>
      <LifeDialog.Content>Are you sure you want to remove this person from your book?</LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose} />
        <ButtonRemove confirm={false} clicked={isRemoving} onClick={handleRemove}>
          Delete From Book
        </ButtonRemove>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
