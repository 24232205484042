export function isEmpty<T>(val: T | undefined): val is undefined {
  if (val === undefined) return true
  if (val === null) return true
  if (typeof val === 'string') {
    if (val.trim().length === 0) return true
    return false
  }
  if (typeof val === 'number') {
    if (isNaN(val)) return true
    if (val === 0) return true
    return false
  }
  if (Array.isArray(val)) {
    return val.length === 0
  }
  if (typeof val === 'object') {
    try {
      return JSON.stringify(val) === '{}'
    } catch (e) {
      console.warn(e)
      console.warn(
        'Something went wrong while trying to stringify this object. ',
        'Since it might be a circular reference, ',
        'the object is at least not empty.'
      )
      return false
    }
  }
  throw new Error('isEmpty is not implemented for this type of object')
}

export function isNotEmpty<T>(val: T | undefined): val is T {
  return !isEmpty(val)
}

/** Compares 2 objects (deep compare). */
export function isEqual<T>(a: T | undefined, b: T | undefined): boolean {
  if (a === undefined && b === undefined) return true
  if (a === undefined || b === undefined) return false
  if (a === null && b === null) return true
  if (a === null || b === null) return false
  if (Object.keys(a).length !== Object.keys(b).length) return false
  return typeof a === 'object' ? Object.entries(a).every(([key, val]) => isEqual(b[key as keyof T], val)) : a === b
}

/** Useful predicates to reduce the amount of casting necessary when calling Array.filter */
export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined && argument !== null
}

export function isTrue(value: boolean | undefined): value is true {
  return value === true
}
