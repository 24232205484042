import { Book } from '@life/frontend-model'

type Props = {
  book: Book
}
export function BookInfo({ book }: Props): JSX.Element {
  return (
    <div className="flex flex-col space-y-2">
      <h1 className="text-xl font-bold text-gray-800">{book.title}</h1>
      {book.subtitle && <h2 className="text-lg italic text-gray-800">{book.subtitle}</h2>}
      {book.author && <h3 className="text-lg text-gray-800">Author: {book.author}</h3>}
      <h4 className="text-sm text-gray-800 pt-4">
        Copyright © {new Date().getFullYear()}&nbsp;&nbsp;&nbsp;{book.author ?? 'Unforgotten Life'}
      </h4>
    </div>
  )
}
