import { AuditProps } from './common'
import type { S3Image } from './image'
import type { Location } from './location'
import type { Person } from './person'
import type { Story, StoryId } from './story'
import type { CollaboratorRole } from './user'

export type BookId = string

export type UnsavedBook = {
  slug: string
  title: string
  subtitle?: string
  author?: string
  maxCollaborators?: number
}
export function validateBook(book: UnsavedBook): string | undefined {
  if (!book.title?.trim()) return 'Missing title'
  return undefined
}

export type Book = UnsavedBook &
  AuditProps & {
    bookId: BookId
    dbVersion?: number
  }

export type BookView = Book & {
  role?: CollaboratorRole
  lastAccessed?: number
}

export const MAX_TOC_LEVELS = 3

export type TableOfContents = {
  beforeToc?: StoryId[]
  afterToc?: StoryId[]
  chapters2?: StoryId[]
  appendix2?: StoryId[]
}

/**
 * @deprecated use beforeToc or afterToc
 */
export type BookSection = {
  storyId: StoryId
  sections: BookSection[] | undefined
}

export type FullBook = {
  info: Book
  collaborator: {
    role: CollaboratorRole
    lastAccessed?: number
  }
  content: {
    toc: TableOfContents
    stories: Story[]
    people: Person[]
    locations: Location[]
    images: S3Image[]
    // things: Things[]
  }
}
