import { BookOpenIcon } from '@heroicons/react/24/outline'
import { isEmpty } from '@life/model'
import { classNames } from '../classNames'
import { IconWithToolTip } from './IconWithToolTip'

type Props = {
  color: 'green' | 'red'
  className?: string
}
export function StoryIcon({ color, className }: Props): JSX.Element {
  return (
    <BookOpenIcon
      className={classNames(className, 'h-4', color === 'green' && 'text-green-700', color === 'red' && 'text-red-700')}
    />
  )
}

type WithToolTipProps = {
  count: number
}
export function StoryIconWithToolTip({ count }: WithToolTipProps): JSX.Element {
  return (
    <IconWithToolTip icon={<StoryIcon color={count > 0 ? 'green' : 'red'} />}>
      <div className="whitespace-nowrap">{XStoriesToEnglish(count)}</div>
    </IconWithToolTip>
  )
}

function XStoriesToEnglish(count: number): string {
  if (isEmpty(count)) return 'No stories'
  if (count === 1) return '1 story'
  return `${count} stories`
}
