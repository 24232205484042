import { PrinterIcon } from '@heroicons/react/24/outline'
import { Translate } from '@life/frontend-model'
import { StoryStatusType } from '@life/model'
import { IconWithToolTip } from './IconWithToolTip'

type StatusProps = {
  status: StoryStatusType
}
export function StatusIcon({ status }: StatusProps): JSX.Element {
  if (status === 'approved') return <PrinterIcon className="h-4 w-4 text-blue-400 fill-blue-200" />
  if (status === 'complete') return <ProgressIcon className="h-4 w-4 text-green-400 fill-green-200" percent={75} />
  if (status === 'draft') return <ProgressIcon className="h-4 w-4 text-yellow-400 fill-yellow-200" percent={50} />
  return <ProgressIcon className="h-4 w-4 text-red-400 fill-red-200" percent={25} />
}

export function StatusIconWithToolTip(props: StatusProps): JSX.Element {
  return (
    <IconWithToolTip icon={<StatusIcon {...props} />}>
      <div className="whitespace-nowrap">{Translate.StoryStatus(props.status)}</div>
    </IconWithToolTip>
  )
}

type Props = {
  percent: number
  className?: string
}

function ProgressIcon({ className, percent }: Props): JSX.Element {
  const radius = 9
  const radians = (Math.PI * (percent - 25)) / 50
  const width = 24
  const offset = width / 2 - radius
  const start = `M ${width / 2} ${width - offset}`
  const end = `L ${width / 2} ${width / 2} Z`
  const circleX = width / 2 - Math.cos(radians) * radius
  const circleY = width / 2 - Math.sin(radians) * radius
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox={`0 0 ${width} ${width}`}
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d={`${start} A ${radius} ${radius} 0 ${percent > 50 ? 1 : 0} 1 ${circleX} ${circleY} ${end}`}
      />
    </svg>
  )
}
