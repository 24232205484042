import { Book } from '@life/frontend-model'

export type Task = {
  id: string
  priority: number
  to: string
  label: string
  complete?: number
  total?: number
  explanation: string
}

export function getTasksForBook(book: Book): Task[] {
  const { people, stories, images } = book
  const tasks: Task[] = []
  if (people.length === 0) {
    tasks.push({
      id: 'first-person',
      priority: 10,
      to: 'people/_add',
      label: 'Add Your First Person',
      total: 1,
      explanation: 'Add whom your book is about. This is probably you.',
    })
  } else {
    // const personIdeas = people.filter((person) => person.status === 'stub')
    // const personDrafts = people.filter((person) => person.status === 'draft')
    if (people.length < 10) {
      tasks.push({
        id: 'more-people',
        priority: 31,
        to: 'people/_add',
        label: 'Add 10 or more people',
        complete: people.length,
        total: 10,
        explanation:
          'Add more people to your book. This will be useful for indexing your book, tagging photos, and adding hyperlinks to your website.',
      })
    } else {
      // TODO Find or Add a Photos for People missing Photos (Priority 90), stop after 10 people have photos
      // TODO Find or Write a story about People not in a Story (Priority 100), make sure everyone is in a story
    }
  }

  if (stories.length === 0) {
    tasks.push({
      id: 'first-story-idea',
      priority: 12,
      to: 'stories/_add',
      label: 'Add Your First Story Idea',
      total: 1,
      explanation:
        'Write down an idea of something to write about such as a scary childhood event or a funny teenage memory.',
    })
  } else {
    const storyStub = stories.filter((story) => story.status === 'stub')
    const storyDrafts = stories.filter((story) => story.status === 'draft')
    const storyCompletes = stories.filter((story) => story.status === 'complete')
    const storyApproved = stories.filter((story) => story.status === 'approved')

    if (stories.length < 10 && storyStub.length < 5) {
      const needed = 5 - storyStub.length
      tasks.push({
        id: 'story-ideas',
        priority: 30,
        to: 'stories/_add',
        label: 'Add 5 or more Story Ideas',
        complete: stories.length,
        total: stories.length + needed,
        explanation:
          'Keep the writing process going by having at least 5 story ideas to write about your life. Add just a title or include a short outline.',
      })
    }

    if (storyStub.length > 0 && storyDrafts.length < 5) {
      tasks.push({
        id: 'story-drafts',
        priority: 20,
        complete: storyDrafts.length,
        total: storyStub.length + storyDrafts.length,
        to: 'stories?filter=status:Idea',
        label: 'Write First Drafts for your Story Ideas',
        explanation: "Flesh out your story ideas into first drafts. Don't worry about adding photos or linking yet.",
      })
    }

    if (storyDrafts.length > 0) {
      tasks.push({
        id: 'story-completes',
        priority: 40,
        complete: storyCompletes.length + storyApproved.length,
        total: storyDrafts.length + storyCompletes.length + storyApproved.length,
        to: 'stories?filter=status:Draft',
        label: 'Complete your stories',
        explanation: 'Add Photos, tag People whom you mention, and fix any grammar mistakes.',
      })
    }

    if (storyCompletes.length > 0) {
      tasks.push({
        id: 'story-approved',
        priority: 100,
        complete: storyApproved.length,
        total: storyApproved.length + storyCompletes.length,
        to: 'stories?filter=status:Complete',
        label: 'Approve your stories for Publishing',
        explanation: 'This is the final step. Review each chapter in the book and approve them for publishing.',
      })
    }
  }

  if (images.length === 0) {
    tasks.push({
      id: 'first-image',
      priority: 12,
      total: 1,
      to: 'photos/_add',
      label: 'Upload Your First Photo',
      explanation: 'Find a great photo from your life and upload it.',
    })
  } else {
    if (images.length < 10) {
      tasks.push({
        id: 'more-images',
        priority: 32,
        to: 'photos/_add',
        label: 'Upload 10 or more Photos',
        complete: images.length,
        total: 10,
        explanation:
          'Add more photos to your book. Find important photos from your life on your phone or computer. Or scan old photos and upload them.',
      })
    }
  }

  tasks.sort((a, b) => a.priority - b.priority)
  return tasks
}
