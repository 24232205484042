import { BookId } from '../book'
import { Story, StoryDate, StoryId, StoryStatusType, UnsavedStory } from '../story'
import { AddErrors, RemoveErrors, ResponseSuccess, UpdateErrors } from './common'

/**
 * /story/add
 */
export type StoryAddInput = {
  story: UnsavedStory
}
export type StoryAddSuccess = ResponseSuccess & {
  story: Story
}
export type StoryAddOutput = StoryAddSuccess | AddErrors

/**
 * /story/update
 */
export type StoryUpdateAllInput = {
  story: Story
}
/** Request to update just one (or several) field(s). */
export type StoryUpdatePartialInput = {
  bookId: BookId
  storyId: StoryId
  title?: string
  status?: StoryStatusType
  occurred?: StoryDate | null // Set to null to remove 'occurred' from the Story
}
export type StoryUpdateInput = StoryUpdateAllInput | StoryUpdatePartialInput
export type StoryUpdateSuccess = ResponseSuccess & StoryUpdateAllInput
export type StoryUpdateOutput = StoryUpdateSuccess | UpdateErrors
export function isPartialUpdateRequest(request: StoryUpdateInput): request is StoryUpdatePartialInput {
  return !!(request as StoryUpdatePartialInput).bookId
}

/**
 * /story/remove
 */
export type StoryRemoveInput = {
  bookId: BookId
  storyId: StoryId
}
export type StoryRemoveSuccess = ResponseSuccess & StoryRemoveInput
export type StoryRemoveOutput = StoryRemoveSuccess | RemoveErrors
