import {
  ButtonCancel,
  ButtonSave,
  classNames,
  DatesForm,
  EditIcon,
  dateToString,
  LifeDialog,
  useErrorNotification,
} from '@life/components'
import { Image, useUpdateImage } from '@life/frontend-model'
import { DateBreakdown } from '@life/model'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { canEditBook } from '../../common'

type Props = {
  image: Image
}
export function ImageDate({ image }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const updater = useUpdateImage()
  const { showError } = useErrorNotification()
  const canEdit = canEditBook(image.book)

  async function save(image: Image): Promise<void> {
    try {
      await updater.update(image)
    } catch (error) {
      showError('Error Updating Image', error)
    }
    setIsOpen(false)
  }

  return (
    <>
      <div
        className={classNames('flex flex-row text-lg sm:pt-2 cursor-pointer', image.when ? '' : 'text-gray-500 italic')}
        onClick={() => {
          if (!canEdit) return
          if (!image.when) image.when = { year: 0 }
          setIsOpen(true)
        }}
      >
        {image.when ? `Photo Date: ${dateToString(image.when)}` : 'Photo Date'}
        <EditIcon className="ml-2 mt-1 h-4 w-4" aria-hidden="true" />
      </div>
      <MetadataDialog
        key={String(isOpen)}
        isOpen={isOpen}
        image={image}
        saving={updater.isLoading}
        onSave={save}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

type MetadataProps = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  image: Image
  isOpen: boolean
  saving: boolean
  onSave: (image: Image) => void
  onClose: () => void
}
function MetadataDialog({ image, isOpen, saving, onSave, onClose }: MetadataProps): JSX.Element {
  type FormFields = { when: DateBreakdown }
  const [whenError, setWhenError] = useState<string>()
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormFields>({
    defaultValues: { when: image.when },
  })

  function handleSave(fields: FormFields): void {
    image.when = fields.when
    onSave(image)
  }

  const shortcuts = { Escape: onClose, Enter: () => handleSubmit(handleSave)() }
  return (
    <LifeDialog modal title="Edit Photo Date" isOpen={isOpen} onClose={onClose} shortcuts={shortcuts}>
      <form onSubmit={handleSubmit(handleSave)}>
        <LifeDialog.Content>
          <Controller
            control={control}
            name="when"
            render={({ field: { onChange, value } }) => (
              <DatesForm
                types={['breakdown']}
                optional
                defaultValue={value}
                onChange={(value, error) => {
                  onChange(value)
                  setWhenError(error)
                }}
              />
            )}
          />
          {whenError && <div className="mt-1 pl-3 text-sm text-red-500">{whenError}</div>}
        </LifeDialog.Content>
        <LifeDialog.Actions>
          <ButtonCancel onClick={onClose} />
          <ButtonSave disabled={!isDirty || !!whenError} clicked={saving} />
        </LifeDialog.Actions>
      </form>
    </LifeDialog>
  )
}
