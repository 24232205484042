import { WithChildren } from '@life/components'
import { Logger } from '@life/model'
import { Component, ErrorInfo } from 'react'

const logger = new Logger('errorboundary')

type Props = WithChildren
type State = {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    logger.error('componentDidCatch', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>An error occurred while rendering this page.</h1>
          <a href="/">Back to Home</a>
        </div>
      )
    }

    return this.props.children
  }
}
