import { HelpIcon, HelpText } from '@life/components'
import { useState } from 'react'

type Props = {
  title: string
  help: string
  showHelp?: boolean
}

export function GroupHeading({ title, help, showHelp: showHelpDefault = true }: Props): JSX.Element {
  const [showHelp, setShowHelp] = useState(showHelpDefault)
  
  return (
    <div className="pb-3">
      <h2 className="text-xl font-medium flex items-center justify-between">
        {title}
        <span onClick={() => setShowHelp(!showHelp)}>
          <HelpIcon className="h-5 cursor-pointer text-gray-600 hover:text-green-600" />
        </span>
      </h2>
      {showHelp && <HelpText>{help}</HelpText>}
    </div>
  )
}
