import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

function Root() {
  const rootElement = document.getElementById('root')
  if (!rootElement) throw new Error('Failed to find root')
  return ReactDOM.createRoot(rootElement)
}

Root().render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
