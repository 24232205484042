import { isEqual } from '@life/model'
import { useEffect } from 'react'
import { DefaultValues, FieldValues, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { usePrevious } from '../usePrevious'

type Props<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>
  onSubmit: SubmitHandler<TFieldValues>
  children: React.ReactNode
}

// TODO Support wider forms like these https://tailwindui.com/components/application-ui/forms/form-layouts

export function Form<TFieldValues extends FieldValues>({ children, onSubmit, form }: Props<TFieldValues>) {
  return <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
}

export function useLifeForm<TFieldValues extends FieldValues>(
  defaultValues: DefaultValues<TFieldValues> | undefined
): UseFormReturn<TFieldValues> {
  const form = useForm<TFieldValues>({
    defaultValues,
  })
  // We need `defaultValues` to be stable in the following useEffect call.
  // However, we can't be certain that `defaultValues` provided by the caller
  // will be stable. (For example, `useLifeForm({ a: 'foo' }))` changes on each call.)
  // So, we will retain the previous value of `defaultValues` and in useEffect
  // check to see if it has changed before making any updates.
  const prevDefaults = usePrevious(defaultValues)
  useEffect(() => {
    if (!isEqual(defaultValues, prevDefaults)) {
      form.reset(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't use prevDefaults, it may not be stable
  }, [form, defaultValues])
  return form
}
