import { useAuth0 } from '@auth0/auth0-react'
import {
  ButtonCancel,
  ButtonSave,
  Input,
  NotReady,
  PageHeading,
  PagePadding,
  useErrorNotification,
  useLifeForm,
} from '@life/components'
import { User, useUpdateUser, useUser } from '@life/frontend-model'

export function ProfileController() {
  const { user, isLoading, error } = useUser()
  const auth = useAuth0()
  const userUpdater = useUpdateUser()
  const { showError } = useErrorNotification()
  const form = useLifeForm(user)
  // End of Hooks

  if (isLoading || error || !user) return <NotReady type="User" isLoading={isLoading} error={error} />

  async function update(user: User): Promise<void> {
    try {
      await userUpdater.update(user)
    } catch (error) {
      showError('Error Updating User', error)
    }
  }

  const { register, formState } = form

  return (
    <PagePadding className="space-y-8 divide-y divide-gray-200">
      <div>
        <PageHeading title="Profile">Edit Profile</PageHeading>
      </div>

      <form onSubmit={form.handleSubmit(update)}>
        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <Input
            {...register('givenNames', {
              required: {
                value: true,
                message: 'First name is required',
              },
            })}
            label="First name"
            placeholder="First name"
            help="Your first name or preferred name"
            autoComplete="given-name"
            error={formState.errors.givenNames}
          />
          <Input
            {...register('lastName', {
              required: {
                value: true,
                message: 'Last name is required',
              },
            })}
            label="Last name"
            placeholder="Last name"
            help="Your last name or family name"
            autoComplete="last-name"
            error={formState.errors.lastName}
          />
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Email
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2" id="email">
              <p className="mt-2 text-sm text-gray-800">{user.email}</p>
              <p className="mt-2 text-sm text-gray-500">We don&apos;t support changing email address at this time.</p>
            </div>
          </div>
          {user.memberSince && (
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="memberSince" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Member Since
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2" id="memberSince">
                <p className="mt-2 text-sm text-gray-800">{new Date(user.memberSince).toLocaleDateString()}</p>
              </div>
            </div>
          )}
          <div className="py-4">
            <div className="flex justify-between">
              <ButtonCancel
                onClick={() => {
                  auth.logout({
                    returnTo: window.location.origin,
                  })
                }}
              >
                Logout
              </ButtonCancel>
              <ButtonSave clicked={userUpdater.isLoading}>Save</ButtonSave>
            </div>
          </div>
        </div>
      </form>
    </PagePadding>
  )
}
