import { RefreshIcon } from '../icons'
import { WithChildren } from '../types'
import { ButtonBase } from './ButtonBase'

type Props = {
  to?: string
  onClick?: VoidFunction
  clicked?: boolean
  disabled?: boolean | undefined
} & WithChildren

export function ButtonRefresh({ to, onClick, clicked, disabled, children }: Props) {
  return (
    <ButtonBase
      to={to}
      clicked={clicked}
      onClick={onClick}
      disabled={disabled}
      className="bg-slate-600 hover:bg-slate-700 focus:ring-slate-500"
      icon={<RefreshIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
    >
      {children || 'Refresh'}
    </ButtonBase>
  )
}
