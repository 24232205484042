import { ButtonCancel, ButtonSave, classNames, HelpText, LifeDialog } from '@life/components'
import { ChangeEvent, useState } from 'react'
import { FieldError } from 'react-hook-form'

type TitleDialogProps = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  isOpen: boolean
  title: string
  isSaving?: boolean
  onSave: (title: string) => void
  onClose: () => void
}
export function TitleDialog({ isOpen, title, isSaving, onSave, onClose }: TitleDialogProps): JSX.Element {
  const [value, setValue] = useState(title)
  const [error, setError] = useState<FieldError>()

  function handleChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    const val = event.target.value
    setValue(val)
    if (!val) setError({ type: 'required', message: 'A story title is required' })
    else if (val.length < 5) setError({ type: 'minLength', message: 'Titles should be at least 5 characters long' })
    else if (val.length > 40) setError({ type: 'maxLength', message: 'Please keep titles less than 40 characters' })
    else setError(undefined)
  }

  const shortcuts = { Escape: onClose, Enter: () => !error && onSave(value) }
  return (
    <LifeDialog modal isOpen={isOpen} title="Edit Story Title" onClose={onClose} shortcuts={shortcuts}>
      <LifeDialog.Content className="w-full sm:w-96">
        <textarea
          placeholder="Story Title"
          className={classNames(
            error
              ? 'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ring-red-400 border-red-300'
              : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
            'w-full h-24 shadow-sm text-xl rounded-md'
          )}
          value={value}
          onChange={handleChange}
        />
        <HelpText error={error}>
          A short title for this story. <b className="bold">Tip:</b> Don&apos;t overthink the title. It&apos;s almost
          always easier to write a good title after you&apos;re done writing.
        </HelpText>
      </LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose} />
        <ButtonSave clicked={isSaving} onClick={() => onSave(value)} disabled={!!error}>
          OK
        </ButtonSave>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
