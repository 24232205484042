import * as Model from '@life/model'

export class Collaborator {
  userId: Model.UserId
  bookId: Model.BookId
  role: Model.CollaboratorRole
  lastName?: string
  givenNames?: string
  lastAccessed?: number

  constructor(data: Model.Collaborator) {
    this.userId = data.userId
    this.bookId = data.bookId
    this.role = data.role
    this.lastName = data.lastName
    this.givenNames = data.givenNames
    this.lastAccessed = data.lastAccessed
  }

  get key(): string {
    return this.userId
  }

  get formalName(): string {
    const { givenNames, lastName } = this
    if (!givenNames && !lastName) return this.userId
    return `${givenNames ?? ''} ${lastName ?? ''}`.trim()
  }

  get formalNameLastFirst(): string {
    const { givenNames, lastName } = this
    return lastName + `, ${givenNames}`.trim()
  }

  toModel(): Model.Collaborator {
    return {
      userId: this.userId,
      bookId: this.bookId,
      role: this.role,
      lastName: this.lastName,
      givenNames: this.givenNames,
      lastAccessed: this.lastAccessed,
    }
  }
}
