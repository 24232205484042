import { useEffect } from 'react'

export function useInterval(delaySec: number, action: () => void): void {
  useEffect(() => {
    if (!delaySec) return
    const interval = setInterval(() => {
      action()
    }, delaySec * 1000)
    return () => clearInterval(interval)
  }, [delaySec, action])
}
