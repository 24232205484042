import { Popover } from '@headlessui/react'
import { Placement } from '@popperjs/core'
import { useState } from 'react'
import { usePopper } from 'react-popper'
import { WithChildren } from '../types'

type Props = WithChildren & {
  icon: JSX.Element
  placement?: Placement
}

export function IconWithToolTip({ children, icon, placement = 'left' }: Props): JSX.Element {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>()
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement })

  return (
    <Popover className="relative">
      <Popover.Button ref={setReferenceElement} className="hover:bg-gray-200 p-1 rounded-full">
        {icon}
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className="absolute z-10">
        <div className="p-2 m-1 bg-white border-2 border-gray-300 shadow-lg rounded-lg text-sm text-gray-600">
          {children}
        </div>
      </Popover.Panel>
    </Popover>
  )
}
