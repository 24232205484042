import { dateToString } from '@life/components'
import { Book, Location, Person, Image, Story, Translate } from '@life/frontend-model'
import { Logger } from '@life/model'
import Fuse from 'fuse.js'

const logger = new Logger('search')

type SearchDomain = 'story' | 'image' | 'person' | 'location'
type SearchKeys<T = unknown> = Fuse.FuseOptionKey<T>[]

export type SearchResult = {
  book: Book | undefined
  stories: Story[]
  images: Image[]
  people: Person[]
  locations: Location[]
}
export function search(book: Book | undefined, text: string | undefined): SearchResult {
  const LIMIT = 10
  const term = text ? text.trim() : undefined
  return {
    book,
    stories: searchStories(book?.stories, term, LIMIT),
    images: searchImages(book?.images, term, LIMIT),
    people: searchPeople(book?.people, term, LIMIT),
    locations: searchLocations(book?.locations, term, LIMIT),
  }
}

export function searchStories(
  stories: readonly Story[] | undefined,
  term: string | undefined,
  limit?: number
): Story[] {
  if (!stories || !term) return []
  const results = advancedSearch('story', stories, term, limit)
  if (results) return results
  return fuzzySearch(
    'story',
    stories,
    [
      { name: 'title', weight: 2 },
      { name: 'occurred', weight: 1.5, getFn: (s) => dateToString(s.occurred) },
      'content.text',
      'content.children.text',
      'content.children.children.text',
    ],
    term,
    limit
  )
}

export function searchImages(images: readonly Image[] | undefined, term: string | undefined, limit?: number): Image[] {
  if (!images || !term) return []
  const results = advancedSearch('image', images, term, limit)
  if (results) return results
  return fuzzySearch(
    'image',
    images,
    [
      'notes',
      'when.year',
      { name: 'name', weight: 0.2 },
      'who.lastName',
      'who.givenNames',
      'who.nickName',
      'who.otherLastNames',
      'stories.title',
    ],
    term,
    limit
  )
}

export function searchPeople(
  people: readonly Person[] | undefined,
  term: string | undefined,
  limit?: number
): Person[] {
  if (!people || !term) return []
  const results = advancedSearch('person', people, term, limit)
  if (results) return results
  return fuzzySearch(
    'person',
    people,
    [
      'lastName',
      'givenNames',
      'suffix',
      'nickName',
      'otherLastNames',
      { name: 'birthDate', getFn: (p) => dateToString(p.birthDate) },
      { name: 'deathDate', getFn: (p) => dateToString(p.deathDate) },
    ],
    term,
    limit
  )
}

export function searchLocations(
  locations: readonly Location[] | undefined,
  term: string | undefined,
  limit?: number
): Location[] {
  if (!locations || !term) return []
  const results = advancedSearch('location', locations, term, limit)
  if (results) return results
  return fuzzySearch('location', locations, ['place', 'description'], term, limit)
}

function fuzzySearch<T>(
  domain: SearchDomain,
  items: readonly T[],
  keys: SearchKeys<T>,
  term: string | Fuse.Expression,
  limit: number | undefined
): T[] {
  logger.verbose(`${domain} search for ${JSON.stringify(term)}`, items, keys)
  const fuse = new Fuse(items, {
    keys,
    ignoreLocation: true,
    includeScore: true,
    includeMatches: true,
    threshold: 0.3,
    minMatchCharLength: 2,
  })
  const result = fuse.search(term, limit ? { limit } : undefined)
  logger.info(domain, 'result', result)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- options force score to exist
  return result.map((r) => r.item)
}

type DomainKeys = Record<string, SearchKeys> // maps search phrase to keys
export const advancedKeys: Record<SearchDomain, DomainKeys> = {
  story: {
    status: [
      {
        name: 'status',
        getFn: (s) => Translate.StoryStatus((s as Story).status),
      },
    ],
    content: ['content.text', 'content.children.text', 'content.children.children.text'],
    people: [
      {
        name: 'people',
        getFn: (s) => String((s as Story).people.length),
      },
    ],
    photos: [
      {
        name: 'photos',
        getFn: (s) => String((s as Story).images.length),
      },
    ],
  },
  image: {
    people: [
      {
        name: 'people',
        getFn: (i) => String((i as Image).who.length),
      },
    ],
    stories: [
      {
        name: 'stories',
        getFn: (i) => String((i as Image).storiesWithImage.length),
      },
    ],
    date: [
      {
        name: 'date',
        getFn: (i) => String((i as Image).when?.year ?? 'none'),
      },
    ],
  },
  person: {
    photos: [
      {
        name: 'images',
        getFn: (p) => String((p as Person).imagesWithPerson.length),
      },
    ],
    stories: [
      {
        name: 'stories',
        getFn: (p) => String((p as Person).storiesWithPerson.length),
      },
    ],
  },
  location: {},
}

function advancedSearch<T>(
  domain: SearchDomain,
  items: readonly T[],
  term: string,
  limit: number | undefined
): T[] | undefined {
  const domainKeys = advancedKeys[domain]
  if (!domainKeys) return undefined
  const { keys, expression } = extractKeys(domainKeys, term)
  if (keys.length === 0) return undefined
  logger.info(domain, 'advanced', expression)
  return fuzzySearch(domain, items, keys, expression, limit)
}

// export for tests
export function extractKeys(domainKeys: DomainKeys, term: string): { keys: SearchKeys; expression: Fuse.Expression } {
  // To find search keys and terms, replace each 'key:' with TOKEN, then split on it.
  // The term for each key will be in the results at index + 1
  const TOKEN = '°'
  const phrases: string[] = []
  const keys: SearchKeys[] = []
  Object.entries(domainKeys).forEach(([phrase, searchKeys]) => {
    const keyTerm = phrase + ':'
    if (term.includes(keyTerm)) {
      // We can't use the phrase in the search, we have to use the key name
      const k = searchKeys[0]
      if (typeof k === 'string') phrases.push(k)
      else if (Array.isArray(k)) phrases.push(k[0])
      else phrases.push(k.name as string)
      keys.push(searchKeys)
      term = term.replace(keyTerm, TOKEN)
    }
  })
  const terms = term.split(TOKEN)
  const expression = phrases.reduce((exp, ph, index) => {
    exp[ph] = terms[index + 1].trim()
    return exp
  }, {} as { [key: string]: string })
  return { keys: keys.flat(), expression }
}
