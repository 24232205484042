import { classNames } from '../classNames'
import { CloseIcon } from '../icons'
import { ButtonBase, ButtonProps } from './ButtonBase'

export function ButtonCancel({ children = 'Cancel', className, ...props }: ButtonProps) {
  return (
    <ButtonBase
      type="button"
      className={classNames('bg-slate-600 hover:bg-slate-700 focus:ring-slate-500', className)}
      icon={<CloseIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
      {...props}
    >
      {children}
    </ButtonBase>
  )
}
