import { ExpandIcon } from '@life/components'
import { Story } from '@life/frontend-model'
import { Link } from 'react-router-dom'

type Props = {
  parent?: Story
  edit?: boolean
}

export function ParentStoryLink({ parent, edit = false }: Props): JSX.Element {
  if (!parent) return <></>
  return (
    <div className="pl-16">
      <div className="flex font-bold text-lg text-gray-400">
        {parent.parent && <StoryLink story={parent.parent} replace={edit} />}
        <StoryLink story={parent} replace={edit} />
      </div>
    </div>
  )
}

type LinkProps = {
  story: Story
  replace?: boolean
}

function StoryLink({ story, replace }: LinkProps): JSX.Element {
  return (
    <div className="flex">
      <Link to={story.link} replace={replace} className="hover:underline hover:text-cyan-500">
        {story.title}
      </Link>
      <ExpandIcon className="w-5 mx-1" />
    </div>
  )
}
