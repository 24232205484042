import { Tooltip } from '..'
import { classNames } from '../classNames'

export type ButtonProp = {
  onClick?: () => void
  selected?: boolean
  disabled?: boolean
  hidden?: boolean
  tooltip?: string
}

type FormatButtonProps = ButtonProp & {
  children: JSX.Element
}

export function FormatButton(props: FormatButtonProps): JSX.Element {
  if (props.hidden) return <></>
  return (
    <Tooltip message={props.tooltip} className="whitespace-nowrap">
      <div
        onMouseDown={() => {
          if (props.disabled) return
          props.onClick && props.onClick()
        }}
        className={classNames(
          props.disabled ? 'cursor-default' : 'cursor-pointer',
          props.disabled
            ? 'text-slate-300' // disabled
            : props.selected
            ? 'text-blue-700 bg-blue-200' // selected
            : 'hover:bg-slate-200 text-gray-700', // enabled
          'h-6 w-6 flex items-center justify-center rounded-md'
        )}
      >
        {props.children}
      </div>
    </Tooltip>
  )
}
