import { ButtonCancel, classNames, ExpandIcon, LifeDialog } from '@life/components'
import { Book, Story } from '@life/frontend-model'
import { StoryId } from '@life/model'
import { useState } from 'react'
import { IndexEntry, useIndexBuilder } from './IndexBuilder'
import { useScrollState } from './ScrollState'

type Props = {
  book: Book
}
export function BookIndex({ book }: Props): JSX.Element {
  const indexBuilder = useIndexBuilder()
  return (
    <div className="h-full overflow-y-auto">
      <IndexGroup book={book} title="People" entries={indexBuilder.findEntries('person')} />
      <IndexGroup book={book} title="Locations" entries={indexBuilder.findEntries('location')} />
      <IndexGroup book={book} title="Things" entries={indexBuilder.findEntries('thing')} />
      <IndexGroup book={book} title="Photos" entries={indexBuilder.findEntries('image')} />
    </div>
  )
}

type LinkProps = {
  entry: IndexEntry
  locationIndex: number
}
function IndexLink({ entry, locationIndex }: LinkProps): JSX.Element {
  const { scrollTo } = useScrollState()
  const { storyId, linkId } = entry.locations[locationIndex]
  return (
    <button onClick={() => scrollTo(storyId, linkId)} className=" whitespace-nowrap hover:underline">
      {entry.title}
    </button>
  )
}

type IndexGroupProps = {
  book: Book
  title: string
  entries: IndexEntry[]
}
function IndexGroup({ book, title, entries }: IndexGroupProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  if (entries.length === 0) return <></>

  return (
    <>
      <div
        onClick={() => setIsOpen((previous) => !previous)}
        className="flex items-center flex-1 space-x-2 pb-1 cursor-pointer"
      >
        <ExpandIcon
          className={classNames('h-4 w-4', isOpen ? 'transition-transform rotate-90' : 'transition-transform rotate-0')}
        />
        <h2 className="font-bold">{title}</h2>
      </div>
      {isOpen && <IndexList book={book} entries={entries} />}
    </>
  )
}

type IndexListProps = {
  book: Book
  entries: IndexEntry[]
}
function IndexList({ book, entries }: IndexListProps): JSX.Element {
  return (
    <div className="pl-3">
      {entries.map((entry, index) => (
        <IndexSingle key={index} book={book} entry={entry} />
      ))}
    </div>
  )
}

type IndexSingleProps = {
  book: Book
  entry: IndexEntry
}
function IndexSingle({ book, entry }: IndexSingleProps): JSX.Element {
  const [chooserIsOpen, setChooserIsOpen] = useState(false)

  return (
    <div className="pl-3">
      {entry.locations.length === 1 && <IndexLink entry={entry} locationIndex={0} />}
      {entry.locations.length > 1 && (
        <button onClick={() => setChooserIsOpen(true)} className={'flex-1 line-clamp-1 hover:underline'}>
          {entry.title}
        </button>
      )}
      {chooserIsOpen && (
        <IndexChooser isOpen={chooserIsOpen} book={book} entry={entry} onClose={() => setChooserIsOpen(false)} />
      )}
    </div>
  )
}

type IndexChooserProps = {
  isOpen: boolean
  book: Book
  entry: IndexEntry
  onClose: VoidFunction
}
function IndexChooser({ isOpen, book, entry, onClose }: IndexChooserProps): JSX.Element {
  function story(storyId: StoryId): Story | undefined {
    return book.findStory(storyId)
  }
  const shortcuts = { Escape: onClose }
  return (
    <LifeDialog title={`References to ${entry.title}`} isOpen={isOpen} shortcuts={shortcuts} onClose={onClose}>
      <LifeDialog.Content>
        {entry.locations.map((loc, index) => (
          <div key={loc.linkId} className="flex flex-row items-baseline max-w-prose">
            <IndexLink entry={entry} locationIndex={index} />
            <span className="pl-2 text-sm truncate">
              in &ldquo;{story(loc.storyId)?.title ?? 'Unknown Story'}&rdquo;
            </span>
            {loc.context && loc.context !== entry.title && (
              <span className="pl-1 text-sm">(&ldquo;{loc.context}&rdquo;)</span>
            )}
          </div>
        ))}
      </LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose}>Close</ButtonCancel>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
