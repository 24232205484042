import { Outlet } from 'react-router-dom'
import { LeftNav } from './LeftNav'
import { TopNav } from './TopNav'

export function LayoutTopNav(): JSX.Element {
  return (
    <div id="LayoutTopNav" className="flex flex-col h-full">
      <TopNav />
      <div className="flex flex-row h-full overflow-hidden">
        <LeftNav />
        <main className="flex-1 w-full h-full overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </div>
  )
}
