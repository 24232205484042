import { Menu, Transition } from '@headlessui/react'
import { Fragment, ReactNode } from 'react'
import { classNames } from './classNames'

type Props = {
  className?: string
  children?: ReactNode
}
export function LifeMenu({ className, children }: Props): JSX.Element {
  return (
    <Menu as="div" className={classNames('relative', className)}>
      {children}
    </Menu>
  )
}

type ButtonProps = {
  className?: string
  children?: ReactNode
}
function MenuButton({ className, children }: ButtonProps): JSX.Element {
  return (
    <Menu.Button
      className={classNames(
        'flex items-center w-full rounded-md bg-white hover:bg-slate-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500',
        className
      )}
    >
      {children}
    </Menu.Button>
  )
}
LifeMenu.Button = MenuButton

type ItemsProps = {
  className?: string
  children?: ReactNode
}
function MenuItems({ className, children }: ItemsProps): JSX.Element {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={classNames(
          'absolute right-0 mt-1 z-[100] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5',
          className
        )}
      >
        <div className="px-1 py-1">{children}</div>
      </Menu.Items>
    </Transition>
  )
}
LifeMenu.Items = MenuItems

type ItemProps = {
  disabled?: boolean
  children?: ReactNode | ((props: { active: boolean; disabled: boolean }) => JSX.Element)
}
function MenuItem({ disabled, children }: ItemProps): JSX.Element {
  return <Menu.Item disabled={disabled}>{children}</Menu.Item>
}
LifeMenu.Item = MenuItem
