import { classNames } from './classNames'

type SpinnerProps = {
  dark?: true
  /**
   * 1 = smallest, 4 = largest
   */
  size?: number
  className?: string
}

export function Spinner({ dark, size, className }: SpinnerProps) {
  size = size || 1
  return (
    <svg
      className={classNames(
        'animate-spin -ml-1 mr-3',
        className || (dark ? 'text-black' : 'text-white'),
        size === 1 ? 'h-5 w-5' : '',
        size === 2 ? 'h-10 w-10' : '',
        size === 3 ? 'h-14 w-14' : '',
        size === 4 ? 'h-20 w-20' : ''
      )}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      aria-label="waiting for server response"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}

type ProgressProps = {
  progress: number
  className?: string
}
export function ProgressCircle({ progress, className }: ProgressProps): JSX.Element {
  return (
    <svg viewBox="0 0 60 60" aria-hidden="true" className={classNames('stroke-green-600', className)}>
      <circle className="opacity-25" cx="30" cy="30" r="26" stroke="currentColor" fill="none" strokeWidth="8"></circle>
      <path
        strokeDasharray={progress + ' ' + (100 - progress)}
        strokeDashoffset="-25"
        pathLength="100"
        d="M5,30a25,25,0,1,1,50,0a25,25,0,1,1,-50,0"
        fill="none"
        strokeWidth="10"
      />
    </svg>
  )
}
