import {
  age,
  ageToString,
  ButtonCancel,
  ButtonSave,
  compareDates,
  dateToString,
  HelpText,
  LifeDialog,
} from '@life/components'
import { Book, Image } from '@life/frontend-model'
import { ImageId, StoryImage } from '@life/model'
import { useState } from 'react'
import { ImageList } from '../../image'
import { getDefaultCaption } from './caption-cache'
import { PartialStoryImage } from './utils'

type Props = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  book: Book
  image: StoryImage | undefined
  addImage: (image: PartialStoryImage) => void
  saveImage: (image: PartialStoryImage) => void
  isOpen: boolean
  onClose: () => void
}
export function ImageDialog({ isOpen, onClose, book, image, addImage, saveImage }: Props): JSX.Element {
  const [img, setImg] = useState<PartialStoryImage>(image ?? { caption: '', size: 'small' })
  const [isDirty, setIsDirty] = useState(false)

  const mode = image ? 'update' : 'insert'

  function handleChoose(image: Image): void {
    setImg((i) => ({
      ...i,
      id: image.imageId,
      caption: mode === 'update' ? i.caption : getDefaultCaption(i, image),
    }))
    setIsDirty(true)
  }
  function handleChange(value: PartialStoryImage): void {
    setImg((i) => ({ ...i, ...value }))
    setIsDirty(true)
  }
  function handleSave(): void {
    if (mode === 'insert') addImage(img)
    else saveImage(img)
  }
  const shortcuts = { Escape: onClose, Enter: handleSave }
  return (
    <LifeDialog
      modal
      isOpen={isOpen}
      title={mode === 'insert' ? 'Insert Photo' : 'Edit Caption'}
      onClose={onClose}
      shortcuts={shortcuts}
    >
      <LifeDialog.Content>
        {img.id ? (
          <EditImage book={book} image={img} onChange={handleChange} />
        ) : (
          <ImageList mode="choose" book={book} images={book.images} onChoose={handleChoose} />
        )}
      </LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose} />
        <ButtonSave type="button" disabled={!img.id || !isDirty} onClick={handleSave}>
          OK
        </ButtonSave>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}

type EditProps = {
  book: Book
  image: PartialStoryImage
  onChange: (value: PartialStoryImage) => void
}
function EditImage({ book, image, onChange }: EditProps): JSX.Element {
  return (
    <div className="w-full sm:w-96 px-1">
      {<SelectedImage imageId={image.id} book={book} onChange={onChange} />}
      <textarea
        placeholder="Optional Caption"
        value={image.caption}
        onChange={(e) => onChange({ caption: e.target.value })}
        className="w-full h-32 rounded shadow-inner"
      />
      <HelpText>Tip: Keep your captions short and let the story include longer details.</HelpText>
    </div>
  )
}

type PhotoDetailProps = {
  book: Book
  imageId?: ImageId
  onChange: (value: PartialStoryImage) => void
}
function SelectedImage({ imageId, book, onChange }: PhotoDetailProps): JSX.Element {
  const image = book.findImage(imageId)
  if (!image) return <></>

  const caption = buildDefaultCaption(book, image)
  if (!caption) return <></>

  return (
    <div className="flex space-x-12 items-start">
      <div
        className="w-2/3 text-sm text-gray-600 cursor-pointer hover:bg-gray-100"
        onClick={() => {
          onChange({ caption })
        }}
      >
        <div>{caption}</div>
        <div className="text-center text-xs mt-2">Use this Caption</div>
      </div>
    </div>
  )
}

function buildDefaultCaption(book: Book, image: Image): string {
  let caption = ''
  const when = dateToString(image.when)
  if (when) caption = when

  const peopleInImage = [...image.who]
  if (peopleInImage.length > 0) {
    if (caption) caption += ' - '
    peopleInImage.sort((a, b) => {
      const compareBirth = compareDates(a.birthDate, b.birthDate)
      if (compareBirth != 0) return compareBirth
      const compareGiven = a.givenNames.localeCompare(b.givenNames)
      if (compareGiven != 0) return compareGiven
      const compareLast = a.lastName.localeCompare(b.lastName)
      if (compareLast != 0) return compareLast
      return 0
    })
    peopleInImage.forEach((person, index) => {
      if (index > 0) caption += ', '
      caption += person.formalName
      const ageInPhoto = ageToString(age(person.birthDate, image.when))
      if (ageInPhoto) caption += ` (${ageInPhoto})`
    })
  }
  return caption
}
