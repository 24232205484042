import { ButtonAdd, ButtonCancel, LifeDialog } from '@life/components'
import { Book, Person } from '@life/frontend-model'
import { useState } from 'react'
import { AddPersonDialog, PersonList } from '../../person'
import { searchPeople } from '../../search'

type Props = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  book: Book
  searchText?: string
  addLink: (person: Person) => void
  isOpen: boolean
  onClose: () => void
}
export function PersonLinkDialog({ isOpen, onClose, book, searchText, addLink }: Props): JSX.Element {
  const [addIsOpen, setAddIsOpen] = useState(false)

  if (!isOpen) return <></>

  let initialFilter = searchText
  if (searchText) {
    // If the text doesn't match anyone, don't use it as a filter
    const found = searchPeople(book.people, searchText, 1)
    if (found.length === 0) initialFilter = undefined
  }

  const shortcuts = { Escape: onClose }
  return (
    <>
      <LifeDialog modal isOpen={isOpen} title="Add Link to Person" onClose={onClose} shortcuts={shortcuts}>
        <LifeDialog.Content className="px-1 max-w-3xl">
          <PersonList mode="choose" book={book} people={book.people} initialFilter={initialFilter} onChoose={addLink} />
        </LifeDialog.Content>
        <LifeDialog.Actions>
          <div className="flex gap-4">
            <ButtonAdd onClick={() => setAddIsOpen(true)}>Add Person</ButtonAdd>
          </div>
          <ButtonCancel onClick={onClose} />
        </LifeDialog.Actions>
      </LifeDialog>
      <AddPersonDialog
        key={String(addIsOpen)}
        isOpen={addIsOpen}
        book={book}
        name={searchText}
        onAdd={(person) => {
          addLink(person)
          setAddIsOpen(false)
        }}
        onClose={() => setAddIsOpen(false)}
      />
    </>
  )
}
