import { classNames } from '../classNames'
import { DropDownIcon } from '../icons'
import { LifeMenu } from '../LifeMenu'
import { SortDef } from './ItemList'

type MenuProps<S extends string> = {
  title: string
  items: SortDef<S>[]
  onClick: (sortDef: SortDef<S>) => void
}
export function DropdownMenu<S extends string>({ title, items, onClick }: MenuProps<S>): JSX.Element {
  return (
    <LifeMenu className="inline-block text-left">
      <LifeMenu.Button className="px-4 py-3 bg-slate-50 hover:bg-slate-200 text-sm font-medium text-slate-700">
        <span className="pr-1 whitespace-nowrap">Sort By:</span>
        <span>{title}</span>
        <DropDownIcon className="-mr-1 ml-1 h-5 w-5" aria-hidden="true" />
      </LifeMenu.Button>

      <LifeMenu.Items>
        <div className="py-1">
          {items.map((item) => (
            <LifeMenu.Item key={String(item.sortType)}>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() => onClick(item)}
                  className={classNames(
                    'block w-full text-left px-4 py-2 rounded',
                    active ? 'bg-slate-100 text-gray-900' : 'text-gray-700'
                  )}
                >
                  {item.description}
                </button>
              )}
            </LifeMenu.Item>
          ))}
        </div>
      </LifeMenu.Items>
    </LifeMenu>
  )
}
