import { useState } from 'react'
import { classNames } from '../classNames'
import { CloseIcon, DeleteIcon } from '../icons'
import { Tooltip } from '../Tooltip'
import { WithChildren } from '../types'
import { ButtonBase } from './ButtonBase'

type Props = {
  clicked?: boolean
  confirm?: boolean
  disabled?: boolean
  canEdit?: boolean
  onClick?: VoidFunction | (() => Promise<void>)
} & WithChildren
export function ButtonRemove({ clicked, onClick, confirm, disabled, canEdit = true, children, className }: Props) {
  const [isConfirming, setConfirm] = useState(false)
  return (
    <Tooltip
      message={canEdit ? '' : 'No Edit permission'}
      className={classNames(className, 'bg-slate-100 inline-flex items-center space-x-2')}
    >
      <div className="flex space-x-2">
        <ButtonBase
          onClick={() => {
            if (isConfirming || confirm === false) onClick && onClick()
            else setConfirm(true)
          }}
          clicked={clicked}
          disabled={disabled ?? !canEdit}
          className="bg-red-600 hover:bg-red-700 focus:ring-red-500"
          icon={<DeleteIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
        >
          {isConfirming ? 'Confirm' : children || 'Delete'}
        </ButtonBase>
        {isConfirming && (
          <ButtonBase
            onClick={() => setConfirm(false)}
            className="bg-gray-600 hover:bg-gray-700 focus:ring-gray-500"
            icon={<CloseIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
          >
            Nevermind
          </ButtonBase>
        )}
      </div>
    </Tooltip>
  )
}
