import * as Model from '@life/model'

export class User {
  email: string
  agreeToTerms?: number
  userId: Model.UserId
  lastName?: string
  givenNames?: string
  memberSince?: number
  lastAccessed?: number

  constructor(data: Model.User) {
    this.email = data.email
    this.agreeToTerms = data.agreeToTerms
    this.userId = data.userId
    this.lastName = data.lastName
    this.givenNames = data.givenNames
    this.memberSince = data.memberSince
    this.lastAccessed = data.lastAccessed
  }

  get key(): string {
    return this.userId
  }

  get formalName(): string {
    const { givenNames, lastName } = this
    return `${givenNames} ${lastName}`.trim()
  }

  get formalNameLastFirst(): string {
    const { givenNames, lastName } = this
    return lastName + `, ${givenNames}`.trim()
  }

  toModel(): Model.User {
    return {
      email: this.email,
      agreeToTerms: this.agreeToTerms,
      userId: this.userId,
      lastName: this.lastName,
      givenNames: this.givenNames,
      memberSince: this.memberSince,
      lastAccessed: this.lastAccessed,
    }
  }
}
