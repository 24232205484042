import { DateBreakdown, DateRange, DateSingle, LifeDate, LifeDateType } from '@life/model'

export function isDateSingle(date: LifeDate): date is DateSingle {
  return (date as DateSingle).type === 'single'
}
export function isDateRange(date: LifeDate): date is DateRange {
  return (date as DateRange).type === 'range'
}
export function isDateBreakdown(date: LifeDate): date is DateBreakdown {
  return !(date as DateSingle).type
}
export function getDateType(date: LifeDate | undefined): LifeDateType | undefined {
  if (!date) return undefined
  if ('type' in date) return date.type
  return 'breakdown'
}

export function dateToString(date: LifeDate | undefined): string {
  if (date === undefined) return ''

  if (isDateSingle(date)) {
    return dateObjToString(date)
  } else if (isDateRange(date)) {
    const { start, end } = date
    if (start === undefined) return 'before ' + dateObjToString(end)
    if (end === undefined) return 'after ' + dateObjToString(start)
    if (start === end) return dateObjToString(start)
    return dateObjToString(start) + ' to ' + dateObjToString(end)
  } else {
    return dateObjToString(date)
  }

  function dateObjWithoutAboutToString(obj: DateBreakdown): string {
    const { year, month, day } = obj
    if (!year) return '' // To support legacy dates
    if (!month) return '' + year
    if (!day) return months[month] + ' ' + year
    return day + ' ' + months[month] + ' ' + year
  }
  function dateObjToString(obj: DateBreakdown): string {
    return (obj.around ? '~' : '') + dateObjWithoutAboutToString(obj)
  }
}

export function lifeSpan(birthDate: DateBreakdown | undefined, deathDate: DateBreakdown | undefined): string {
  function year(d: DateBreakdown): string {
    return d.around ? `~${d.year}` : d.year.toString()
  }
  if (birthDate && deathDate) return `${year(birthDate)} to ${year(deathDate)}`
  if (birthDate) return `b.${year(birthDate)}`
  if (deathDate) return `d.${year(deathDate)}`
  return ''
}

export function monthToString(month: number | undefined): string {
  if (!month) return ''
  return months[month] ?? ''
}

export function stringToMonth(str: string | undefined): number | undefined {
  if (!str) return undefined
  const num = Number.parseInt(str)
  if (isValidMonth(num)) return num
  const month = months.indexOf(str)
  if (month > 0) return month
  const index = months.findIndex((m) => m.toLocaleLowerCase().startsWith(str.toLocaleLowerCase()))
  if (index > 0) return index
  return undefined
}

const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function isValidDate(date: DateBreakdown): boolean {
  if (!isValidYear(date.year)) return false
  if (!isValidMonth(date.month)) return false
  if (!isValidDay(date)) return false
  return true
}

export function isValidYear(year: number): boolean {
  // Although dates in the future are valid, this is a HISTORY application, so all dates should be in the past
  if (isFuture(year)) return false
  return year > 0
}

export function isFuture(year: number): boolean {
  if (isNaN(year)) return false
  const currentYear = new Date().getFullYear()
  return year > currentYear
}

export function isValidMonth(month: number | undefined): boolean {
  if (month === undefined) return true
  if (isNaN(month)) return false
  if (month < 1) return false
  if (month > 12) return false
  return true
}

export function isValidDay(date: DateBreakdown): boolean {
  // Assume year and month have already been validated
  const { year, month, day } = date
  if (day !== undefined && (isNaN(day) || day <= 0)) return false
  if (!month && !day) return true
  if (!month && day) return false
  if (month && !day) return true
  if (month && day) {
    // Has both day and month, make sure day is valid for month
    const dateObj = new Date()
    dateObj.setHours(0, 0, 0, 0)
    dateObj.setFullYear(year, month - 1, day)
    if (dateObj.getFullYear() !== year) return false
    if (dateObj.getMonth() !== month - 1) return false
    if (dateObj.getDate() !== day) return false
  }
  return true
}

export function compareDates(a: DateBreakdown | undefined, b: DateBreakdown | undefined): number {
  if (a === b) return 0
  if (!a) return -1
  if (!b) return 1
  if (a.year !== b.year) return a.year - b.year
  if (!a.month && !b.month) return 0
  if (!a.month) return 1
  if (!b.month) return -1
  if (a.month != b.month) return a.month - b.month
  if (!a.day && !b.day) return 0
  if (!a.day) return 1
  if (!b.day) return -1
  return a.day - b.day
}

export function compareDatesWithRanges(
  a: DateSingle | DateRange | undefined,
  b: DateSingle | DateRange | undefined,
  direction: 'ASC' | 'DESC' = 'ASC'
): number {
  if (direction === 'ASC') {
    const first = a?.type === 'range' ? a.start || a.end : a
    const second = b?.type === 'range' ? b.start || b.end : b
    return compareDates(first, second)
  } else {
    const first = a?.type === 'range' ? a.end || a.start : a
    const second = b?.type === 'range' ? b.end || b.start : b
    return compareDates(second, first)
  }
}

export function age(birthDate: undefined, currentDate: undefined): undefined
export function age(birthDate: DateBreakdown, currentDate: undefined): undefined
export function age(birthDate: undefined, currentDate: DateBreakdown): undefined
export function age(birthDate: DateBreakdown, currentDate: DateBreakdown): Age
export function age(birthDate: DateBreakdown | undefined, currentDate: DateBreakdown | undefined): Age | undefined
export function age(birthDate: DateBreakdown | undefined, currentDate: DateBreakdown | undefined): Age | undefined {
  if (!birthDate) return undefined
  if (!currentDate) return undefined

  const birth = getDate(birthDate)
  const current = getDate(currentDate)

  const days = (current.getTime() - birth.getTime()) / (1000 * 60 * 60 * 24)
  const approximate = isApproximate(birthDate) || isApproximate(currentDate)
  if (360 > days && days > -360)
    return {
      amount: days / (365.25 / 12),
      unit: 'MONTH',
      approximate,
    }
  return {
    amount: days / 365.25,
    unit: 'YEAR',
    approximate,
  }
}

export type Age = {
  amount: number
  unit: 'YEAR' | 'MONTH' | 'DAY'
  accuracy?: 'YEAR' | 'MONTH' | 'DAY'
  approximate?: boolean
}

function isApproximate(date: DateBreakdown): boolean {
  return date.around === true
}

function getDate(input: DateBreakdown): Date {
  const date = new Date()
  // Calculate year/month/day upfront so they can all be set at once.
  // This makes setting the date be independent of the current date/time.
  const year = input.year
  let month = input.month && input.month - 1
  let day = input.day

  if (month && !day) {
    day = 15 // Assume the middle of the month
  } else if (!month && !day) {
    // Assume the middle of the year
    month = 5
    day = 1
  }
  date.setHours(0, 0, 0, 0)
  date.setFullYear(year, month, day)
  return date
}

export function ageToString(age?: Age): string {
  if (!age) return ''

  const { amount, unit, approximate } = age

  function getUnit() {
    if (Math.abs(amount) === 1) {
      if (unit === 'YEAR') return 'year'
      if (unit === 'MONTH') return 'month'
      if (unit === 'DAY') return 'day'
    }
    if (unit === 'YEAR') return 'years'
    if (unit === 'MONTH') return 'months'
    if (unit === 'DAY') return 'days'
    throw new Error('unit missing')
  }

  function getAmount() {
    return Math.floor(amount)
  }

  if (amount < 0) {
    return (approximate ? '~' : '') + -1 * getAmount() + ' ' + getUnit() + ' before birth'
  }

  return (approximate ? '~' : '') + getAmount() + ' ' + getUnit() + ' old'
}
