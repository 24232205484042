import { Transition } from '@headlessui/react'
import { classNames, Spinner, useOptionalParam } from '@life/components'
import { useBook, useUser } from '@life/frontend-model'
import { Slug } from '@life/model'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { buildBookMenu, buildGeneralMenu, ItemLink } from './buildMenu'

export function LeftNav(): JSX.Element {
  const { user } = useUser()
  const bookSlug = useOptionalParam('bookSlug')
  const navigation = buildGeneralMenu(user)
  const [open, setOpen] = useState(true)

  return (
    <div id="LeftNav" className="hidden sm:block h-full">
      {!open && (
        <div
          className="bg-gray-100 hover:bg-gray-300 border-gray-200 hover:border-gray-400 cursor-pointer h-full w-4 absolute left-0 border-r-4"
          onClick={() => {
            setOpen(true)
          }}
        />
      )}
      <Transition
        className="h-full"
        show={open}
        enter="transition ease-in-out duration-200 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="flex flex-row items-stretch h-full">
          <div className={classNames('flex-col w-48 flex h-full bg-gray-100')}>
            <div className="flex-1 flex flex-col py-3 overflow-y-auto">
              {bookSlug && <BookMenu bookSlug={bookSlug} />}
            </div>
            <div className="flex-shrink-0 h-fit border-t border-gray-200 px-2">
              {navigation.map((item) => (
                <SideLink key={item.name} item={item} />
              ))}
            </div>
          </div>
          <div
            className="bg-gray-100 hover:bg-gray-200 w-2 border-r-4 border-gray-200 cursor-pointer"
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
      </Transition>
    </div>
  )
}

type BookMenuType = {
  bookSlug: Slug
}

function BookMenu({ bookSlug }: BookMenuType): JSX.Element {
  const { book, error, isLoading } = useBook(bookSlug)

  if (isLoading)
    return (
      <div className="flex flex-col items-center py-5">
        <Spinner size={2} className="text-gray-500" />
        <div className="text-gray-500 pt-2">Loading Book</div>
      </div>
    )
  if (error)
    return (
      <div className="flex flex-col items-center py-5">
        <div className="text-red-500 pt-2">Failed to load book</div>
      </div>
    )

  const navigation = buildBookMenu(book)
  return (
    <div className="px-2 space-y-1">
      {navigation.map((item) => (
        <SideLink key={item.name} item={item} />
      ))}
    </div>
  )
}

type SideLinkProp = {
  item: ItemLink
}

function SideLink({ item }: SideLinkProp): JSX.Element {
  return (
    <Link
      to={item.href}
      className={classNames(
        item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
        item.future ? 'line-through text-gray-400' : ''
      )}
    >
      <item.icon
        className={classNames(
          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
          'mr-3 h-6 w-6'
        )}
        aria-hidden="true"
      />
      {item.subText ? (
        <div>
          <p className="text-sm">{item.name}</p>
          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{item.subText}</p>
        </div>
      ) : (
        <p>{item.name}</p>
      )}
    </Link>
  )
}
