import { NotReady, PageHeading, PagePadding, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { ManageCollaborators } from './Collaborators'
import { Invites } from './Invites'

export function SharingController(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)

  if (isLoading || error || !book) return <NotReady type="Book" id="" isLoading={isLoading} error={error} />
  if (book.role !== 'Owner') return <div>Only the book Owner can share a book.</div>

  return (
    <PagePadding>
      <PageHeading title={['Book', book.title, 'Sharing']} className="flex-1">
        Share Your Book
      </PageHeading>
      <h1 className="text-lg font-bold text-gray-800 pt-4">Share and Collaborate with Others</h1>
      <p className="pb-2">
        You can share your book with others, or ask them to collaborate with you in creating the book. First invite
        others to your book, then after they accept the invitation choose what level of access you want to give them.
      </p>
      <p className="pb-2">
        To invite others, get an Invite Link. Send the link to them in whatever manner is easiest. When they accept the
        link they be able to View the book online. Use the &ldquo;Manage Collaborators&rdquo; section below to change
        their access.
      </p>
      <p className="pb-2">
        You can invite as many people as you&apos;d like. Set a Maximum to avoid sharing with more people than you had
        intended. Provide an expiration for your invitation to avoid misuse.
      </p>

      <h1 className="text-lg font-bold text-gray-800 pt-4">Get Invite Link</h1>
      <Invites book={book} className="pl-11" />

      <h1 className="text-lg font-bold text-gray-800 pt-4">Manage Collaborators</h1>
      <ManageCollaborators book={book} className="pl-11" />
    </PagePadding>
  )
}
