import { EditIcon } from '../icons'
import { Tooltip } from '../Tooltip'
import { WithChildren } from '../types'
import { ButtonBase } from './ButtonBase'

type Props = {
  to?: string
  onClick?: VoidFunction
  clicked?: boolean
  disabled?: boolean
  canEdit?: boolean
  icon?: JSX.Element
} & WithChildren
export function ButtonEdit({ to, onClick, clicked, disabled, canEdit = true, children, icon }: Props): JSX.Element {
  if (!canEdit) disabled = true

  return (
    <Tooltip message={canEdit ? '' : 'No Edit permission'}>
      <ButtonBase
        to={to}
        clicked={clicked}
        disabled={disabled}
        onClick={onClick}
        className="bg-slate-600 hover:bg-slate-700 focus:ring-slate-500"
        icon={icon || <EditIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
      >
        {children || 'Edit'}
      </ButtonBase>
    </Tooltip>
  )
}

type SmallProps = {
  onClick?: VoidFunction
}
export function ButtonEditSmall({ onClick }: SmallProps): JSX.Element {
  return (
    <button type="button" onClick={onClick} className="p-2 rounded-full cursor-pointer hover:text-gray-400">
      <EditIcon className="h-4 w-4 font-normal" />
    </button>
  )
}
