import { Disclosure } from '@headlessui/react'
// import { useOptionalParam } from '@life/components'
import { ButtonToggleMenu } from './ButtonToggleMenu'
import { Logo } from './Logo'
import { MobileMenu } from './MobileMenu'
// import { Search } from './Search'

export function TopNav(): JSX.Element {
  // const _bookSlug = useOptionalParam('bookSlug')

  return (
    <Disclosure as="nav" className="flex-1 flex-shrink-0 bg-indigo-600">
      {({ open, close }) => (
        <>
          <div className="mx-auto px-2">
            <div className="relative flex items-center justify-between h-16">
              <Logo close={close} />
              {/* {bookSlug && <Search bookSlug={bookSlug} />} */}

              <div className="flex md:hidden">
                <ButtonToggleMenu open={open} />
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden">
            <MobileMenu close={close} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
