type LevelInfo = {
  numericLevel: number
  prefix: string
}
const levels = {
  error: { numericLevel: 0, prefix: '❗️ ' },
  warn: { numericLevel: 1, prefix: '⚠️ ' },
  info: { numericLevel: 2, prefix: '' },
  timer: { numericLevel: 3, prefix: '⏱ ' },
  verbose: { numericLevel: 4, prefix: '❔ ' },
} as const
export type Level = keyof typeof levels & string

/**
 * The Logger provides a framework for writing messages to the console.
 * Modules that log each have their own Logger instance with a unique name.
 * Log levels can be set differently for each Logger instance.
 * It supports different levels and filters all messages that are lower priority than the current level.
 * See `levels` for information on what levels are available and their priority.
 *
 * As a debugging feature, when used in a browser, you can dynamically modify the current logging level
 * for module names by updating local storage (in the Dev Tools). Further, if local storage
 * has a logging level set and the Logger instance is not provided a log level in the
 * constructor, it will try to find it from local storage.
 *
 * The format for setting logging levels in local storage is: `key=${LOGGER_STORAGE_KEY}`
 * and value is a JSON object with `"name": ${level}` pairs.
 * As a convenience, in local storage you can use `*` in the key to set all
 * loggers matching the pattern. For example, `{"api*": "info"}` will set all loggers
 * whose name starts with "api" to "info".
 */
export class Logger {
  /** A set of all active loggers, by name. Can be used to dynamically alter log level. */
  public static loggers: Record<string, Logger> = {}
  public disabled = process.env.NODE_ENV === 'test'
  private _level: Level = 'error'
  private numericLevel = 0
  private startTime?: number

  constructor(public name: string, level?: Level) {
    Logger.loggers[name] = this
    if (!level) level = readLogLevelFromStorage(name) ?? 'timer'
    this.level = level
  }

  public get level(): Level {
    return this._level
  }
  public set level(level: Level) {
    if (levels[level]) {
      this._level = level
      this.numericLevel = levels[level].numericLevel
    }
  }

  error(message: string | unknown, ...other: unknown[]): void {
    if (typeof message === 'string') this.log('error', message, ...other)
    else this.log('error', '', message, other)
  }

  warn(message: string, ...other: unknown[]): void {
    this.log('warn', message, ...other)
  }

  info(message: string, ...other: unknown[]): void {
    this.log('info', message, ...other)
  }

  verbose(message: string, ...other: unknown[]): void {
    this.log('verbose', message, ...other)
  }

  log(level: Level, message: string, ...other: unknown[]): void {
    if (this.disabled) return
    const info = levels[level]
    if (!info) return
    if (info.numericLevel > this.numericLevel) return

    const m = this.format(info, message)
    if (level === 'error') console.error(m, ...other)
    else console.log(m, ...other)
  }

  startTimer(): void {
    this.startTime = Date.now()
  }
  timer(message: string): number {
    const elapsed = Date.now() - (this.startTime ?? 0)
    this.log('timer', `${elapsed}ms - ${message}`)
    return elapsed
  }

  private format(info: LevelInfo, message: string): string {
    return `${info.prefix}[${this.name}] (${Date.now()}): ${message}`
  }
}

const LOGGER_STORAGE_KEY = 'logger'

const inBrowser = typeof window !== 'undefined'

if (inBrowser) window.addEventListener('storage', handleStorageEvent)
function handleStorageEvent(e: StorageEvent): void {
  if (e.key !== LOGGER_STORAGE_KEY || !e.newValue) return
  console.log('Setting log levels from local storage')
  const settings = JSON.parse(e.newValue)
  Object.entries(settings).forEach(([exp, value]) => {
    const level = value as Level
    const loggers = findLoggerNames(exp).map((name) => Logger.loggers[name])
    loggers.forEach((logger) => {
      if (levels[level]) {
        console.log(`Setting ${logger.name} log level to '${level}'`)
        logger.level = level
      }
    })
  })
}

function readLogLevelFromStorage(name: string): Level | undefined {
  if (!inBrowser) return undefined
  const stg = localStorage.getItem(LOGGER_STORAGE_KEY)
  let level: Level | undefined
  if (stg) {
    const settings = JSON.parse(stg)
    Object.entries(settings).forEach(([exp, value]) => {
      const loggers = findLoggerNames(exp).map((name) => Logger.loggers[name])
      loggers.some((logger) => {
        if (logger.name === name) {
          level = value as Level
          console.log(`Found ${name} log level: '${level}'`)
          return true
        }
        return false
      })
    })
  }
  return level
}

/**
 * Finds all loggers whose name matches the expression.
 * The expression can be a plain straing or a reg-ex.
 */
function findLoggerNames(exp: string): string[] {
  const regex = RegExp('^' + exp.replace('*', '.*') + '$')
  return Object.keys(Logger.loggers).filter((name) => regex.test(name))
}
