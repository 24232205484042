import { ButtonCancel, LifeDialog, ProgressCircle } from '@life/components'
import { Book, ImportStatus, useImportDoc } from '@life/frontend-model'
import { useEffect, useState } from 'react'

type Props = {
  key: string
  isOpen: boolean
  book: Book
  file: File | undefined
  onClose: () => void
}
export function WordDocImporter({ isOpen, book, file, onClose }: Props): JSX.Element {
  const [inProgress, setInProgress] = useState<boolean>()
  const { importDoc, status, progress, abort, stats, error } = useImportDoc(book)
  useEffect(() => {
    // state starts at undefined to make sure we don't upload multiple times!
    if (inProgress === undefined && file) {
      setInProgress(true)
      importDoc(file)
    }
  }, [inProgress, importDoc, file])

  if (!file) return <></>
  const statusDisplay = {
    idle: '',
    gettingUrl: 'Preparing to upload...',
    uploading: 'Uploading file...',
    importing: 'Importing document...',
    completed: 'Import successfully completed',
    error: 'An error occurred',
    aborted: 'The upload was aborted',
    timedout: 'Server still processing...',
  }
  return (
    <LifeDialog title="Importing from Word Document" isOpen={isOpen} modal onClose={onClose} className="max-w-prose">
      <LifeDialog.Content>
        <InfoLine name="File" value={file.name} />
        <InfoLine name="Status" value={statusDisplay[status]} />
        <div className="pt-6">
          {status === 'error' && <InfoLine name="Error" value={error} />}
          {status === 'completed' && (
            <>
              <InfoLine name="Story title" value={stats?.storyTitle} />
              <InfoLine name="Photos added" value={String(stats?.imageCount)} />
            </>
          )}
        </div>
      </LifeDialog.Content>
      <LifeDialog.Actions>
        <DoneButton status={status} abort={abort} progress={progress} onClose={onClose} />
      </LifeDialog.Actions>
    </LifeDialog>
  )
}

type InfoProps = {
  name: string
  value: string | undefined
}
function InfoLine({ name, value }: InfoProps): JSX.Element {
  if (value === undefined) return <></>
  return (
    <div>
      <span className="font-bold pr-2">{name}:</span>
      {value}
    </div>
  )
}

type DoneProps = {
  status: ImportStatus
  progress: number
  abort: () => void
  onClose: () => void
}
function DoneButton({ status, progress, abort, onClose }: DoneProps): JSX.Element {
  if (status === 'uploading') {
    return (
      <>
        <div>
          You may abort the upload while it is in progress. Once the upload completes, the import cannot be aborted.
        </div>
        <ButtonCancel
          onClick={abort}
          icon={<ProgressCircle progress={progress} className="-ml-0.5 mr-2 h-4 w-4" />}
          className="relative bg-red-600 hover:bg-red-700 focus:ring-red-500"
        >
          Abort Upload
        </ButtonCancel>
      </>
    )
  }
  return (
    <>
      {status === 'importing' && (
        <div>
          You may close this dialog. When the import is complete the Story and Photos will be automatically added to the
          Book.
        </div>
      )}
      {status === 'timedout' && (
        <div>
          You may close this dialog. The server is taking too long to automatically update the Book. Try refreshing the
          browser in several minutes.
        </div>
      )}
      <ButtonCancel onClick={onClose} clicked={status === 'importing'}>
        Close
      </ButtonCancel>
    </>
  )
}
