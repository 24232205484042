import { Book } from '@life/frontend-model'
import { StorySubstory } from '@life/model'
import { StoryPreview } from './StoryReader'

type Props = {
  book: Book
  section: StorySubstory
}

export function ViewSection({ book, section }: Props): JSX.Element {
  const story = book.findStory(section.id)
  if (!story) return <div className="text-red-700">Missing child story ID</div>
  return <StoryPreview book={book} story={story} />
}
