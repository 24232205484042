import { ReactNode } from 'react'
import { classNames } from './classNames'

type Props = {
  message: string | undefined
  className?: string
  children: ReactNode
  position?: 'top' | 'bottom'
}
export function Tooltip({ message, className, children, position = 'bottom' }: Props): JSX.Element {
  if (!message) return <>{children}</>
  return (
    <div className={classNames('relative flex flex-col items-center group', className)}>
      {children}
      <div
        className={classNames(
          'absolute items-center hidden group-hover:flex',
          position === 'top' ? 'bottom-0 mb-8 flex-col' : 'top-0 mt-8 flex-col-reverse'
        )}
      >
        <span className="relative z-20 p-2 text-center text-xs select-none text-white bg-gray-600 shadow-lg rounded-md">
          {message}
        </span>
        <div className={classNames('w-3 h-3 z-10 rotate-45 bg-gray-600', position === 'top' ? '-mt-2' : '-mb-2')}></div>
      </div>
    </div>
  )
}
