import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { NotificationProvider } from '@life/components'
import { ModelProvider } from '@life/frontend-model'
import { BrowserRouter } from 'react-router-dom'
import auth0Config from './auth0.json'
import { ErrorBoundary } from './ErrorBoundary'
import LifeRoutes from './LifeRoutes'

export default function App() {
  function handleRedirect(appState: AppState | undefined): void {
    if (appState?.target) window.location.href = appState.target
    else window.location.href = new URL(window.location.href).origin + '/start'
  }
  return (
    <ErrorBoundary>
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        redirectUri={window.location.origin}
        onRedirectCallback={handleRedirect}
        cacheLocation="localstorage"
      >
        <ModelProvider>
          <BrowserRouter>
            <NotificationProvider>
              <LifeRoutes />
            </NotificationProvider>
          </BrowserRouter>
        </ModelProvider>
      </Auth0Provider>
    </ErrorBoundary>
  )
}
