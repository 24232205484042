import { ButtonAdd, NotReady, PageHeading, PagePadding, useErrorNotification, useRequiredParam } from '@life/components'
import { useAcceptInvite, useBookInfo } from '@life/frontend-model'
import { useNavigate } from 'react-router-dom'

export function AcceptInvite(): JSX.Element {
  const [bookSlug, inviteId] = useRequiredParam(['bookSlug', 'inviteId'])
  const { book, isLoading, error } = useBookInfo(bookSlug)
  const { accept, isAccepting } = useAcceptInvite()
  const { showError } = useErrorNotification()
  const navigate = useNavigate()

  if (isLoading || error || !book)
    return <NotReady type="Invite" id={`${bookSlug}/${inviteId}`} isLoading={isLoading} error={error} />

  async function acceptInvite(): Promise<void> {
    try {
      await accept(bookSlug, inviteId)
      navigate(`/book/${bookSlug}`)
    } catch (error) {
      showError('Error accepting invite', error)
    }
  }

  return (
    <PagePadding className="flex flex-col items-center gap-4">
      <PageHeading title="Invite" back={false} className="pt-20">
        Accept Invitation
      </PageHeading>
      <p>You have been invited to view the Unforgotten Life book &ldquo;{book.title}&rdquo;.</p>
      <p>Accept the invitation by clicking &lsquo;Accept&rsquo; below.</p>
      <ButtonAdd clicked={isAccepting} onClick={acceptInvite}>
        Accept
      </ButtonAdd>
    </PagePadding>
  )
}
