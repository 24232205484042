import {
  dateToString,
  ItemList,
  ItemListMode,
  PersonIconWithToolTip,
  S3ImageView,
  SortDef,
  StoryIconWithToolTip,
} from '@life/components'
import { Book, Image } from '@life/frontend-model'
import { useItemNavigation } from '../../common'
import { searchImages } from '../../search'
import { ImageSortType, sortImages } from '../util'

const sortDefs: SortDef<ImageSortType>[] = [
  { sortType: 'date', description: 'Date' },
  { sortType: 'notes', description: 'Notes' },
  { sortType: 'in-use', description: 'Used in Book' },
  { sortType: 'no-people', description: 'No. People' },
]

type Props = {
  mode?: ItemListMode
  book: Book
  images: readonly Image[]
  initialFilter?: string
  onSelect?: (image: Image) => void
  isSelected?: (image: Image) => boolean
  onChoose?: (image: Image) => void
  noHeader?: boolean
}
export function ImageList({
  mode,
  book,
  images,
  initialFilter,
  onSelect,
  isSelected,
  onChoose,
  noHeader,
}: Props): JSX.Element {
  const { navToImage } = useItemNavigation(book)

  function filterSort(images: readonly Image[], sortType: ImageSortType | undefined, filter?: string): Image[] {
    const newImages = filter ? searchImages(images, filter) : [...images]
    if (sortType) sortImages(newImages, sortType)
    return newImages
  }

  return (
    <ItemList
      mode={mode}
      items={images}
      prefsKey="imageList"
      onChoose={onChoose}
      onSelect={onSelect}
      isSelected={isSelected}
      onNavigate={navToImage}
      sortDefs={sortDefs}
      doFilterSort={filterSort}
      initialFilter={initialFilter}
      noHeader={noHeader}
      searchHelp={<ImageSearchHelp />}
    >
      {(viewType, image) => (
        <>
          {viewType === 'grid' && <GridImage image={image} />}
          {viewType === 'list' && <ListImage image={image} />}
        </>
      )}
    </ItemList>
  )
}

type ItemProps = {
  image: Image
}
function GridImage({ image }: ItemProps): JSX.Element {
  return (
    <div className="flex-col overflow-clip bg-white border rounded-xl shadow hover:shadow-md m-1">
      <S3ImageView image={image} size="thumb" className="w-full aspect-square object-cover" />
      <div className="h-5 mt-1 px-2 flex justify-between text-xs text-gray-800">
        <p className="text-center ">{dateToString(image.when)}</p>
        <IconBar image={image} />
      </div>
    </div>
  )
}

function ListImage({ image }: ItemProps): JSX.Element {
  return (
    <div className="flex flex-row items-center space-x-2 overflow-clip border rounded-xl shadow hover:shadow-md">
      <S3ImageView image={image} size="thumb" className="w-16 aspect-square object-cover" />

      <div className="flex-1 flex flex-row items-center text-sm text-gray-800 space-x-2 pr-2">
        <p className="flex-1 text-ellipsis overflow-hidden text-left">{image.notes}</p>
        {image.when && <p className="text-right font-bold">{dateToString(image.when)}</p>}
        <IconBar image={image} />
      </div>
    </div>
  )
}

function IconBar({ image }: ItemProps): JSX.Element {
  return (
    <div className="flex items-center">
      <StoryIconWithToolTip count={image.storiesWithImage.length} />
      <PersonIconWithToolTip count={image.who.length} />
    </div>
  )
}

function ImageSearchHelp(): JSX.Element {
  return (
    <>
      <p>Search photos by notes, year, or the names of people in the photo, or stories they are in.</p>
      <p className="py-2">Advanced search:</p>
      <ul className="pl-8 list-disc">
        <li>&lsquo;people:&rsquo; searches number of people in the photo; e.g., people:0</li>
        <li>&lsquo;stories:&rsquo; searches number of stories with the photo; e.g., stories:0</li>
        <li>&lsquo;date:&rsquo; searches the date of the photo; e.g., date:2020 or date:none</li>
      </ul>
    </>
  )
}
