import { classNames } from '@life/components'
import { Book } from '@life/frontend-model'
import { StoryParagraph } from '@life/model'
import { ViewContent } from './ViewContent'

type Props = {
  book: Book
  paragraph: StoryParagraph
}

export function ViewParagraph({ book, paragraph }: Props): JSX.Element {
  const padding = paragraph.leftPadding ?? 0
  return (
    <p
      className={classNames('pb-4 leading-8 max-w-prose text-xl text-gray-700 font-serif', padding > 0 ? 'pl-10' : '')}
    >
      <ViewContent book={book} content={paragraph.children} />
    </p>
  )
}
