import { dateToString, ItemList, S3ImageView, SortDef } from '@life/components'
import { Image } from '@life/frontend-model'
import { ImageSortType, sortImages } from '../image'
import { searchImages } from '../search'

const sortDefs: SortDef<ImageSortType>[] = [
  { sortType: 'date', description: 'Date' },
  { sortType: 'notes', description: 'Notes' },
]

type Props = {
  images: Image[]
}
export function PhotoAlbum({ images }: Props): JSX.Element {
  function filterSort(images: readonly Image[], sortType: ImageSortType | undefined, filter?: string): Image[] {
    const newImages = filter ? searchImages(images, filter) : [...images]
    if (sortType) sortImages(newImages, sortType)
    return newImages
  }

  function openImage(img: Image): void {
    window.open(img.originalUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <ItemList items={images} onNavigate={openImage} sortDefs={sortDefs} doFilterSort={filterSort}>
      {(viewType, image) => (
        <>
          {viewType === 'grid' && <GridImage image={image} />}
          {viewType === 'list' && <ListImage image={image} />}
        </>
      )}
    </ItemList>
  )
}

type ItemProps = {
  image: Image
}
function GridImage({ image }: ItemProps): JSX.Element {
  return (
    <div className="flex-col overflow-clip bg-white border rounded-xl shadow hover:shadow-md m-1">
      <S3ImageView image={image} size="thumb" className="w-full aspect-square object-cover" />
      <div className="h-5 mt-1 px-2 flex justify-between text-xs text-gray-800">
        <p className="text-center ">{dateToString(image.when)}</p>
      </div>
    </div>
  )
}

function ListImage({ image }: ItemProps): JSX.Element {
  return (
    <div className="flex flex-row items-center space-x-2 overflow-clip border rounded-xl shadow hover:shadow-md">
      <S3ImageView image={image} size="thumb" className="w-16 aspect-square object-cover" />

      <div className="flex-1 flex flex-row items-center text-sm text-gray-800 space-x-2 pr-2">
        <p className="flex-1 text-ellipsis overflow-hidden text-left">{image.notes}</p>
        {image.when && <p className="text-right font-bold">{dateToString(image.when)}</p>}
      </div>
    </div>
  )
}
