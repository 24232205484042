import { Image } from '@life/frontend-model'
import { ImageId, StoryElement } from '@life/model'
import { Descendant } from 'slate'
import { PartialStoryImage } from './utils'

const cacheKey = 'captionCache'

type CaptionCache = Record<ImageId, string>

export function cacheCaptions(story: Descendant[]): void {
  story.forEach((el) => {
    const item = el as StoryElement
    if (item.type === 'image') {
      addToCache(item.id, item.caption)
    }
    if (item.children?.length > 0) {
      cacheCaptions(item.children)
    }
  })
}

export function cacheCaption(image: PartialStoryImage): void {
  image.id && addToCache(image.id, image.caption)
}

export function getDefaultCaption(storyImg: PartialStoryImage, image: Image): string | undefined {
  return storyImg.caption || getFromCache(image.imageId) || image.notes
}

function addToCache(id: ImageId, caption: string | undefined): void {
  const cache = getCache()
  if (caption) cache[id] = caption
  else delete cache[id]
  setCache(cache)
}

function getFromCache(id: ImageId): string | undefined {
  return getCache()[id]
}

function getCache(): CaptionCache {
  const cache = localStorage.getItem(cacheKey)
  return cache ? JSON.parse(cache) : {}
}

function setCache(cache: CaptionCache): void {
  localStorage.setItem(cacheKey, JSON.stringify(cache))
}
