import { useLocation, useParams } from 'react-router-dom'

export function useRequiredParam(param: string): string
export function useRequiredParam(params: string[]): string[]
export function useRequiredParam(param: string | string[]): string | (string | undefined)[] | undefined {
  return useParamInternal(param, true)
}

export function useOptionalParam(param: string): string | undefined
export function useOptionalParam(params: string[]): (string | undefined)[]
export function useOptionalParam(param: string | string[]): string | (string | undefined)[] | undefined {
  return useParamInternal(param, false)
}

function useParamInternal(param: string | string[], required: boolean): string | (string | undefined)[] | undefined {
  const params = useParams()
  function getParam(param: string): string | undefined {
    if (!(param in params)) {
      if (required) throw new Error(`Param ${param} not found`)
      return undefined
    }
    return params[param] ?? ''
  }
  if (Array.isArray(param)) {
    return param.map((p) => getParam(p))
  }
  return getParam(param)
}

export function useQueryParam(param: string): string | undefined {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  return query.get(param) ?? undefined
}
