import { compareDates } from '@life/components'
import { Image } from '@life/frontend-model'

export function toReadableSize(bytes: number): string {
  if (bytes > 10000000) return Math.round(bytes / 1000000) + ' MB'
  if (bytes > 1000000) return Math.round(bytes / 100000) / 10 + ' MB'
  if (bytes > 1000) return Math.round(bytes / 1000) + ' KB'
  if (bytes > 100) return Math.round(bytes / 100) / 10 + ' KB'
  return bytes + ' B'
}

export type ImageSortType = 'notes' | 'date' | 'in-use' | 'no-people'

export function sortImages(images: Image[], sortField: ImageSortType): Image[] {
  images.sort((a, b) => {
    switch (sortField) {
      case 'notes':
        if (!a.notes) return b.notes ? 1 : -1
        if (!b.notes) return a.notes ? -1 : 1
        return a.notes.localeCompare(b.notes)
      case 'date':
        if (!a.when) return b.when ? 1 : -1
        if (!b.when) return a.when ? -1 : 1
        return compareDates(a.when, b.when)
      case 'in-use':
        return a.storiesWithImage.length - b.storiesWithImage.length
      case 'no-people':
        return a.who.length - b.who.length
    }
  })
  return images
}

/**
 * Calculates the sizes of an image (width, height, isLandscape).
 * First examines the width and height in the Image. If those are not present
 * then it will calculate from an HTMLImageElement, if provided.
 * Usage:
 *   const imgRef = useRef<HTMLImageElement>(null)
 *   const [width, height, isLandscape] = getImageSize(image, imgRef.current) ?? []
 *   return <img ref={imgRef} src=... />
 * @return [width, height, isLandscape] or undefined if it cannot be computed
 */
export function getImageSize(
  image: Image | undefined,
  imgEl?: HTMLImageElement | null
): [number, number, boolean] | undefined {
  let w, h
  if (image && image.width !== undefined && image.height !== undefined) {
    w = image.width
    h = image.height
  } else if (imgEl) {
    w = imgEl.naturalWidth
    h = imgEl.naturalHeight
  }
  return w !== undefined && h !== undefined ? [w, h, w >= h] : undefined
}
