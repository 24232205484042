import { ImageIconWithToolTip, lifeSpan, StoryIconWithToolTip } from '@life/components'
import { Person } from '@life/frontend-model'
import { PersonImage } from '../PersonImage'

type GridProps = {
  person: Person
  lastNameFirst: boolean
}
export function GridPerson({ person, lastNameFirst }: GridProps): JSX.Element {
  return (
    <div className="flex flex-col p-4 pb-1 bg-white border rounded-xl shadow hover:shadow-md gap-2 m-1">
      <PersonImage image={person.image} />
      <div className="flex-wrap w-full text-center">
        <h3 className="line-clamp-2 text-gray-800 font-medium text-sm sm:text-base sm:whitespace-nowrap">
          {lastNameFirst ? person.formalNameLastFirst : person.formalName}
        </h3>
        <div className="flex flex-row justify-between">
          <p className="text-sm text-gray-500">{lifeSpan(person.birthDate, person.deathDate) || <>&nbsp;</>}</p>
          <IconBar imageCount={person.imagesWithPerson.length} storyCount={person.storiesWithPerson.length} />
        </div>
      </div>
    </div>
  )
}

type ListProps = {
  person: Person
  lastNameFirst: boolean
}
export function ListPerson({ person, lastNameFirst }: ListProps): JSX.Element {
  return (
    <div className="flex flex-row p-1 bg-white border rounded-xl shadow hover:shadow-md gap-2">
      <PersonImage image={person.image} className="w-16" />
      <div className="flex flex-row items-center space-x-2 justify-between w-full">
        <div className="text-left">
          <h3 className="text-gray-800 font-medium text-xl">
            {lastNameFirst ? person.formalNameLastFirst : person.formalName}
          </h3>
          {person.nickName && (
            <p className="pl-2 italic">
              {person.nickName}
              {person.otherLastNames && <span className="pl-1">({person.otherLastNamesDisplay})</span>}
            </p>
          )}
        </div>
        <div className="flex space-x-2 items-center">
          <p className="text-left text-sm text-gray-500">{lifeSpan(person.birthDate, person.deathDate)}</p>
          <IconBar imageCount={person.imagesWithPerson.length} storyCount={person.storiesWithPerson.length} />
        </div>
      </div>
    </div>
  )
}

type IconBarProps = {
  imageCount: number
  storyCount: number
}
function IconBar({ imageCount, storyCount }: IconBarProps): JSX.Element {
  return (
    <div className="flex items-center">
      <ImageIconWithToolTip count={imageCount} />
      <StoryIconWithToolTip count={storyCount} />
    </div>
  )
}
