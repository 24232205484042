import { CameraIcon } from '@heroicons/react/24/outline'
import { isEmpty } from '@life/model'
import { classNames } from '../classNames'
import { IconWithToolTip } from './IconWithToolTip'

type Props = {
  color: 'green' | 'red'
  className?: string
}
export function ImageIcon({ color, className }: Props): JSX.Element {
  return (
    <CameraIcon
      className={classNames(className, 'h-4', color === 'green' && 'text-green-700', color === 'red' && 'text-red-700')}
    />
  )
}

type WithToolTipProps = {
  count: number
}
export function ImageIconWithToolTip({ count }: WithToolTipProps): JSX.Element {
  return (
    <IconWithToolTip icon={<ImageIcon color={count > 0 ? 'green' : 'red'} />}>
      <div className="whitespace-nowrap">{XPhotosToEnglish(count)}</div>
    </IconWithToolTip>
  )
}

function XPhotosToEnglish(count: number): string {
  if (isEmpty(count)) return 'No photos'
  if (count === 1) return '1 photo'
  return count + ' photos'
}
