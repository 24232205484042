import { classNames } from '../classNames'
import { CheckIcon } from '../icons'
import { ItemListMode } from './ItemList'

type OverlayProps = {
  selected: boolean | undefined
  mode?: ItemListMode
}
export function SelectOverlay({ selected, mode }: OverlayProps): JSX.Element {
  return (
    <div
      className={classNames(
        'absolute top-3 left-3 w-8 h-8 rounded-full overflow-clip',
        mode !== 'choose' && 'border-2 border-gray-400'
      )}
    >
      <div
        className={classNames(
          'w-full',
          selected ? 'text-white bg-blue-500' : 'invisible group-hover:visible text-gray-400 bg-gray-50'
        )}
      >
        <CheckIcon />
      </div>
    </div>
  )
}
