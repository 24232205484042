import { SaveIcon } from '../icons'
import { Tooltip } from '../Tooltip'
import { ButtonBase } from './ButtonBase'

type Props = {
  clicked?: boolean
  canEdit?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  children?: React.ReactNode
}
export function ButtonSave({
  clicked,
  canEdit = true,
  disabled,
  type = 'submit',
  onClick,
  children,
}: Props): JSX.Element {
  return (
    <Tooltip message={canEdit ? '' : 'No Edit permission'}>
      <ButtonBase
        type={type}
        clicked={clicked}
        disabled={disabled ?? !canEdit}
        onClick={onClick}
        className="bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
        icon={<SaveIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
      >
        {children || 'Save'}
      </ButtonBase>
    </Tooltip>
  )
}
