import { BookIcon, NewBookIcon, NotReady, PagePadding } from '@life/components'
import { useBooks } from '@life/frontend-model'
import { Link, useNavigate } from 'react-router-dom'

export function SwitchBooks(): JSX.Element {
  const navigate = useNavigate()
  const { list, isLoading, error } = useBooks()

  if (isLoading || error || !list) return <NotReady type="Book" isLoading={isLoading} error={error} />

  if (list?.length === 0) {
    navigate('/book/_add')
  }

  list.sort((a, b) => {
    return b.lastAccessed - a.lastAccessed
  })

  return (
    <PagePadding narrow>
      <h1 className="text-center text-3xl font-extrabold text-slate-700 tracking-tight sm:text-4xl">
        Welcome to <span className="whitespace-nowrap">Unforgotten Life</span>
      </h1>
      <div className="">
        {list.map((book) => (
          <Link
            key={book.key}
            to={'/book/' + (book.slug || book.bookId)}
            className="hover:bg-slate-100 hover:border-indigo-500 hover:shadow-md rounded-lg border-2 border-slate-200 p-4 my-4 flex"
          >
            <BookIcon className="w-8 pr-2" />
            <p className="text-xl">
              {book.title}
              {book.subtitle && <span className="italic text-lg">: {book.subtitle}</span>}
            </p>
          </Link>
        ))}
        <Link
          to="/book/_add"
          className="hover:bg-slate-100 hover:border-indigo-500 hover:shadow-md rounded-lg border-2 border-slate-200 border-dashed p-4 my-4 flex"
        >
          <NewBookIcon className="w-8 pr-2" />
          <p className="text-xl">Start a new Book</p>
        </Link>
      </div>
    </PagePadding>
  )
}
