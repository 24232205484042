import { Disclosure, Transition } from '@headlessui/react'
import {
  BookIcon,
  CloseIcon,
  InfoPanelProvider,
  MenuIcon,
  NotReady,
  PagePadding,
  useInfoPanel,
  usePageTitle,
  useRequiredParam,
} from '@life/components'
import { Book, useBook } from '@life/frontend-model'
import { isDefined } from '@life/model'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { canEditBook } from '../common'
import { BookIndex } from './BookIndex'
import { BookInfo } from './BookInfo'
import { BookReader } from './BookReader'
import { IndexBuilderProvider, useIndexBuilder } from './IndexBuilder'
import { Outline } from './Outline'
import { PhotoAlbum } from './PhotoAlbum'
import { ScrollStateProvider } from './ScrollState'

export function SiteController(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)
  usePageTitle(book?.title)
  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />

  return (
    <div id="LayoutTopNav" className="flex flex-col h-full">
      <InfoPanelProvider>
        <ScrollStateProvider book={book}>
          <IndexBuilderProvider book={book}>
            <TopNav book={book} />
            <main className="flex flex-row h-full overflow-hidden">
              <SiteNav book={book} className="hidden sm:block h-full p-2 max-w-xs bg-gray-100" />
              <PagePadding noPadding>
                <BookReader book={book} />
              </PagePadding>
            </main>
          </IndexBuilderProvider>
        </ScrollStateProvider>
      </InfoPanelProvider>
    </div>
  )
}

type TopNavProps = {
  book: Book
}
function TopNav({ book }: TopNavProps): JSX.Element {
  const canEdit = canEditBook(book)
  return (
    <Disclosure as="nav" className="flex-1 flex-shrink-0 bg-indigo-600">
      {({ open, close }) => (
        <>
          <div className="mx-auto px-2">
            <div className="relative flex items-center justify-between h-16">
              <Link
                to={canEdit ? `/book/${book.bookId}` : '/'}
                className="text-slate-50 hover:text-slate-200"
                onClick={() => close()}
              >
                <BookIcon className="h-10 w-auto" aria-label="Unforgotten Logo" />
              </Link>
              <div className="flex md:hidden">
                <ButtonToggleMenu open={open} />
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden">
            <SiteNav book={book} className="h-full p-2 bg-gray-200" />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

type MenuProps = {
  open: boolean
}
export function ButtonToggleMenu({ open }: MenuProps): JSX.Element {
  return (
    <Disclosure.Button className="bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-400 hover:text-white hover:bg-indigo-600">
      <span className="sr-only">Open main menu</span>
      {open ? (
        <CloseIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
      )}
    </Disclosure.Button>
  )
}

type NavProps = {
  book: Book
  className?: string
}
function SiteNav({ book, className }: NavProps): JSX.Element {
  const { openInfoPanel } = useInfoPanel()
  const indexBuilder = useIndexBuilder()
  const [isOpen, setIsOpen] = useState(true)

  function showBookInfo(): void {
    openInfoPanel('Book Info', <BookInfo book={book} />)
  }
  function showPhotoAlbum(): void {
    // Build image list here since an InfoPanel cannot use useIndexBuilder
    const allEntries = indexBuilder.findEntries('image')
    // Remove duplicate entry.elementId
    const imageEntries = allEntries.filter(
      (img, index) => allEntries.findIndex((i) => i.elementId === img.elementId) === index
    )
    const images = imageEntries.map((entry) => book.findImage(entry.elementId)).filter(isDefined)
    openInfoPanel('Photo Album', <PhotoAlbum images={images} />, { size: 'full' })
  }

  return (
    <div id="SiteNav" className={className}>
      {!isOpen && (
        <div
          className="bg-gray-100 hover:bg-gray-300 border-gray-200 hover:border-gray-400 cursor-pointer h-full w-4 absolute left-0 border-r-4"
          onClick={() => setIsOpen(true)}
        />
      )}
      <Transition
        className="h-full"
        show={isOpen}
        enter="transition ease-in-out duration-200 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="flex flex-row items-stretch h-full">
          <div className="flex flex-col space-y-2">
            <h1 className="text-2xl font-bold text-gray-800 leading-none cursor-pointer" onClick={showBookInfo}>
              {book.title}
            </h1>
            {book.subtitle && <h1 className="text-lg italic text-gray-800 leading-none">{book.subtitle}</h1>}
            <h1 className="text-lg font-bold text-gray-800 pt-4">Outline</h1>
            <div className="pl-3">
              <Outline book={book} />
            </div>
            <h1 className="text-lg font-bold text-gray-800 pt-4">Index</h1>
            <div className="pl-3">
              <BookIndex book={book} />
            </div>
            <h1 className="text-lg font-bold text-gray-800 pt-4 cursor-pointer" onClick={showPhotoAlbum}>
              View Photo Album
            </h1>
          </div>
          <div
            className="h-full bg-gray-100 hover:bg-gray-200 w-2 border-r-4 border-gray-200 cursor-pointer"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Transition>
    </div>
  )
}
