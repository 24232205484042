import { BookView, Image, Person, Story } from '@life/frontend-model'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

type BookType = Pick<BookView, 'bookId' | 'slug'>
type ImageType = Pick<Image, 'imageId'>
type PersonType = Pick<Person, 'personId'>
type StoryType = Pick<Story, 'storyId'>

export function linkToBook(bookInfo: BookType): string {
  const { bookId, slug } = bookInfo
  const root = '/book/'
  return root + (slug || bookId)
}

const ADD = '_add'

/** @deprecated use image.link */
export function linkToImage(bookInfo: BookType, image: ImageType): string {
  const target = image?.imageId || ADD
  return linkToBook(bookInfo) + '/photos/' + target
}

/** @deprecated use person.link */
export function linkToPerson(bookInfo: BookType, person?: PersonType): string {
  const target = person?.personId || ADD
  return linkToBook(bookInfo) + '/people/' + target
}

/** @deprecated use story.link */
export function linkToStory(bookInfo: BookType, story?: StoryType): string {
  const target = story?.storyId || ADD
  return linkToBook(bookInfo) + '/stories/' + target
}

type ItemNavigators = {
  navToBook: () => void
  navToStory: (story: Story) => void
  navToImage: (image: Image) => void
  navToPerson: (person: Person) => void
}
export function useItemNavigation(book: BookView | undefined): ItemNavigators {
  const navigate = useNavigate()
  const bookRef = useRef<BookView | undefined>(book)

  return {
    navToBook: () => bookRef.current && navigate(linkToBook(bookRef.current)),
    navToStory: (story) => bookRef.current && navigate(linkToStory(bookRef.current, story)),
    navToImage: (image) => bookRef.current && navigate(linkToImage(bookRef.current, image)),
    navToPerson: (person) => bookRef.current && navigate(linkToPerson(bookRef.current, person)),
  }
}
