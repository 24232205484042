import { WarningIcon } from './icons'
import { API } from '@life/model'
import { ReactNode } from 'react'
import { Spinner } from './Spinner'

type MessageProps = {
  title: string
  description: string
  icon?: ReactNode
  children?: ReactNode
}
export function MessagePage({ title, description, icon, children }: MessageProps): JSX.Element {
  return (
    <div className="bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="">
        <main className="flex flex-col">
          <div className="flex mb-4 sm:ml-6">
            {icon || <Spinner size={4} className="text-slate-500" />}
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-3xl font-extrabold text-slate-500 tracking-tight sm:text-4xl">{title}</h1>
              <p className="mt-1 text-base text-slate-500">{description}</p>
            </div>
          </div>
          <div>{children}</div>
        </main>
      </div>
    </div>
  )
}

const messages = {
  Book: {
    loadingOne: (id: string) => `Loading Book ${id}...`,
    loadingMany: 'Loading your Books',
    errorOne: (id: string) => `Error loading Book ${id}`,
    errorMany: 'Error loading your Books',
    notFound: (id: string) => `Book ${id} does not exist`,
  },
  Story: {
    loadingOne: (id: string) => `Loading Story ${id}...`,
    loadingMany: 'Loading your Stories',
    errorOne: (id: string) => `Error loading Story ${id}`,
    errorMany: 'Error loading your Stories',
    notFound: (id: string) => `Story ${id} does not exist`,
  },
  Image: {
    loadingOne: (id: string) => `Loading Image ${id}...`,
    loadingMany: 'Loading your Images',
    errorOne: (id: string) => `Error loading Image ${id}`,
    errorMany: 'Error loading your Images',
    notFound: (id: string) => `Image ${id} does not exist`,
  },
  PdfInfo: {
    loadingOne: (_id: string) => `Loading PDF Info...`,
    loadingMany: 'Loading PDF Info...',
    errorOne: (_id: string) => `Error loading PDF Info`,
    errorMany: 'Error loading PDF Info',
    notFound: (_id: string) => 'No PDF Info',
  },
  Person: {
    loadingOne: (id: string) => `Loading Person ${id}...`,
    loadingMany: 'Loading your People',
    errorOne: (id: string) => `Error loading Person ${id}`,
    errorMany: 'Error loading your People',
    notFound: (id: string) => `Person ${id} does not exist`,
  },
  User: {
    loadingOne: () => '',
    loadingMany: 'Loading User credentials...',
    errorOne: () => '',
    errorMany: 'Error logging in',
    notFound: (id: string) => `User ${id} does not exist`,
  },
  Collaborator: {
    loadingOne: () => '',
    loadingMany: 'Loading collaborators...',
    errorOne: () => '',
    errorMany: 'Error loading your collaborators',
    notFound: (id: string) => `Collaborators for ${id} not found`,
  },
  Invite: {
    loadingOne: () => 'Loading the invitation...',
    loadingMany: '',
    errorOne: () => 'Error loading the invitation',
    errorMany: '',
    notFound: (id: string) => `Invitation ${id} not found`,
  },
}
type NotReadyProps = {
  type: keyof typeof messages
  id?: string
  notFound?: boolean
  isLoading?: boolean
  error?: Error | API.ResponseError<unknown> | string
  children?: ReactNode
}
export function NotReady({ type, id, notFound, isLoading, error, children }: NotReadyProps): JSX.Element | null {
  if (isLoading) {
    const desc = id ? messages[type].loadingOne(id) : messages[type].loadingMany
    return (
      <MessagePage title="Loading" description={desc}>
        {children}
      </MessagePage>
    )
  }
  if (error) {
    const desc = id ? messages[type].errorOne(id) : messages[type].errorMany
    const msg = error instanceof Error ? error.message : API.isResponseError(error) ? error.message : error.toString()
    return (
      <MessagePage
        title="Error"
        description={`${desc}; Error: ${msg}`}
        icon={<WarningIcon className="h-20 w-20 text-red-700" />}
      >
        {children}
      </MessagePage>
    )
  }
  if (notFound) {
    const desc = id ? messages[type].notFound(id) : 'No ID provided'
    return (
      <MessagePage title="Not Found" description={desc} icon={<WarningIcon className="h-20 w-20 text-red-700" />}>
        {children}
      </MessagePage>
    )
  }
  return null
}
