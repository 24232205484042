import { DateBreakdown } from '@life/model'
import { useState } from 'react'
import { classNames } from './classNames'
import { isValidDay, isValidYear, monthToString, stringToMonth } from './dates'

type Props = {
  label?: string
  value: DateBreakdown | undefined
  setValue: (value: DateBreakdown) => void
}
export function DateForm({ label = 'Date', value: date, setValue }: Props): JSX.Element {
  function toNumber(value: string | undefined): number | undefined {
    if (value === undefined) return undefined
    const num = Number.parseInt(value)
    if (num === 0) return undefined
    if (isNaN(num)) return undefined
    return num
  }
  if (!date) date = { year: 0 }
  const showMonth = date.year > 0
  const showDay = showMonth && !!date.month

  return (
    <table className="DateForm">
      <tbody>
        <tr>
          <th className="text-sm font-medium text-gray-700">{label}</th>
          <th className="text-xs text-gray-600">{showMonth && date.month === undefined && 'Optional'}</th>
          <th className="text-xs text-gray-600">{showDay && date.day === undefined && 'Optional'}</th>
        </tr>
        <tr>
          <th className="text-sm text-gray-600 align-bottom">Year</th>
          {showMonth && <th className="text-sm text-gray-600 w-14">Month</th>}
          {showDay && <th className="text-sm text-gray-600 w-14">Day</th>}
        </tr>
        <tr>
          <td>
            <input
              type="tel"
              placeholder="YYYY"
              name="year"
              maxLength={4}
              value={date.year > 0 ? date.year : ''}
              onChange={(event) => {
                const value = toNumber(event.target.value)
                setValue({ ...date, year: value ?? 0 })
              }}
              className={classNames(
                'p-1 text-sm text-center rounded-md w-20',
                isValidYear(date.year)
                  ? 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                  : 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
              )}
            />
          </td>
          {showMonth && (
            <td className="text-center">
              <MonthInput date={date} setValue={setValue} />
            </td>
          )}
          {showDay && (
            <td className="text-center">
              <input
                type="tel"
                placeholder="D"
                name="day"
                value={date.day ?? ''}
                onChange={(event) => {
                  if (!date) return //shouldn't happen
                  setValue({ ...date, day: toNumber(event.target.value) })
                }}
                onFocus={(element) => element.target.select()}
                className={classNames(
                  'p-1 text-sm text-center rounded-md w-12',
                  isValidDay(date)
                    ? 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                    : 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                )}
              />
            </td>
          )}
        </tr>
        <tr>
          <td>
            <label className="text-sm text-gray-600">
              <input
                type="checkbox"
                className="rounded-md mx-1"
                name="estimate"
                checked={date.around === true}
                onChange={(element) => {
                  if (!date) return // shouldn't happen
                  setValue({ ...date, around: element.target.checked })
                }}
              />
              Estimate
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

type MonthProps = {
  date: DateBreakdown
  setValue: (value: DateBreakdown) => void
}
function MonthInput({ date, setValue }: MonthProps): JSX.Element {
  const [displayMonth, setDisplayMonth] = useState(monthToString(date.month))
  return (
    <input
      type="text"
      placeholder="Mon"
      name="month"
      maxLength={3}
      value={displayMonth}
      onBlur={(event) => {
        const newMonth = stringToMonth(event.target.value)
        setValue({ ...date, month: newMonth, day: newMonth ? date.day : undefined })
        setDisplayMonth(monthToString(newMonth))
      }}
      onChange={(event) => {
        const newMonth = stringToMonth(event.target.value) ?? (event.target.value ? 0 : undefined)
        setValue({ ...date, month: newMonth, day: newMonth ? date.day : undefined })
        setDisplayMonth(event.target.value)
      }}
      className={classNames(
        'p-1 text-sm text-center rounded-md w-12',
        !displayMonth || stringToMonth(displayMonth)
          ? 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
          : 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
      )}
    />
  )
}
