import { API } from '@life/model'
import { useMutation, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { bookKey } from './book-api'
import { Person, UnsavedPerson } from './person'

export type AddPersonState = {
  isAdding: boolean
  add: (person: UnsavedPerson) => Promise<Person>
}
export function useAddPerson(): AddPersonState {
  const queryClient = useQueryClient()
  const mutation = useMutation((person: UnsavedPerson) => addPerson(person))
  async function add(person: UnsavedPerson): Promise<Person> {
    try {
      const output = await mutation.mutateAsync(person)
      queryClient.setQueryData<Book | undefined>(bookKey(person.book), (previous) => {
        if (!previous) return previous
        previous.people.push(output)
        return previous
      })
      return output
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, add, isAdding: mutation.isLoading }
}

export type UpdatePersonState = {
  isUpdating: boolean
  update: (person: Person) => Promise<Person>
}
export function useUpdatePerson(): UpdatePersonState {
  const queryClient = useQueryClient()
  const mutation = useMutation((person: Person) => updatePerson(person))
  async function update(person: Person): Promise<Person> {
    try {
      const output = await mutation.mutateAsync(person)
      queryClient.setQueryData<Book | undefined>(bookKey(person.book), (previous) => {
        if (!previous) return previous
        const p = previous.findPerson(person.personId)
        if (p) Object.assign(p, output)
        return previous
      })
      return output
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update, isUpdating: mutation.isLoading }
}

export type RemovePersonState = {
  isRemoving: boolean
  remove: (person: Person) => Promise<void>
}
export function useRemovePerson(): RemovePersonState {
  const queryClient = useQueryClient()
  const mutation = useMutation((person: Person) => removePerson(person))
  async function remove(person: Person): Promise<void> {
    try {
      await mutation.mutateAsync(person)
      queryClient.setQueryData<Book | undefined>(bookKey(person.book), (previous) => {
        if (!previous) return previous
        const index = previous.people.findIndex((p) => p.personId === person.personId)
        if (index >= 0) previous.people.splice(index, 1)
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, remove, isRemoving: mutation.isLoading }
}

async function addPerson(person: UnsavedPerson): Promise<Person> {
  const response = await serverRequest<API.PersonAddInput, API.PersonAddSuccess>('/person/add', {
    person: person.toUnsavedModel(),
  })
  return new Person(person.book, response.person)
}

async function updatePerson(person: Person): Promise<Person> {
  const response = await serverRequest<API.PersonUpdateInput, API.PersonUpdateSuccess>('/person/update', {
    person: person.toModel(),
  })
  return new Person(person.book, response.person)
}

async function removePerson({ bookId, personId }: Person): Promise<void> {
  await serverRequest<API.PersonRemoveInput, API.PersonRemoveSuccess>('/person/remove', { bookId, personId })
}
