import { classNames, EditIcon, ProgressCircle } from '@life/components'
import { Book, Image, UploadState, useAddImage, useReplaceImage } from '@life/frontend-model'
import { ImageId } from '@life/model'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toReadableSize } from './util'

export type FileUploadAttempt = {
  file: File
  id?: string
  error?: string
}

type AdderProps = {
  book: Book
  addAttempt: FileUploadAttempt
  onCancel: (file: FileUploadAttempt) => void
}

export function FileAdder({ book, addAttempt, onCancel }: AdderProps): JSX.Element {
  const addState = useAddImage(book)
  return (
    <FileUploader uploadAttempt={addAttempt} imageId={addState.imageId} uploadState={addState} onCancel={onCancel} />
  )
}

type ReplacerProps = {
  image: Image
  replaceAttempt: FileUploadAttempt
  onCancel: (file: FileUploadAttempt) => void
}
export function FileReplacer({ image, replaceAttempt, onCancel }: ReplacerProps): JSX.Element {
  const replaceState = useReplaceImage(image)
  return (
    <FileUploader
      uploadAttempt={replaceAttempt}
      imageId={image.imageId}
      uploadState={replaceState}
      onCancel={onCancel}
    />
  )
}

type UploaderProps = {
  uploadAttempt: FileUploadAttempt
  imageId: ImageId | undefined
  uploadState: UploadState
  onCancel: (file: FileUploadAttempt) => void
}
function FileUploader({ uploadAttempt, imageId, uploadState, onCancel }: UploaderProps): JSX.Element {
  const [state, setState] = useState<'start' | 'creating' | 'complete' | 'error' | 'aborted' | undefined>(
    uploadAttempt.error ? 'error' : undefined
  )
  const [error, setError] = useState(uploadAttempt.error)
  const { upload, abort, status, error: uploadError, progress } = uploadState

  useEffect(() => {
    // state starts at undefined to make sure we don't upload multiple times!
    if (!state) {
      setState('start')
    } else if (state === 'start') {
      setState('creating')
      upload(uploadAttempt.file)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- upload may not be stable but doesn't affect this
  }, [state, uploadAttempt.file])

  useEffect(() => {
    switch (status) {
      case 'success':
        setState('complete')
        setError('')
        break
      case 'aborted':
        setState('aborted')
        setError('')
        break
      case 'error':
        setState('error')
        setError(uploadError)
        break
    }
  }, [status, uploadError])
  // End of Hooks

  type UploadContent = {
    headerBackground: 'bg-black' | 'bg-green-700' | 'bg-red-700'
    headerTransitionFrom: 'from-black' | 'from-green-700' | 'from-red-700'
    label: string
    message?: string
    icon?: JSX.Element
  }
  function style(file: FileUploadAttempt): UploadContent {
    function getLabel(): string {
      if (status === 'adding') return 'Adding image to book'
      if (progress > 0) return 'Uploading ' + progress + '%'
      return 'Starting upload'
    }
    switch (state) {
      case undefined:
        return {
          headerBackground: 'bg-black',
          headerTransitionFrom: 'from-black',
          label: '',
        }
      case 'start':
      case 'creating': {
        const content: UploadContent = {
          headerBackground: 'bg-black',
          headerTransitionFrom: 'from-black',
          label: getLabel(),
          message: 'Click below to cancel',
          icon: (
            <button
              onClick={async () => {
                abort()
                onCancel(file)
              }}
              className="absolute bottom-3 z-20 rounded-full p-2 bg-gray-200 bg-opacity-50 hover:bg-gray-500 hover:border-white"
            >
              <ProgressCircle progress={progress} className="absolute top-0 left-0" />
            </button>
          ),
        }
        return content
      }
      case 'complete':
        return {
          headerBackground: 'bg-green-700',
          headerTransitionFrom: 'from-green-700',
          label: 'Upload complete',
          icon: (
            <Link
              to={'../photos/' + imageId}
              className="absolute bottom-3 z-20 rounded-full p-2 bg-gray-200 bg-opacity-50 border-2 border-transparent hover:bg-gray-500 hover:border-white"
            >
              <EditIcon className="w-5 text-white" />
            </Link>
          ),
        }

      case 'aborted':
        return {
          headerBackground: 'bg-red-700',
          headerTransitionFrom: 'from-red-700',
          label: 'Aborted',
        }
      case 'error':
        return {
          headerBackground: 'bg-red-700',
          headerTransitionFrom: 'from-red-700',
          label: 'Error',
          message: error,
        }
    }
  }

  const fileStyle = style(uploadAttempt)

  return (
    <div className="rounded-lg bg-gray-600 m-2 p-2 w-64 h-64 flex overflow-hidden justify-center relative">
      <div className="absolute top-0 w-full z-20">
        <div className={classNames(fileStyle.headerBackground, 'h-6')} />
        <div className={classNames(fileStyle.headerTransitionFrom, 'bg-gradient-to-b h-10')} />
      </div>
      <div className="absolute top-0 w-full h-16 z-30 p-2 flex flex-row">
        <div className="flex-1">
          <p className="text-xs text-white font-semibold">{uploadAttempt.file.name}</p>
          <p className="text-[10px] text-gray-200">{toReadableSize(uploadAttempt.file.size)}</p>
        </div>
        <div className="flex-1">
          <p className="text-xs text-white text-right font-semibold">{fileStyle.label}</p>
          <p className="text-[10px] text-right text-gray-200">{fileStyle.message}</p>
        </div>
      </div>
      <img src={URL.createObjectURL(uploadAttempt.file)} className="z-10 mt-4 object-contain" />
      {fileStyle.icon}
    </div>
  )
}
