import { useAuth0 } from '@auth0/auth0-react'
import { ButtonRefresh, ButtonSave, ExternalLinkIcon, NotReady, PageHeading, PagePadding } from '@life/components'
import { useAddUser, User, useUpdateUser } from '@life/frontend-model'
import { Navigate, useNavigate } from 'react-router-dom'

/**
 * Get the unit time
 */
const LAST_UPDATE_TO_TERMS = 1661623906860
const LAST_UPDATE_TO_PRIVACY = 1652753433000

export function Start() {
  const navigate = useNavigate()
  const { user, isLoading, error } = useAddUser()
  const { isLoading: isAgreeing, update } = useUpdateUser()

  if (isLoading || error || !user)
    return (
      <div>
        <NotReady type="User" isLoading={isLoading} error={error} />
        {error && <LoginButtons />}
      </div>
    )

  const pendingAgreements = []
  if (!agreedToTerms(user)) {
    pendingAgreements.push({
      href: 'https://www.unforgotten.life/terms-of-service',
      name: 'Terms of Service',
    })
  }
  if (!agreedToPrivacy(user)) {
    pendingAgreements.push({
      href: 'https://www.unforgotten.life/privacy-policy',
      name: 'Privacy Policy',
    })
  }

  async function agreeToTerms() {
    if (!user) return
    user.agreeToTerms = Date.now()
    await update(user).then(() => {
      navigate('../', { replace: true })
    })
  }

  if (pendingAgreements.length === 0) return <Navigate to="../" replace={true} />

  return (
    <PagePadding>
      <PageHeading title="Home" back={false}>
        Welcome Back
      </PageHeading>
      <p className="py-5 max-w-prose">
        We updated our
        {pendingAgreements.map((link, index) => {
          return (
            <>
              {index > 0 && 'and'}
              <a
                key={link.name}
                href={link.href}
                target="_blank"
                rel="noreferrer"
                className="mx-1 underline text-indigo-600 hover:text-indigo-700 hover:no-underline inline-flex"
              >
                {link.name}
                <ExternalLinkIcon className="w-4" />
              </a>
            </>
          )
        })}
        on {new Date(LAST_UPDATE_TO_TERMS).toLocaleDateString()}. Please review and click the button below to continue.
      </p>
      <ButtonSave
        clicked={isAgreeing}
        onClick={() => {
          agreeToTerms()
        }}
      >
        I Agree to
        {pendingAgreements.map((link, index) => {
          return (
            <>
              {index > 0 && 'and'}
              <span className="mx-1">{link.name}</span>
            </>
          )
        })}
      </ButtonSave>
    </PagePadding>
  )
}

function agreedToTerms(user?: User): boolean {
  const agreeToTerms = user?.agreeToTerms
  if (agreeToTerms === undefined) return false
  return agreeToTerms >= LAST_UPDATE_TO_TERMS
}

function agreedToPrivacy(user?: User): boolean {
  const agreeToTerms = user?.agreeToTerms
  if (agreeToTerms === undefined) return false
  return agreeToTerms >= LAST_UPDATE_TO_PRIVACY
}

/**
 * Sometimes we fail to login properly. I haven't figured out why. There's most likely a bug or race condition somewhere.
 * If this happens, we display some login buttons below to help them navigate manually.
 */
function LoginButtons(): JSX.Element {
  const auth = useAuth0()

  return (
    <div className="text-center w-full space-x-5">
      <ButtonRefresh
        onClick={() => {
          auth.logout({
            returnTo: window.location.origin,
          })
        }}
      >
        Sign Out
      </ButtonRefresh>
      <ButtonRefresh
        onClick={() => {
          auth.loginWithRedirect()
        }}
      >
        Sign In
      </ButtonRefresh>
    </div>
  )
}
