import {
  BookIcon,
  CameraIcon,
  DashboardIcon,
  OutlineIcon,
  SearchIcon,
  SettingsIcon,
  ShareIcon,
  StoryIcon,
  SwitchIcon,
  UserIcon,
  UsersIcon,
} from '@life/components'
import { Book, User } from '@life/frontend-model'
import React from 'react'

export type ItemLink = {
  name: string
  subText?: string
  href: string
  future?: boolean
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  current?: boolean
}

export function buildGeneralMenu(user?: User): ItemLink[] {
  const path = new URL(window.location.href).pathname

  function buildProfileLink(): ItemLink {
    const link: ItemLink = {
      name: 'Loading...',
      subText: 'Profile',
      href: '/profile',
      icon: UserIcon,
      current: path.startsWith('/profile'),
    }

    if (user) {
      if (user.givenNames && user.lastName) link.name = user.givenNames + ' ' + user.lastName
      else link.name = 'Your Name Here'
    }
    return link
  }

  return [
    {
      name: 'Switch Books',
      href: '/',
      icon: SwitchIcon,
      current: path === '/',
    },
    buildProfileLink(),
  ]
}

export function buildBookMenu(book?: Book): ItemLink[] {
  if (!book) return []
  const path = new URL(window.location.href).pathname

  const root = '/book/' + (book.slug || book.bookId)
  return [
    {
      name: 'Dashboard',
      href: root,
      icon: DashboardIcon,
      current: path === root,
    },
    {
      name: 'Search',
      href: root + '/search',
      icon: SearchIcon,
      current: path.startsWith(root + '/search'),
    },
    {
      name: 'Outline',
      href: root + '/outline',
      icon: OutlineIcon,
      current: path.startsWith(root + '/outline'),
    },
    {
      name: 'Stories',
      href: root + '/stories',
      icon: StoryIcon,
      current: path.startsWith(root + '/stories'),
    },
    {
      name: 'Photos',
      href: root + '/photos',
      icon: CameraIcon,
      current: path.startsWith(root + '/photos'),
    },
    {
      name: 'People',
      href: root + '/people',
      icon: UsersIcon,
      current: path.startsWith(root + '/people'),
    },
    // {
    //   name: 'Locations',
    //   href: root + '/locations',
    //   icon: MapIcon,
    //   current: path.startsWith(root + '/locations'),
    //   future: true,
    // },
    // {
    //   name: 'Things',
    //   href: root + '/things',
    //   icon: BriefcaseIcon,
    //   current: path.startsWith(root + '/things'),
    //   future: true,
    // },
    {
      name: 'Book',
      href: root + '/book',
      icon: BookIcon,
      current: path.startsWith(root + '/book'),
    },
    {
      name: 'Sharing',
      href: root + '/sharing',
      icon: ShareIcon,
      current: path.startsWith(root + '/sharing'),
    },
    {
      name: 'Settings',
      href: root + '/settings',
      icon: SettingsIcon,
      current: path.startsWith(root + '/settings'),
    },
  ]
}
