import { DocumentAddIcon, MoveLeftIcon } from '@life/components'

type Props = {
  onClick: VoidFunction
  index: number
}

export function InsertStory({ onClick, index }: Props): JSX.Element {
  return (
    <div className="cursor-pointer h-4 group flex items-center" onClick={onClick}>
      <div className="items-center text-green-800 space-x-2 hidden group-hover:flex">
        <DocumentAddIcon className="h-4 w-4" />
        <span className="">{index}</span>
        <MoveLeftIcon className="h-6" />
      </div>
      <div className="w-6 group-hover:hidden"></div>
      <div className="border border-dashed flex-1 group-hover:border-green-800"></div>
    </div>
  )
}
