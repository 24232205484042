import { Logger, StoryElement, StoryText } from '@life/model'
import { Descendant, Transforms } from 'slate'
import { jsx } from 'slate-hyperscript'
import { StoryEditor } from './types'

/**
 * Handles inserting data from the clipboard into the Slate editor.
 * First attempts to insertFragmentData (which handles the Slate clipboard),
 * then looks for rich text in the clipboard ('text/html'), then tries
 * plain text ('text'), and finally reverts to the standard Slate insertData.
 *
 * Some code clean-up is performed:
 * - Double-returns will be collapsed into single returns.
 */

const logger = new Logger('edit-paste')

export function insertData(editor: StoryEditor, data: DataTransfer): void {
  const { insertFragmentData, insertTextData, insertData } = editor
  if (insertFragmentData(data)) return
  const html = data.getData('text/html')
  if (html) {
    logger.info('html', html)
    const parsed = new DOMParser().parseFromString(html, 'text/html')
    const fragment = deserialize(parsed.body)
    if (fragment) {
      logger.info('fragment', fragment)
      Transforms.insertFragment(editor, fragment as Descendant[])
    } else {
      insertData(data)
    }
    return
  }
  const text = data.getData('text')
  if (text) {
    // If text uses double-return to delimit paragraphs, replace with a single return
    const dt = new DataTransfer()
    dt.setData('text/plain', text.replace(/\n\n/g, '\n'))
    insertTextData(dt)
    return
  }
  insertData(data)
}

const ELEMENT_TAGS: Record<string, (el?: HTMLElement) => StoryElement> = {
  H1: () => ({ type: 'paragraph', children: [{ text: '' }] }),
  H2: () => ({ type: 'paragraph', children: [{ text: '' }] }),
  H3: () => ({ type: 'paragraph', children: [{ text: '' }] }),
  H4: () => ({ type: 'paragraph', children: [{ text: '' }] }),
  H5: () => ({ type: 'paragraph', children: [{ text: '' }] }),
  H6: () => ({ type: 'paragraph', children: [{ text: '' }] }),
  P: () => ({ type: 'paragraph', children: [{ text: '' }] }),
}

// COMPAT: `B` is a weird tag in Google Docs. Try to detect it and ignore it.
const TEXT_TAGS: Record<string, (el?: HTMLElement) => Partial<StoryText> | null> = {
  CODE: () => ({ code: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  B: (el?: HTMLElement) => (el?.getAttribute?.('style') === 'font-weight:normal;' ? null : { bold: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

function deserialize(
  el: ChildNode | HTMLElement
): (Descendant | StoryElement | string | null)[] | StoryElement | string | null {
  if (el.nodeType === 3) {
    return el.textContent?.replace(/\n/g, '') || null
  } else if (el.nodeType !== 1) {
    return null
  }

  const { nodeName } = el
  const children = Array.from(el.childNodes).map(deserialize).flat()

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el as HTMLElement)
    return children.length > 0 ? jsx('element', attrs, children) : null
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el as HTMLElement)
    if (attrs) return children.map((child) => jsx('text', attrs, child))
  }

  return children
}
