import { ButtonCancel, ButtonSave, DatesForm, HelpText, LifeDialog } from '@life/components'
import { LifeDate, StoryDate } from '@life/model'
import { useState } from 'react'
import { FieldError } from 'react-hook-form'

type OccurredDialogProps = {
  key: string // Trick to force dialog to reset state when it's opened. Should be set to String(isOpen)
  isOpen: boolean
  occurred: StoryDate | undefined
  onSave: (occurred: StoryDate | undefined) => void
  onClose: () => void
}
export function OccurredDialog({ isOpen, occurred, onSave, onClose }: OccurredDialogProps): JSX.Element {
  const [value, setValue] = useState(occurred)
  const [error, setError] = useState<FieldError>()
  function handleChange(occurred: LifeDate | undefined, error: string | undefined): void {
    setValue(occurred as StoryDate)
    if (error) setError({ type: 'validate', message: error })
    else setError(undefined)
  }

  const shortcuts = { Escape: onClose, Enter: () => !error && onSave(value) }
  return (
    <LifeDialog modal isOpen={isOpen} title="Edit Story Date(s)" onClose={onClose} shortcuts={shortcuts}>
      <LifeDialog.Content className="w-full sm:w-96">
        <DatesForm types={['single', 'range']} optional defaultValue={occurred} onChange={handleChange} />
        <HelpText error={error}>
          When did this story occur? This date or date range will be used to suggest story order along with
          crosschecking against dates for people, places, and things in your story.
        </HelpText>
      </LifeDialog.Content>
      <LifeDialog.Actions>
        <ButtonCancel onClick={onClose} />
        <ButtonSave onClick={() => onSave(value)} disabled={!!error}>
          OK
        </ButtonSave>
      </LifeDialog.Actions>
    </LifeDialog>
  )
}
