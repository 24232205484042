import { BookId } from '../book'
import { AuditProps, DateRange, DateSingle } from '../common'
import { ImageId } from '../image'
import { LocationId } from '../location'
import { PersonId } from '../person'
import { ThingId } from '../thing'

export type StoryId = string
export type StoryDate = DateSingle | DateRange
export type StoryStatusType = typeof StoryStatus[number]
export const StoryStatus = ['stub', 'draft', 'complete', 'approved'] as const

export type StoryParagraphType = typeof StoryParagraphs[number]
export const StoryParagraphs = ['paragraph', 'task', 'image', 'substory'] as const

export type UnsavedStory = {
  bookId: BookId
  title: string
  status: StoryStatusType
  content: StoryContent[]
  occurred?: StoryDate
}

export type Story = UnsavedStory &
  AuditProps & {
    version: number
    storyId: StoryId
  }

/**
 * @see https://docs.slatejs.org/api/nodes/node
 */
export type StoryContent = StoryText | StoryElement

export type StoryText = {
  text: string
  bold?: true
  code?: true
  italic?: true
  underline?: true
}
export type StoryNoun = StoryPerson | StoryLocation | StoryThing
/** all story content except Text */
export type StoryElement = StoryNoun | StoryComment | StoryBlockElement

/** elements that are not inline and take up an entire paragraph or "block" */
export type StoryBlockElement = StoryParagraph | StorySubstory | StoryImage | StoryTask

export type StorySubstory = { type: 'substory'; id: StoryId; children: StoryText[] }
export type StoryTask = { type: 'task'; children: StoryText[] }
export type StoryComment = { type: 'comment'; children: StoryText[] }
export type StoryParagraph = { type: 'paragraph'; children: (StoryNoun | StoryText)[]; leftPadding?: number }

// 'linkId' in the following is used during web rendering to create a stable link element
export type StoryPerson = { type: 'person'; id: PersonId; linkId?: string; children: StoryText[] }
export type StoryLocation = { type: 'location'; id: LocationId; linkId?: string; children: StoryText[] }
export type StoryThing = { type: 'thing'; id: ThingId; linkId?: string; children: StoryText[] }

export type StoryImage = {
  type: 'image'
  id: ImageId
  caption?: string
  size?: ImageSize
  linkId?: string
  children: StoryText[]
}

export type ImageSize = 'large' | 'medium' | 'small'
