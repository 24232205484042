import { useEffect } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { classNames } from '.'
import { BackIcon } from './icons'
import { WithChildren } from './types'

type Props = {
  /** The browser page title */
  title: string | string[]
  back?: boolean
  right?: JSX.Element
} & WithChildren

export function PageHeading({ className = '', children, title, right, back = true }: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  usePageTitle(title)

  return (
    <div className={classNames(className, 'flex space-x-2 align-middle items-center')}>
      {back && (
        <button
          className="bg-slate-200 rounded p-1"
          type="button"
          onClick={() => {
            navigate(-1)
          }}
        >
          <BackIcon className="h-6" />
        </button>
      )}
      <h1 className="flex-1 text-3xl font-bold text-gray-800 leading-none">{children}</h1>
      {right}
    </div>
  )
}

export function usePageTitle(title: string | string[] | undefined): void {
  useEffect(() => {
    // Set the document title on mount
    const oldTitle = document.title
    if (title) {
      document.title = buildTitle(title)
    }
    return () => {
      // Restore the old title on unmount
      document.title = oldTitle
    }
  }, [title])
}

function buildTitle(title: string | string[]): string {
  const MAX_PART_LENGTH = 20
  function formatPart(part: string): string {
    if (part.length < MAX_PART_LENGTH) return part
    return part.slice(0, MAX_PART_LENGTH) + '…'
  }
  function composeTitle(parts: string[]): string {
    const stage = process.env.REACT_APP_STAGE
    const appName = 'Unforgotten Life' + (stage && stage !== 'prod' ? ` (${stage})` : '')
    return [...parts, appName].join(' • ')
  }
  if (!Array.isArray(title)) return composeTitle([formatPart(title)])
  const parts: string[] = []
  title.forEach((part) => parts.push(formatPart(part)))
  return composeTitle(parts)
}
