import { Story } from '@life/frontend-model'
import { Logger, StoryElement, StoryText } from '@life/model'
import React, { ReactNode, useState } from 'react'
import { createEditor, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, withReact } from 'slate-react'
import { cacheCaptions } from './caption-cache'
import { KeyDown, ToolbarActionEvent, ToolbarWithEditor } from './Toolbar'
import { StoryEditor } from './types'
import { useEditorConfig } from './useEditorConfig'

const logger = new Logger('editor')

// Required by Slate to support custom types in TypeScript
// See https://docs.slatejs.org/concepts/12-typescript
declare module 'slate' {
  interface CustomTypes {
    Editor: StoryEditor
    Element: StoryElement
    Text: StoryText
  }
}

function createEditorWithPlugins(): StoryEditor {
  // I find this syntax to be strange, so I'm hiding the ugly
  // To create an editor with plugins you wrap each plugin within each other
  // https://docs.slatejs.org/libraries/slate-react#withreact-editor-editor
  return withReact(withHistory(createEditor()))
}

type Props = {
  story: Story
  className?: string
  initial: Descendant[]
  onChange?: (value: Descendant[]) => void
  onBlur?: () => void
}

export function Editor({ initial, story, onChange, onBlur }: Props) {
  const [editor] = useState(createEditorWithPlugins())
  const [handleAction, setHandleAction] = useState<ToolbarActionEvent>()
  const [handleKeydown, setHandleKeyDown] = useState<KeyDown>()
  const { renderElement, renderLeaf } = useEditorConfig(editor, story.book, handleAction)

  cacheCaptions(initial)

  return (
    <EditorErrorBoundary>
      <Slate editor={editor} value={initial} onChange={onChange}>
        <ToolbarWithEditor
          story={story}
          editor={editor}
          setHandleKeyDown={setHandleKeyDown}
          setHandleAction={setHandleAction}
        />
        <Editable
          className="flex-1 p-3 bg-white text-lg border-2 border-gray-300 overflow-y-scroll"
          onBlur={onBlur}
          autoFocus={true}
          placeholder="Write your story here..."
          onKeyDown={handleKeydown}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    </EditorErrorBoundary>
  )
}

/** Error Boundary to display error when editing. Copied from React docs. */
class EditorErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    logger.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <div>Error editing Story. Please reload the page.</div>
    }
    return this.props.children
  }
}
