// See @life/model story/util.ts for more story utility functions
import { Story } from '@life/frontend-model'

export type StorySortType = 'title' | 'date' | 'status' | 'no-people'
export function sortStories(stories: Story[], sortField: StorySortType): void {
  stories.sort((a, b) => {
    switch (sortField) {
      case 'title':
        return a.fullTitle.localeCompare(b.fullTitle)
      case 'date': {
        const aDate = storyDate(a)
        const bDate = storyDate(b)
        if (!aDate) return bDate ? 1 : -1
        if (!bDate) return aDate ? -1 : 1
        return aDate - bDate
      }
      case 'status':
        return a.status.localeCompare(b.status)
      case 'no-people':
        return a.people.length - b.people.length
    }
  })
}

function storyDate(story: Story): number | undefined {
  if (story.occurred === undefined) return
  const startDate = story.occurred.type === 'single' ? story.occurred : story.occurred.start
  if (startDate === undefined) return
  const date = new Date()
  date.setFullYear(startDate.year)
  if (startDate.month) date.setMonth(startDate.month)
  if (startDate.day) date.setDate(startDate.day)
  return date.getTime()
}
