import { classNames, Spinner, useOptionalParam } from '@life/components'
import { useBook, useUser } from '@life/frontend-model'
import { Slug } from '@life/model'
import { Link } from 'react-router-dom'
import { buildBookMenu, buildGeneralMenu, ItemLink } from './buildMenu'

type Props = {
  close: VoidFunction
}

export function MobileMenu({ close }: Props): JSX.Element {
  const { user } = useUser()
  const bookSlug = useOptionalParam('bookSlug')
  const navigation = buildGeneralMenu(user)

  return (
    <div>
      <div className="">{bookSlug && <BookMenu bookSlug={bookSlug} close={close} />}</div>
      <div className="border-t border-indigo-800">
        <TwoColumns items={navigation} close={close} />
      </div>
    </div>
  )
}

type BookMenuType = Props & {
  bookSlug: Slug
}

function BookMenu({ bookSlug, close }: BookMenuType): JSX.Element {
  const { book, error, isLoading } = useBook(bookSlug)

  if (isLoading)
    return (
      <div className="flex flex-col items-center py-5">
        <Spinner size={2} className="text-gray-200" />
        <div className="text-gray-200 pt-2">Loading Book</div>
      </div>
    )
  if (error)
    return (
      <div className="flex flex-col items-center py-5">
        <div className="text-red-400 text-bold pt-2">Failed to load book</div>
      </div>
    )

  const navigation = buildBookMenu(book)
  return <TwoColumns items={navigation} close={close} />
}

type TwoColumnsType = Props & {
  items: ItemLink[]
}

function TwoColumns({ items, close }: TwoColumnsType): JSX.Element {
  const breakpoint = Math.ceil(items.length / 2)
  return (
    <div className="flex flex-row p-2 space-x-2">
      <div className="flex-1">
        {items.slice(0, breakpoint).map((item) => (
          <SideLink key={item.name} item={item} close={close} />
        ))}
      </div>
      <div className="flex-1">
        {items.slice(breakpoint).map((item) => (
          <SideLink key={item.name} item={item} close={close} />
        ))}
      </div>
    </div>
  )
}

type SideLinkProp = Props & {
  item: ItemLink
}

function SideLink({ item, close }: SideLinkProp): JSX.Element {
  return (
    <Link
      to={item.href}
      onClick={() => {
        setTimeout(() => {
          close()
        }, 200)
      }}
      className={classNames(
        item.current ? 'bg-gray-200 text-gray-900' : item.future ? 'line-through text-gray-400' : 'text-gray-200',
        'flex flex-row items-center p-2 text-base font-medium rounded-md'
      )}
    >
      <item.icon className={classNames('mr-3 h-6 w-6 rounded-full')} aria-hidden="true" />
      {item.subText ? (
        <div>
          <p className="text-sm">{item.name}</p>
          <p className="text-xs">{item.subText}</p>
        </div>
      ) : (
        <p>{item.name}</p>
      )}
    </Link>
  )
}
