import { ImageId } from '../image'
import {
  Story,
  StoryBlockElement,
  StoryContent,
  StoryElement,
  StoryId,
  StoryImage,
  StoryLocation,
  StoryParagraph,
  StoryPerson,
  StoryStatus,
  StorySubstory,
  StoryTask,
  StoryText,
  StoryThing,
  UnsavedStory,
} from './story'

export function isStoryText(el: StoryContent | undefined): el is StoryText {
  return !(el as StoryElement)?.type && (el as StoryText).text !== undefined
}
export function isStoryElement(el: StoryContent | undefined): el is StoryElement {
  return !!(el as StoryElement)?.type
}
export function isStorySubstory(el: StoryContent | undefined): el is StorySubstory {
  return isStoryElement(el) && el.type === 'substory'
}
export function isStoryParagraph(el: StoryContent | undefined): el is StoryParagraph {
  return isStoryElement(el) && el.type === 'paragraph'
}
export function isStoryTask(el: StoryContent | undefined): el is StoryTask {
  return isStoryElement(el) && el.type === 'task'
}
export function isStoryPerson(el: StoryContent | undefined): el is StoryPerson {
  return isStoryElement(el) && el.type === 'person'
}
export function isStoryLocation(el: StoryContent | undefined): el is StoryLocation {
  return isStoryElement(el) && el.type === 'location'
}
export function isStoryThing(el: StoryContent | undefined): el is StoryThing {
  return isStoryElement(el) && el.type === 'thing'
}
export function isStoryImage(el: StoryContent | undefined): el is StoryImage {
  return isStoryElement(el) && el.type === 'image'
}

export function isStoryBlock(el: StoryContent | undefined): el is StoryBlockElement {
  return isStoryElement(el) && ['paragraph', 'substory', 'image', 'task'].includes(el.type)
}

export function isStoryContentHasChildren(el: StoryContent): el is StoryElement {
  if (Array.isArray((el as StoryElement).children)) return true
  return false
}

export function validateStory(story: UnsavedStory): string | undefined {
  if (!story.bookId?.trim()) return 'Missing bookId'
  if (!story.title?.trim()) return 'Missing title'
  if (!StoryStatus.includes(story.status)) return 'Illegal status'
  if (!Array.isArray(story.content)) throw new Error('Illegal content')
  if (story.occurred && !['single', 'range'].includes(story.occurred.type)) return 'Illegal occurred'
  return undefined
}

export function getImagesFromStory(story: Story): ImageId[] {
  const imageIds: ImageId[] = []

  function getIdsFromContent(contents: StoryContent[]): void {
    contents.forEach((content) => {
      if (isStoryImage(content)) {
        imageIds.push(content.id)
        return
      }
      if (isStoryContentHasChildren(content)) getIdsFromContent(content.children)
    })
  }
  getIdsFromContent(story.content)
  return imageIds
}

export function createParagraph(): StoryParagraph {
  return {
    type: 'paragraph',
    children: createEmptyText(),
  }
}

export function createTask(): StoryTask {
  return {
    type: 'task',
    children: createEmptyText(),
  }
}

export function createSubstory(id: StoryId): StorySubstory {
  return {
    type: 'substory',
    id,
    children: createEmptyText(),
  }
}

export function createEmptyText(): StoryText[] {
  return [
    {
      text: '',
    },
  ]
}
