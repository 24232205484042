import { Story } from '@life/frontend-model'
import { StorySubstory } from '@life/model'
import { useStoryMarker } from './ScrollState'
import { ViewContent } from './ViewContent'

type SubstoryProps = {
  story: Story
  section: StorySubstory
  depth: number
}
export function ViewSubstory({ story, section, depth }: SubstoryProps): JSX.Element {
  const subStory = story.book.findStory(section.id)
  if (!subStory) return <div className="text-red-700">Missing child story ({section.id})</div>
  return (
    <>
      {depth === 1 && <ViewSection story={subStory} depth={depth + 1} />}
      {depth >= 2 && <ViewSubsection story={subStory} depth={depth + 1} />}
    </>
  )
}

type SectionProps = {
  story: Story
  depth?: number
}
export function ViewChapter({ story, depth = 1 }: SectionProps): JSX.Element {
  const attrs = useStoryMarker<HTMLDivElement>(story.storyId)
  return (
    <div {...attrs} className="p-2 border-b-2 border-slate-600 border-double">
      <div className="text-2xl font-bold py-10">{story.title}</div>
      <ViewContent story={story} content={story.content} depth={depth} linkIndex={0} />
    </div>
  )
}

export function ViewSection({ story, depth = 2 }: SectionProps): JSX.Element {
  const attrs = useStoryMarker<HTMLDivElement>(story.storyId)
  return (
    <div {...attrs}>
      <div className="text-lg font-bold border-b-2 border-slate-600">{story.title}</div>
      <ViewContent story={story} content={story.content} depth={depth} linkIndex={0} />
    </div>
  )
}

export function ViewSubsection({ story, depth = 3 }: SectionProps): JSX.Element {
  const attrs = useStoryMarker<HTMLDivElement>(story.storyId)
  return (
    <div {...attrs}>
      <div className="font-bold">{story.title}</div>
      <ViewContent story={story} content={story.content} depth={depth} linkIndex={0} />
    </div>
  )
}
