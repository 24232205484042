import { Book } from './book'

/** Base class for Unsaved Book Items. */
export abstract class UnsavedBookItem {
  readonly book: Book

  constructor(book: Book) {
    this.book = book
  }

  get bookId(): string {
    return this.book.bookId
  }
  abstract get key(): string

  abstract toUnsavedModel(): unknown
}

/** Mixin interface for saved Book Items. */
export interface BookItem {
  get key(): string
  toModel(): unknown
}
