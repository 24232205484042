import { NotReady, PageHeading, PagePadding, useRequiredParam } from '@life/components'
import { useBook, useReplaceImage } from '@life/frontend-model'
import { Logger } from '@life/model'
import { getEditorDefaults, ProgressCallback } from '@pqina/pintura'
import '@pqina/pintura/pintura.css'
import { PinturaEditor } from '@pqina/react-pintura'

const logger = new Logger('pintura')

export function Pintura(): JSX.Element {
  const [bookSlug, imageId] = useRequiredParam(['bookSlug', 'imageId'])
  const { book, isLoading, error } = useBook(bookSlug)
  const image = book?.findImage(imageId)
  const { upload } = useReplaceImage(image)
  // End of Hooks

  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />
  if (!image) return <NotReady type="Image" id={imageId} notFound />

  const editorConfig = getEditorDefaults({
    // See PinturaDefaultImageWriterOptions
    imageWriter: {
      // TODO This section needs more work. It's not using any of the information from useUploadImage and tries to fake progress
      //  ((imageState: any, options: any, onprogress: ProgressCallback) => Promise<any>);
      //  { url: string; dataset?: (imageState: any) => PinturaStoreField[] }
      store: (imageState: { dest: File }, options: unknown, onprogress: ProgressCallback) =>
        new Promise((resolve) => {
          logger.info('store', imageState, options)

          upload(imageState.dest)

          // Show S3 upload progress in UI
          const event = new ProgressEvent('progress', {
            lengthComputable: true,
            loaded: 500,
            total: 1000,
          })
          onprogress(event)
          resolve('DONE')
        }),
    },
    locale: { labelButtonExport: 'Save' },
  })

  return (
    <PagePadding className="flex flex-col" wide>
      <PageHeading title="Edit Photo">Edit Photo</PageHeading>
      <div className="w-full flex-1">
        <PinturaEditor {...editorConfig} src={image.originalUrl}></PinturaEditor>
      </div>
    </PagePadding>
  )
}
