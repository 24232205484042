import { Image } from '@life/frontend-model'
import { Logger } from '@life/model'
import React, { ReactNode, Suspense } from 'react'
import { useImage } from 'react-image'
import { classNames } from './classNames'
import { Spinner } from './Spinner'

const logger = new Logger('component-s3image')

type ImageProps = {
  image: Image
  /** Image size to prefer. Defaults to 'thumb'. */
  size?: 'thumb' | 'web' | 'original'
  alt?: string
  className?: string
}
export function S3ImageView({ image, size = 'thumb', alt, className }: ImageProps): JSX.Element {
  function ImageInternal(): JSX.Element {
    // Check that the image was actually loaded correctly from backend
    if (!image.thumbUrl || !image.webUrl || !image.originalUrl) throw new Error('Image not loaded correctly')
    let imageList
    switch (size) {
      case 'thumb':
        imageList = [image.thumbUrl, image.webUrl, image.originalUrl]
        break
      case 'web':
        imageList = [image.webUrl, image.originalUrl, image.thumbUrl]
        break
      case 'original':
        imageList = [image.originalUrl, image.webUrl, image.thumbUrl]
        break
    }
    const { src } = useImage({
      srcList: imageList,
    })
    return <img src={src} alt={alt} className={classNames(className, 'bg-gray-500')} />
  }
  return (
    <ImageErrorBoundary>
      <Suspense
        fallback={
          <div className={classNames('flex justify-center items-center h-20', className)}>
            <Spinner dark />
          </div>
        }
      >
        <ImageInternal />
      </Suspense>
    </ImageErrorBoundary>
  )
}

/** Error Boundary to display error when reading image. Copied from React docs. */
class ImageErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    logger.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <div>Error loading image.</div>
    }
    return this.props.children
  }
}
