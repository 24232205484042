import { API, BookId, StoryId } from '@life/model'
import { useQuery, UseQueryResult } from 'react-query'
import { serverBaseUrl, serverRequest } from './api'
import { Book } from './book'
import { PdfInfo } from './pdfinfo'

const pdfInfoKey = (bookId: BookId) => `BOOK_PDF:${bookId}`

export type BookPdfState = {
  isLoading: boolean
  error?: API.GetErrors
  pdfInfo?: PdfInfo
  refetch: UseQueryResult<PdfInfo, API.GetErrors>['refetch']
}
export function useGetPdfInfo(book: Book): BookPdfState {
  const query = useQuery<PdfInfo, API.GetErrors>(pdfInfoKey(book.bookId), () => readPdfBook(book), {
    retry: (count, error) => {
      // Don't retry if NotFound but do if another error
      if (error.error === 'NotFound') return false
      return count < 2
    },
  })
  return { ...query, error: query.error ?? undefined, pdfInfo: query.data }
}

async function readPdfBook(book: Book): Promise<PdfInfo> {
  const response = await serverRequest<API.BookPdfGetInput, API.BookPdfGetSuccess>('/book/pdf/get', {
    bookId: book.bookId,
  })
  return new PdfInfo(book, response.pdfInfo)
}

export async function createPdfBookInS3(book: Book): Promise<PdfInfo> {
  const result = await serverRequest<API.BookPdfCreateInput, API.BookPdfCreateSuccess>('/book/pdf/create', {
    bookId: book.bookId,
  })
  return new PdfInfo(book, result.pdfInfo)
}

/**
 * Calls the backend to get the temporary preview url for this story
 */
export async function readPdfPreviewUrl(bookId: BookId, storyId: StoryId): Promise<string> {
  const response = await serverRequest<API.BookPdfPreviewUrlInput, API.BookPdfPreviewUrlSuccess>(
    '/book/pdf/preview/url',
    { bookId, storyId }
  )
  return `${serverBaseUrl}${response.uri}`
}
