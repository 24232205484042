import { Route, Routes } from 'react-router-dom'
import {
  AcceptInvite,
  AddBook,
  AddImage,
  AddPerson,
  AddStory,
  BookDashboard,
  ChapterEditor,
  EditBook,
  EditPerson,
  EditStory,
  ImagePage,
  ImagesController,
  PdfController,
  PeopleController,
  PersonManager,
  PersonPage,
  Pintura,
  ProfileController,
  ReplaceImage,
  SearchController,
  SharingController,
  SiteController,
  Start,
  StoriesController,
  StoryPage,
  SwitchBooks,
  ViewInvite,
} from '../domain'
import NotFound from './404'
import { LayoutWithAuth } from './LayoutWithAuth'
import { LayoutTopNav } from './nav'

export default function LifeRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<LayoutWithAuth />}>
        <Route path="start" element={<Start />} />
        <Route path="accept/:bookSlug/:inviteId" element={<AcceptInvite />} />
        <Route>
          <Route path="" element={<LayoutTopNav />}>
            <Route path="" element={<SwitchBooks />} />
            <Route path="profile" element={<ProfileController />} />
            <Route path="/book/_add" element={<AddBook />} />
            <Route path="/book/:bookSlug">
              <Route path="" element={<BookDashboard />} />
              <Route path="settings" element={<EditBook />} />
              <Route path="search" element={<SearchController />} />
              <Route path="book" element={<PdfController />} />
              <Route path="outline" element={<ChapterEditor />} />
              <Route path="stories" element={<StoriesController />} />
              <Route path="stories/_add" element={<AddStory />} />
              <Route path="stories/:storyId" element={<StoryPage />} />
              <Route path="stories/:storyId/edit" element={<EditStory />} />
              <Route path="photos" element={<ImagesController />} />
              <Route path="photos/_add" element={<AddImage />} />
              <Route path="photos/:imageId" element={<ImagePage />} />
              <Route path="photos/:imageId/edit" element={<Pintura />} />
              <Route path="photos/:imageId/replace" element={<ReplaceImage />} />
              <Route path="photos/:imageId/people" element={<PersonManager />} />
              <Route path="people" element={<PeopleController />} />
              <Route path="people/_add" element={<AddPerson />} />
              <Route path="people/:personId" element={<PersonPage />} />
              <Route path="people/:personId/edit" element={<EditPerson />} />
              <Route path="sharing" element={<SharingController />} />
            </Route>
          </Route>
          <Route path="/site/:bookSlug" element={<SiteController />} />
        </Route>
      </Route>
      <Route path="invite/:bookSlug/:inviteId" element={<ViewInvite />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
