import { useEffect, useRef } from 'react'

/**
 * A hook that returns the previous value.
 * Useful for gaining the previous value of a property or state.
 * Example:
 * function Foo({ v }) {
 *   const prev = usePrevious(v)
 *   // prev contains value of v before it changed
 *   // v contains the current value
 * }
 */
export function usePrevious<T>(value: T): T | undefined {
  const prev = useRef<T>()
  useEffect(() => {
    prev.current = value
  }, [value])
  return prev.current
}
