import { useCallback, useRef, useState } from 'react'
import { Logger } from '@life/model'

const logger = new Logger('api-upload')

export type UploadStatus = 'idle' | 'uploading' | 'success' | 'error' | 'aborted' | 'adding' | 'gettingUrl'
export type UploadState = {
  upload: (file: File) => Promise<void>
  status: UploadStatus
  progress: number
  abort: VoidFunction
  error?: string
}

export type UploadFileState = {
  upload: (url: string, extension: string, file: File) => void
  abort: VoidFunction
  status: UploadStatus
  progress: number
}
export function useUploadFile(): UploadFileState {
  const [status, setStatus] = useState<UploadStatus>('idle')
  const [progress, setProgress] = useState(0)

  const requestRef = useRef(new XMLHttpRequest())
  const request = requestRef.current

  const upload = useCallback(
    (url: string, contentType: string, file: File): void => {
      setStatus('uploading')
      function isError(event: Event): boolean {
        const status = (event.target as XMLHttpRequest)?.status ?? -1
        return status !== 0 && (status < 200 || status >= 400)
      }
      function handleSuccess(event: Event) {
        if (!isError(event)) setStatus('success')
      }
      function handleError(event: Event) {
        logger.error('useUploadFile:upload handleError:', event)
        setStatus('error')
      }
      function handleReadyStateChange(event: Event) {
        if (isError(event)) {
          logger.error('useUploadFile:upload handleReadyStateChange:', event)
          setStatus('error')
        }
      }
      function handleAbortResponse() {
        setStatus('aborted')
      }
      function handleProgress(event: ProgressEvent) {
        const { total, loaded } = event
        setProgress(Math.round((100 * loaded) / total))
      }
      request.addEventListener('load', handleSuccess, false)
      request.addEventListener('error', handleError, false)
      request.addEventListener('readystatechange', handleReadyStateChange, false)
      request.addEventListener('abort', handleAbortResponse, false)
      request.upload.addEventListener('progress', handleProgress, false)
      request.open('PUT', url, true)
      request.setRequestHeader('Access-Control-Allow-Origin', window.location.origin)
      request.setRequestHeader('Content-Type', contentType)
      request.send(file)
    },
    [request]
  )
  const abort = useCallback((): void => {
    request.abort()
  }, [request])
  return {
    upload,
    abort,
    status,
    progress,
  }
}
