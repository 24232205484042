import { API, BookId } from '@life/model'
import { useCallback, useEffect, useState } from 'react'
import { QueryClient, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { bookKey } from './book-api'
import { UploadState, useUploadFile } from './upload'

export const docxContentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

/**
 * Manages the process of importing a document.
 * This is complicated by the fact that upload is asynchronous and can't be 'await'ed.
 * The approach taken here is to maintain an internal state that transitions from
 *   'idle' -> 'gettingUrl' -> 'uploading' -> 'importing' -> 'completed'
 * The state transitions can be stopped at any point by an error.
 * Note: This seems like a good use case for 'useReducer', but that turned out to
 * be complicated since some of the actions are asynchronous and should be 'await'ed.
 * So the code manages state transitions itself, in `useEffect`.
 */
export type ImportStatus =
  | 'idle'
  | 'gettingUrl'
  | 'uploading'
  | 'importing'
  | 'completed'
  | 'error'
  | 'timedout'
  | 'aborted'

type ImportDocState = Omit<UploadState, 'upload' | 'status'> & {
  status: ImportStatus
  importDoc: UploadState['upload']
  stats?: API.BookImportStats
}
export function useImportDoc(book: Book): ImportDocState {
  const [state, setState] = useState<ImportStatus>('idle')
  const [file, setFile] = useState<File>()
  const [error, setError] = useState<string>()
  const [stats, setStats] = useState<API.BookImportStats>()
  const queryClient = useQueryClient()
  const { upload, abort, status: uploadStatus, progress } = useUploadFile()

  // This useEffect manages state transitions.
  useEffect(() => {
    switch (uploadStatus) {
      case 'aborted':
        setState('aborted')
        return
      case 'error':
        setState('error')
        setError('Error uploading file')
        return
      case 'uploading':
        setState('uploading')
        return
      case 'success':
        if (state === 'uploading' && file) {
          setState('importing')
          doImport(book, file, queryClient)
            .then((stats) => {
              setState('completed')
              setStats(stats)
            })
            .catch((error) => {
              if (isServerTimeout(error)) {
                setState('timedout')
                setError('Server timeout')
              } else {
                setState('error')
                setError(`Error importing file: ${(error as API.ResponseError<unknown>).message}`)
              }
            })
        }
        return
    }
    switch (state) {
      case 'gettingUrl':
        if (file) {
          getDocUploadUrl(book.bookId, file.name, docxContentType)
            .then((uploadUrl) => {
              upload(uploadUrl, docxContentType, file)
              setState('uploading')
            })
            .catch((error) => {
              setState('error')
              setError(`Error getting upload URL: ${(error as API.ResponseError<unknown>).message}`)
            })
        }
    }
  }, [state, uploadStatus, book, file, upload, queryClient])

  const handleAbort = useCallback(() => {
    abort()
    setState('aborted')
  }, [abort])
  const importDoc = useCallback(async (file: File): Promise<void> => {
    setFile(file)
    if (file) setState('gettingUrl')
  }, [])

  return {
    importDoc,
    status: state,
    progress,
    abort: handleAbort,
    stats,
    error,
  }
}

async function doImport(book: Book, file: File, queryClient: QueryClient): Promise<API.BookImportStats> {
  const stats = await importDocFile(book.bookId, file.name, docxContentType)
  // Force react-query to refetch the Book
  queryClient.invalidateQueries(bookKey(book))
  return stats
}

/**
 * Calls the backend to get the temporary document upload url.
 */
async function getDocUploadUrl(bookId: BookId, file: string, contentType: string): Promise<string> {
  const response = await serverRequest<API.BookImportUrlInput, API.BookImportUrlSuccess>('/book/import/url', {
    bookId,
    file,
    contentType,
  })
  return response.uploadUrl
}

/**
 * Calls the backend to import a document.
 */
async function importDocFile(bookId: BookId, file: string, contentType: string): Promise<API.BookImportStats> {
  const response = await serverRequest<API.BookImportInput, API.BookImportSuccess>('/book/import', {
    bookId,
    file,
    contentType,
  })
  return response.stats
}

/**
 * Attempts to determine if the error is because API Gateway timed out the connection.
 * There is no good way to discover that error. This approach is the best I can come up with.
 */
function isServerTimeout(error: unknown): boolean {
  const message = (error as API.ResponseError<unknown>).message
  if (!message) return false
  try {
    const msg = JSON.parse(message)
    return msg?.message === 'Service Unavailable'
  } catch (_e) {
    return false
  }
}
