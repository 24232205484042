import { dateToString, S3ImageView } from '@life/components'
import { Book } from '@life/frontend-model'
import { StoryImage } from '@life/model'

type ImageProps = {
  book: Book
  image: StoryImage
}
export function ImageInfo({ book, image }: ImageProps): JSX.Element {
  const img = book.findImage(image.id)
  if (!img) return <></>
  return (
    <>
      <a href={img.originalUrl} target="_blank" rel="noreferrer">
        <S3ImageView image={img} size="web" alt={image.caption} className="flex-1 max-h-[50rem]" />
      </a>
      {img.when && <h2 className="text-lg text-gray-800">Date: {dateToString(img.when)}</h2>}
      {img.where && <h2 className="text-lg text-gray-800">Location: {img.where}</h2>}
      {img.who && <h2 className="text-lg text-gray-800">People: {img.who.map((p) => p.formalName).join(', ')}</h2>}
      {img.notes && <h2 className="text-lg text-gray-800">Notes: {img.notes}</h2>}
    </>
  )
}
