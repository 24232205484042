import { classNames, PhotoIcon } from '@life/components'
import { FileRejection, useDropzone } from 'react-dropzone'

type DropProps = {
  singleFile?: boolean
  onDrop: (files: File[], rejections: FileRejection[]) => void
}

const MB = 1024 * 1024
const MAX_UPLOAD_SIZE = 10 * MB

const messages: Record<string, string> = {
  Drop: 'Drop your photos here...',
  Drop_single: 'Drop your photo here...',
  Drop_drag: 'Drag & Drop your photos here...',
  Drop_drag_single: 'Drag & Drop your photo here...',
  Select: 'Click to Select files',
  Select_single: 'Click to Select file',
}
export function DropZone({ onDrop, singleFile }: DropProps): JSX.Element {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAX_UPLOAD_SIZE,
    accept: {
      'image/png': [],
      'image/jpg': [],
      'image/jpeg': [],
      // TODO support heic somehow
      // https://shkspr.mobi/blog/2020/12/coping-with-heic-in-the-browser/
      // https://github.com/catdad-experiments/heic-convert
      // 'image/heic': [],
    },
  })
  const dropMsgIndex = `Drop${isDragActive ? '_drag' : ''}${singleFile ? '_single' : ''}`
  const selectMsgIndex = `Select${singleFile ? '_single' : ''}`
  return (
    <div className="w-full p-8">
      <div
        className={classNames(
          'px-8 py-16 w-full flex flex-col items-center rounded-xl border-4 border-dashed cursor-pointer bg-gray-50',
          isDragActive ? 'bg-green-100' : 'hover:bg-gray-100'
        )}
        {...getRootProps()}
      >
        <PhotoIcon className="w-20 text-gray-400" />
        <input {...getInputProps()} />
        <div className="text-gray-600 text-center">
          <p className="font-bold">{messages[dropMsgIndex]}</p>
          <p className="">or</p>
          <p className="text-indigo-600 hover:underline">{messages[selectMsgIndex]}</p>
        </div>
      </div>
    </div>
  )
}
