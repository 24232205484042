import { classNames, S3ImageView, useInfoPanel } from '@life/components'
import { Story } from '@life/frontend-model'
import { StoryImage, StoryNoun, StoryParagraph, StoryText } from '@life/model'
import { ImageInfo } from './ImageInfo'
import { NounInfo } from './NounInfo'
import { storyImageToImage } from './util'
import { ViewContent } from './ViewContent'

type TextProps = {
  text: StoryText
}
export function ViewText({ text }: TextProps): JSX.Element {
  const classes = []
  if (text.bold) {
    classes.push('font-bold')
  }
  if (text.code) {
    classes.push('font-mono')
  }
  if (text.italic) {
    classes.push('italic')
  }
  if (text.underline) {
    classes.push('underline')
  }
  return classes.length > 0 ? <span className={classes.join(' ')}>{text.text}</span> : <>{text.text}</>
}

type ParagraphProps = {
  story: Story
  paragraph: StoryParagraph
  depth: number
  linkIndex: number
}
export function ViewParagraph({ story, paragraph, depth, linkIndex }: ParagraphProps): JSX.Element {
  const padding = paragraph.leftPadding ?? 0
  return (
    <p className={classNames('pb-3 max-w-prose text-gray-700', padding > 0 ? 'pl-10' : '')}>
      <ViewContent story={story} content={paragraph.children} depth={depth} linkIndex={linkIndex + 1} />
    </p>
  )
}

type ImageProps = {
  story: Story
  image: StoryImage
  alignImageLeft: boolean
  depth: number
  linkIndex: number
}
export function ViewImage({ story, image, alignImageLeft, depth, linkIndex }: ImageProps): JSX.Element {
  const { openInfoPanel } = useInfoPanel()

  function handleOpenInfoPanel(): void {
    openInfoPanel('', <ImageInfo book={story.book} image={image} />)
  }

  return (
    <div
      id={image.linkId}
      className={classNames(
        'pt-2 w-full',
        image.size === 'small' ? 'sm:w-1/2' : 'pb-4',
        image.size === 'small' && (alignImageLeft ? 'sm:float-left sm:pr-2 sm:mr-2' : 'sm:float-right sm:pl-2 sm:ml-2')
      )}
    >
      <button onClick={handleOpenInfoPanel}>
        <figure>
          <S3ImageView
            image={storyImageToImage(story.book, image)}
            size="web"
            alt={image.caption}
            className="rounded-lg"
          />
          <figcaption className="my-2 text-base text-gray-500">{image.caption}</figcaption>
        </figure>
        <ViewContent story={story} content={image.children} depth={depth} linkIndex={linkIndex} />
      </button>
    </div>
  )
}

type LinkProps = {
  story: Story
  noun: StoryNoun
  depth: number
  linkIndex: number
}
export function ViewLink({ noun, story, depth, linkIndex }: LinkProps): JSX.Element {
  const { openInfoPanel } = useInfoPanel()

  function handleOpenInfoPanel(): void {
    openInfoPanel('', <NounInfo book={story.book} noun={noun} />)
  }

  return (
    <button id={noun.linkId} onClick={handleOpenInfoPanel} className="cursor-pointer hover:underline text-cyan-600">
      <ViewContent story={story} content={noun.children} depth={depth} linkIndex={linkIndex} />
    </button>
  )
}
