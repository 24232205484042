import { Dialog, Transition } from '@headlessui/react'
import { Fragment, KeyboardEvent } from 'react'
import { classNames } from './classNames'
import { WithChildren } from './types'

/**
 * Represents a keyboard shortcut and its action.
 * Keys must be strings that specify keyboard combinations.
 * When that combination is pressed, the corresponding function will be called.
 * Valid shortcut specifiers are:
 * - any event.key (such as 'Escape', or 'Enter')
 * - cmd+N where N is a case-sensitive character or event.key
 * -- matches either Mac command key or control key
 */
export type ShortcutMap = {
  [shortcut: string]: () => void
}

type Props = WithChildren & {
  isOpen: boolean
  title?: string
  modal?: boolean // if true, dialog won't closed when clicked outside
  onClose: () => void
  shortcuts?: ShortcutMap
  className?: string
}
export function LifeDialog({ isOpen, title, modal, onClose, shortcuts, className, children }: Props): JSX.Element {
  function handleClose(): void {
    if (!modal) onClose()
  }

  function handleKeyboard(event: KeyboardEvent): void {
    if (!shortcuts || event.repeat) return
    const key = `${event.ctrlKey || event.metaKey ? 'cmd+' : ''}${event.key}`
    if (key in shortcuts) {
      event.preventDefault()
      shortcuts[key]()
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={handleClose} onKeyDown={handleKeyboard} tabIndex={-1} className="relative z-30">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-2 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'w-fit transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all',
                  className
                )}
              >
                {title && <Dialog.Title className="font-medium text-lg mb-2">{title}</Dialog.Title>}
                <div>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

type ActionsProps = WithChildren & {
  className?: string
}
function LifeDialogActions({ className, children }: ActionsProps): JSX.Element {
  const childCount = Array.isArray(children) ? children.filter(Boolean).length : 0
  return (
    <div
      className={classNames(
        'flex flex-row space-x-2 border-t mt-2 pt-4',
        childCount > 1 ? 'justify-between' : 'justify-end',
        className
      )}
    >
      {children}
    </div>
  )
}

LifeDialog.Actions = LifeDialogActions

type ContentProps = WithChildren & {
  className?: string
}
function LifeDialogContent({ className, children }: ContentProps): JSX.Element {
  return (
    <div className={classNames('pt-1 overflow-y-auto', className)} style={{ maxHeight: '80vh' }}>
      {children}
    </div>
  )
}

LifeDialog.Content = LifeDialogContent
