import { AddIcon } from '../icons'
import { Tooltip } from '../Tooltip'
import { ButtonBase, ButtonProps } from './ButtonBase'

type Props = {
  canEdit?: boolean
} & ButtonProps

export function ButtonAdd(props: Props) {
  const { canEdit = true, children, ...other } = props
  return (
    <Tooltip message={canEdit ? '' : 'No Edit permission'}>
      <ButtonBase
        className="bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
        icon={<AddIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
        disabled={!canEdit}
        {...other}
      >
        {children || 'Add'}
      </ButtonBase>
    </Tooltip>
  )
}
