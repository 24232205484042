import { Story } from '@life/frontend-model'
import { isStoryText, StoryContent } from '@life/model'
import { ViewParagraph, ViewLink, ViewImage, ViewText } from './ViewElements'
import { ViewSubstory } from './ViewSections'

type Props = {
  story: Story
  content: StoryContent[]
  depth: number
  linkIndex: number
}
export function ViewContent({ story, content, depth, linkIndex }: Props): JSX.Element {
  let alignImageLeft = false

  return (
    <>
      {content &&
        content.map((row, index) => {
          if (isStoryText(row)) {
            return <ViewText key={index} text={row} />
          }
          const type = row.type
          switch (type) {
            case 'paragraph':
              return <ViewParagraph key={index} story={story} paragraph={row} depth={depth} linkIndex={linkIndex} />
            case 'substory':
              alignImageLeft = false
              return <ViewSubstory key={row.id} story={story} section={row} depth={depth} />
            case 'image':
              if (row.size === 'small') {
                alignImageLeft = !alignImageLeft
              } else {
                alignImageLeft = false
              }
              return (
                <ViewImage
                  key={index}
                  story={story}
                  image={row}
                  alignImageLeft={alignImageLeft}
                  depth={depth}
                  linkIndex={linkIndex}
                />
              )
            case 'person':
            case 'location':
            case 'thing':
              return <ViewLink key={index} story={story} noun={row} depth={depth} linkIndex={linkIndex} />
            case 'task':
            case 'comment':
              return <></>
            default: {
              const exhaustiveType: never = type
              return <div className="text-red-700">Unknown element type ({exhaustiveType})</div>
            }
          }
        })}
    </>
  )
}
