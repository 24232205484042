import { NotReady, PageHeading, PagePadding, useRequiredParam } from '@life/components'
import { useBook } from '@life/frontend-model'
import { isEmpty } from '@life/model'
import { useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { DropZone } from './DropZone'
import { FileAdder, FileUploadAttempt } from './FileUploader'

export function AddImage(): JSX.Element {
  const bookSlug = useRequiredParam('bookSlug')
  const { book, isLoading, error } = useBook(bookSlug)
  const [uploads, setUploads] = useState<FileUploadAttempt[]>([])
  // End of Hooks

  if (isLoading || error || !book) return <NotReady type="Book" id={bookSlug} isLoading={isLoading} error={error} />

  function onDrop(files: File[], rejections: FileRejection[]): void {
    if (!book) return
    const filesGood = files.map((file) => ({
      id: (Math.random() + 1).toString(36).substring(7),
      file: file,
    }))
    const filesBad = rejections.map((file) => ({
      id: (Math.random() + 1).toString(36).substring(7),
      file: file.file,
      error: file.errors.join(', '),
    }))
    setUploads([...filesGood, ...filesBad])
  }

  return (
    <PagePadding>
      <PageHeading title="Add Photos">Add Photos</PageHeading>

      {isEmpty(uploads) ? (
        <DropZone onDrop={onDrop} />
      ) : (
        <div className="rounded-xl bg-gray-100 p-2">
          <p className="text-center text-gray-700 hover:underline cursor-pointer" onClick={() => setUploads([])}>
            Upload More Photos
          </p>
          <div className=" flex flex-wrap items-center">
            {uploads.map((file) => (
              <FileAdder
                key={file.id}
                book={book}
                addAttempt={file}
                onCancel={(file) => setUploads((previous) => previous.filter((image) => image.id !== file.id))}
              />
            ))}
          </div>
        </div>
      )}
    </PagePadding>
  )
}
