import { compareDates } from '@life/components'
import { Person } from '@life/frontend-model'
import { DateBreakdown } from '@life/model'

export type PersonSortType =
  | 'givenNames'
  | 'lastName'
  | 'firstName'
  | 'formalName'
  | 'mentions'
  | 'death'
  | 'birth'
  | 'in-use'
  | 'no-images'

export function sortPeople(people: Person[], sortField: PersonSortType): Person[] {
  people.sort((a, b) => {
    let compare = 0
    const compareMentions =
      a.imagesWithPerson.length + a.storiesWithPerson.length - (b.imagesWithPerson.length + b.storiesWithPerson.length)
    const compareLast = a.lastName.localeCompare(b.lastName)
    const compareGiven = a.givenNames.localeCompare(b.givenNames)

    switch (sortField) {
      case 'lastName':
        compare = compareLast
        if (compare === 0) compare = compareGiven
        break
      case 'mentions':
        compare = compareMentions
        if (compare === 0) compare = compareGiven * -1
        if (compare === 0) compare = compareLast * -1
        break
      case 'givenNames':
        compare = compareGiven
        if (compare === 0) compare = compareLast
        break
      case 'firstName':
        compare = a.firstName.localeCompare(b.firstName)
        break
      case 'formalName':
        compare = a.formalName.localeCompare(b.formalName)
        break
      case 'birth':
        compare = compareDates(a.birthDate, b.birthDate)
        if (compare === 0) compare = compareMentions
        break
      case 'death':
        compare = compareDates(a.deathDate, b.deathDate)
        if (compare === 0) compare = -1 * compareDates(a.birthDate, b.birthDate)
        if (compare === 0) compare = compareMentions
        break
      case 'in-use':
        compare = a.storiesWithPerson.length - b.storiesWithPerson.length
        break
      case 'no-images':
        compare = a.imagesWithPerson.length - b.imagesWithPerson.length
        break
    }
    return compare
  })
  return people
}

export function filterPeopleByDate<P extends Person>(
  people: readonly P[],
  date: DateBreakdown | undefined
): readonly P[] {
  if (!date) return people
  return people.filter((person) => {
    if (person.birthDate && compareDates(person.birthDate, date) > 0) return false // Born After
    if (person.deathDate && compareDates(person.deathDate, date) < 0) return false // Died Before
    return true
  })
}

export function splitNames(name?: string): [string, string] {
  if (!name) return ['', '']
  name = name.trim()
  const index = name.lastIndexOf(' ')
  if (index > 0) return [name.substring(0, index).trim(), name.substring(index + 1)]
  return [name, '']
}
