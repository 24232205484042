import { API, ImageId } from '@life/model'
import { useCallback, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { serverRequest } from './api'
import { Book } from './book'
import { bookKey } from './book-api'
import { Image, UnsavedImage } from './image'
import { UploadState, useUploadFile } from './upload'

type AddImageState = UploadState & {
  imageId?: ImageId
}
export function useAddImage(book: Book): AddImageState {
  const [isAdding, setAdding] = useState(false)
  const [imageId, setImageId] = useState<ImageId>()
  const [error, setError] = useState<string>()
  const queryClient = useQueryClient()
  const { upload, abort: abortUpload, status, progress } = useUploadFile()
  const imageRef = useRef<Image>()

  const add = useCallback(
    async (file: File): Promise<void> => {
      if (!book) {
        setError('book is missing')
        return
      }

      // Insert image to DB
      let image: Image
      try {
        setAdding(true)
        image = await addImage(
          new UnsavedImage(book, {
            bookId: book.bookId,
            name: file.name,
            extension: stripImageExtension(file.type),
            size: file.size,
          })
        )
      } catch (error) {
        setError((error as API.AddErrors).error)
        return
      } finally {
        setAdding(false)
      }

      // Update local copy and the book
      imageRef.current = image
      setImageId(image.imageId)
      queryClient.setQueryData<Book | undefined>(bookKey(book), (previous) => {
        if (!previous) return previous
        previous.images.push(image)
        return previous
      })

      const uploadUrl = image.uploadUrl
      if (!uploadUrl) {
        setError('Failed to get upload url from server')
        return
      }
      upload(uploadUrl, addImageExtension(image.extension), file)
    },
    [book, queryClient, upload]
  )
  async function handleAbortClick(): Promise<void> {
    abortUpload()
    if (imageRef.current) {
      await removeImage(imageRef.current)
    }
  }
  return {
    upload: add,
    status: isAdding ? 'adding' : error ? 'error' : status,
    progress,
    abort: handleAbortClick,
    imageId,
    error,
  }
}

const prefix = 'image/'
function stripImageExtension(fileType: string) {
  if (fileType.startsWith(prefix)) return fileType.substring(prefix.length)
  throw new Error('Image types should be of type image/*')
}

function addImageExtension(extension: string) {
  if (extension.startsWith(prefix)) return extension
  return prefix + extension
}

export type RemoveImageState = {
  isRemoving: boolean
  remove: (image: Image) => Promise<void>
}
export function useRemoveImage(): RemoveImageState {
  const queryClient = useQueryClient()
  const mutation = useMutation((image: Image) => removeImage(image))
  async function remove(image: Image): Promise<void> {
    try {
      await mutation.mutateAsync(image)
      queryClient.setQueryData<Book | undefined>(bookKey(image.book), (previous) => {
        if (!previous) return previous
        const index = previous.images.findIndex((i) => i.imageId === image.imageId)
        if (index >= 0) previous.images.splice(index, 1)
        return previous
      })
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, remove, isRemoving: mutation.isLoading }
}

type ReplaceImageState = UploadState
export function useReplaceImage(image: Image | undefined): ReplaceImageState {
  const queryClient = useQueryClient()
  const { upload, abort, status, progress } = useUploadFile()
  const replace = useCallback(
    async (file: File) => {
      if (!image) return
      if (!image.uploadUrl) return
      // Update queryClient with modified image url so images will rerender
      queryClient.setQueryData<Book | undefined>(bookKey(image.book), (previous) => {
        if (!previous) return previous
        const img = previous.findImage(image.imageId)
        if (img) img.refreshUrls()
        return previous
      })
      upload(image.uploadUrl, addImageExtension(image.extension), file)
    },
    [image, upload, queryClient]
  )
  const handleAbortClick = useCallback(() => {
    abort()
  }, [abort])

  return {
    upload: replace,
    status: status,
    progress,
    abort: handleAbortClick,
  }
}

type UpdateImageState = {
  isLoading: boolean
  update: (image: Image) => Promise<Image>
}
export function useUpdateImage(): UpdateImageState {
  const queryClient = useQueryClient()
  const mutation = useMutation((image: Image) => updateImage(image))
  async function update(image: Image): Promise<Image> {
    try {
      const output = await mutation.mutateAsync(image)
      queryClient.setQueryData<Book | undefined>(bookKey(image.book), (previous) => {
        if (!previous) return previous
        const i = previous.findImage(image.imageId)
        if (i) Object.assign(i, output)
        return previous
      })
      return output
    } catch (error) {
      throw API.toResponseError(error)
    }
  }
  return { ...mutation, update }
}

async function addImage(image: UnsavedImage): Promise<Image> {
  const response = await serverRequest<API.ImageAddInput, API.ImageAddSuccess>('/image/add', {
    image: image.toUnsavedModel(),
  })
  return new Image(image.book, response.image)
}

async function updateImage(image: Image): Promise<Image> {
  const response = await serverRequest<API.ImageUpdateInput, API.ImageUpdateSuccess>('/image/update', {
    image: image.toModel(),
  })
  return new Image(image.book, response.image)
}

async function removeImage({ bookId, imageId }: Image): Promise<void> {
  await serverRequest<API.ImageRemoveInput, API.ImageRemoveSuccess>('/image/remove', { bookId, imageId })
}
