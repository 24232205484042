import * as Model from '@life/model'
import { Book } from './book'
import { BookItem, UnsavedBookItem } from './book-item'

export class UnsavedPdfInfo extends UnsavedBookItem {
  /** The version of the PDF file. It will be undefined if create is pending or error. */
  readonly version?: number
  /** The version number that is currently being created, if any. */
  readonly pendingVersion?: number
  /** Any error encountered while attempting to create the PDF. */
  readonly createError?: string

  constructor(book: Book, data: Model.UnsavedPdf) {
    super(book)
    this.version = data.version
    this.pendingVersion = data.pendingVersion
    this.createError = data.createError
  }

  override get key(): string {
    return String(this.version) ?? String(this.pendingVersion) ?? 'undefined'
  }

  override toUnsavedModel(): Model.UnsavedPdf {
    return {
      bookId: this.bookId,
      version: this.version,
      pendingVersion: this.pendingVersion,
      createError: this.createError,
    }
  }
}

export class PdfInfo extends UnsavedPdfInfo implements BookItem {
  // TODO: consider using 2 urls Cover-> `book/$bookId/pdf/$version/cover`, Content-> `book/$bookId/pdf/$version/content`
  static BookPdfNotCreated = Model.API.BookPdfNotCreated

  /** Presigned URL for PDF download, matching 'version'. Undefined if no file. Note: may expire! */
  url?: string

  constructor(book: Book, data: Model.PdfInfo) {
    super(book, data)
    this.url = data.url
  }

  override get key(): string {
    return this.url ?? 'undefined'
  }

  toModel(): Model.PdfInfo {
    return {
      url: this.url,
      ...this.toUnsavedModel(),
    }
  }
}
