import { useState } from 'react'

export function useSendState() {
  const [isSending, setSending] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [failureReason, setFailureReason] = useState('')

  async function sendWrapper<T>(send: () => Promise<T>) {
    setSending(true)
    setFailureReason('')
    return send()
      .then((output) => {
        setSuccess(true)
        return output
      })
      .catch((error) => {
        setSuccess(false)
        setFailureReason(getErrorMessage(error))
        return error
      })
      .finally(() => {
        setSending(false)
      })
  }

  return {
    send: sendWrapper,
    isSending,
    isSuccess,
    failureReason,
  }
}

function getErrorMessage(error: string | Error): string {
  if (typeof error === 'string') return error
  return error.message || error.toString()
}
