import { ActionMenuIcon, classNames, LifeMenu } from '@life/components'
import { Story } from '@life/frontend-model'
import { Link } from 'react-router-dom'
import { PdfPreviewLink } from '../PdfPreviewLink'

type Props = {
  story: Story
  isDirty: boolean
}
export function EditMenu({ story, isDirty }: Props): JSX.Element {
  let previewDisabled = false
  let reason = ''
  if (!story.isInToc) {
    previewDisabled = true
    reason = 'Story not in Book'
  } else if (isDirty) {
    previewDisabled = true
    reason = 'Story must be saved'
  }
  return (
    <LifeMenu>
      <LifeMenu.Button className="p-1 rounded-full text-white">
        <ActionMenuIcon className="h-5 w-5 text-black hover:text-gray-400" aria-hidden="true" />
      </LifeMenu.Button>
      <LifeMenu.Items className="w-56">
        <LifeMenu.Item>
          {({ active }) => (
            <div
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              <PdfPreviewLink book={story.book} storyId={story.storyId} disabled={previewDisabled} reason={reason} />
            </div>
          )}
        </LifeMenu.Item>
        <LifeMenu.Item>
          {({ active }) => (
            <Link
              type="button"
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'flex w-full text-left px-4 py-2 space-x-2 text-sm whitespace-nowrap'
              )}
              to={story.link}
            >
              <span>Read Story</span>
            </Link>
          )}
        </LifeMenu.Item>
      </LifeMenu.Items>
    </LifeMenu>
  )
}
